import React, { useMemo, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useCustomersUIContext } from '../../../_context/CustomersUIContext';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { AddressEditForm } from './AddressEditForm';
import { Icon } from '_metronic/_icons';



export function AddressEditDialog() {

  // Address UI Context
  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.customerId,
    show: UIContext.showEditAddressDialog,
    onHide: UIContext.closeEditAddressDialogHandler,
  }), [UIContext]);


  const btnRef = useRef();
  const submitForm = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };


  return (
    <Modal size="lg" show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-lg" centered backdrop="static" keyboard={false}>

      <Modal.Header>
        <Modal.Title>
          <Icon.GeoAlt className="mr-3" />
          <FormattedMessage id={UIProps.id ? "ADDRESS.TITLE" : "ADDRESS.TITLE_NEW"} />
        </Modal.Title>

        <CloseButton onClick={UIProps.onHide} />
      </Modal.Header>

      <Modal.Body>

        <AddressEditForm btnRef={btnRef} />

      </Modal.Body>

      <Modal.Footer>

        <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='primary' type="submit" className="ml-2" onClick={submitForm} id="btn_add_modal">
          <FormattedMessage id={UIProps.id ? "GENERAL.SAVE" : "GENERAL.ADD"} />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
