import React from 'react';
import { Icon } from '_metronic/_icons';
import { FormattedMessage } from 'react-intl';



export const MODULE_TRANSLATIONS = {
  archive: <FormattedMessage id="MENU.ASIDE.SETTING.ARCHIVE" />,
  category: <FormattedMessage id="CATEGORY.TITLE" />,
  company: <FormattedMessage id="GENERAL.COMPANY" />,
  connection: <FormattedMessage id="ADMIN.USER.LOGS.CONNECTION" />,
  customer: <FormattedMessage id="CUSTOMER.TITLE" />,
  fulfillment: <FormattedMessage id="GENERAL.CONVERT" />,
  credit: <FormattedMessage id="DOCUMENT.CREDIT_TITLE" />,
  invoice: <FormattedMessage id="DOCUMENT.INVOICE_TITLE" />,
  offer: <FormattedMessage id="DOCUMENT.OFFER_TITLE" />,
  order: <FormattedMessage id="DOCUMENT.ORDER_TITLE" />,
  payment: <FormattedMessage id="DOCUMENT.TABLE.COLUMN.PAYMENT" />,
  waybill: <FormattedMessage id="DOCUMENT.WAYBILL_TITLE" />,
  dashboard: <FormattedMessage id="MENU.ASIDE.DASHBOARD" />,
  export: <FormattedMessage id="IMPORT_EXPORT.EXPORT" />,
  amazon: <FormattedMessage id="ADMIN.USER.LOGS.AMAZON" />,
  ebay: <FormattedMessage id="ADMIN.USER.LOGS.EBAY" />,
  kaufland: <FormattedMessage id="ADMIN.USER.LOGS.KAUFLAND" />,
  product: <FormattedMessage id="PRODUCT.TITLE" />,
  digital_product: <FormattedMessage id="ADMIN.USER.LOGS.DIGITAL_PRODUCT" />,
  shipping: <FormattedMessage id="GENERAL.SHIPPING" />,
  file: <FormattedMessage id="ADMIN.USER.LOGS.FILE" />,
  supplier: <FormattedMessage id="ADMIN.USER.LOGS.SUPPLIER" />,
  support: <FormattedMessage id="MENU.ASIDE.SUPPORT" />,
  user: <FormattedMessage id="ADMIN.USER.LOGS.USER" />,
};

export const RESULTS_TRANSLATIONS = {
  error_filter: <FormattedMessage id="ADMIN.USER.LOGS.RESULTS_FILTER.ERROR_FILTER" />,
  success_filter: <FormattedMessage id="ADMIN.USER.LOGS.RESULTS_FILTER.SUCCESS_FILTER" />,
}

export const USER_AGENT = [
  {
    value: "chrome",
    label: "Chrome",
    icon: <Icon.BrowserChrome />
  },
  {
    value: "firefox",
    label: "Firefox",
    icon: <Icon.BrowserFirefox />
  },
  {
    value: "safari",
    label: "Safari",
    icon: <Icon.BrowserSafari />
  },
  {
    value: "edge",
    label: "Edge",
    icon: <Icon.BrowserEdge />
  },
  {
    value: "mobile",
    label: "Phone",
    icon: <Icon.Phone />
  },
  {
    value: "tablet",
    label: "Tablet",
    icon: <Icon.Tablet />
  },
  {
    value: "desktop",
    label: "Desktop",
    icon: <Icon.Pc />
  },
]

export const getBrowserIcon = (type) => {
  return USER_AGENT.find(agent => agent.value === type);
}

/* export const INITIAL_USER = {
  name: '',
  email: '',
  license: {
    type: '',
    updated_at: ''
  },
  language: '',
  newsletter: '',
  user_id: '',
  created_at: '',
  updated_at: '',
} */