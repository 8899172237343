import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sortCaret, headerSortingClasses, remoteGetSelectRow, NoRecordsFoundMessage, convertPriceCurrency, UIDates, getCountryList } from '_metronic/_helpers';
import { useDocumentLocalUIContext } from '../_context/DocumentLocalUIContext';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import { getConnection } from 'constants/connectionSettings';
import * as UIHelpers from '../_context/DocumentsUIHelpers';
import { useLoadingHandler, useQuery } from 'app/hooks';
import * as columnFormatters from './column-formatters';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { DocumentActions } from '../_redux/actions';
import * as columnHelpers from './columnHelpers';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function DocumentsTable({ documents, loading, countDocuments, queryParams, setOrderQuery, setPaginationQuery, setFilterQuery, connectionEntities }) {

  const locale = useLang();
  const query = useQuery();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  // Documents UI Context
  const UIContext = useDocumentsUIContext();
  const contextLocal = useDocumentLocalUIContext();
  const { type: documentType, title: documentTitle } = useMemo(() =>
    UIContext.requestedDocument,
    [UIContext.requestedDocument,]
  );

  const UIProps = useMemo(() => ({
    openEditDocumentDialog: UIContext.openEditDocumentDialog,
    openDeleteDocumentDialog: UIContext.openDeleteDocumentDialog,
    openDocumentConvertToWaybill: UIContext.openDocumentConvertToWaybill,
    openDocumentConvertToCredit: UIContext.openDocumentConvertToCredit,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    copyDocumentForCreateNewOne: UIContext.copyDocumentForCreateNewOne,
    openPaymentReminderDialog: UIContext.openPaymentReminderDialog,
    openPaymentWarningsDialog: UIContext.openPaymentWarningsDialog,
    openEnterPaymentDialog: UIContext.openEnterPaymentDialog,
    openDocumentConvertToInvoice: UIContext.openDocumentConvertToInvoice,
    openDocumentConvertToOrder: UIContext.openDocumentConvertToOrder,
    openLabelSettingDialog: UIContext.openLabelSettingDialog,
    openCustomerDetailsDialog: UIContext.openCustomerDetailsDialog,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    pushConvertDocument: UIContext.pushConvertDocument,
    openConvertDocumentAlertDialog: UIContext.openConvertDocumentAlertDialog,
    openDocumentDetailsDialog: UIContext.openDocumentDetailsDialog,
    requestedDocument: UIContext.requestedDocument,
    documentQueryParams: contextLocal.queryParams,
    openLabelsTable: UIContext.openLabelsTable,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    openConvertDocumentInfoDialog: UIContext.openConvertDocumentInfoDialog,
    openExportDocumentDialog: UIContext.openExportDocumentDialog,
  }),
    [UIContext, contextLocal.queryParams]
  );

  const useLoading = useLoadingHandler(loading, DocumentActions.cleanStatesInStore({ loading: false }));

  const amazonConnection = connectionEntities?.find(item => item.name === 'amazon')?.amazon || {};
  const { invoice_upload: isAmazonInvoiceUpload, credit_upload: isAmazonCreditUpload } = amazonConnection;

  /*   const tableRowHandler = (rowId, key, event) => {
      dispatch(DocumentActions.updateDocumentByField(rowId, key, event));
    }; */

  const documentConvertHandler = (id, from, to, isDuplicated, isDuplicateInvoiceId) => {
    if (from === "waybills" && isDuplicateInvoiceId) {
      return UIProps.openConvertDocumentInfoDialog({ isDuplicateInvoiceId });
    }
    if (isDuplicated) {
      return UIProps.openConvertDocumentAlertDialog({ id, from, to });
    }
    UIProps.pushConvertDocument(id, from, to);
  };


  const openPrintDocumentDialog = (id) => {

    if (id) {
      const data = {
        ids: [id],
        type: documentType,
      };

      dispatch(DocumentActions.printDocument(data));
      UIProps.openPrintDocumentDialog();
    }
  };


  const COLUMNS = [
    {
      dataField: 'order_id',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.MARKETPLACE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap pl-2',
      classes: 'font-weight-bold text-nowrap pl-2',
      headerStyle: { minWidth: '100px' },
      style: { height: '53px' },
      formatter: columnFormatters.TitleColumnFormatter,
      formatExtraData: {
        openPrintDocumentDialog,
        UIProps: UIProps,
        UIHelpers: UIHelpers,
        documentType: documentType,
        documentTitle: documentTitle,
      },
    },
    {
      dataField: 'relatedInvoiceId',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.INVOICE_ID' }),
      sort: true,
      sortCaret,
      hidden: !['credits', 'waybills'].includes(documentType),
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => {
        const relatedInvoiceId = row.history?.find(history => history.document_from === "invoice" && ['credit', 'waybill'].includes(history.document_to))?.relation_id ?? (row?.related_invoice_id ?? '')
        return (
          <Link to={`${ModuleRoutes.DOCUMENT_INVOICE}?page=1&search=${relatedInvoiceId}`} type='button' className='svg-icon text-dark-75 text-hover-primary preview'>
            {relatedInvoiceId}
            <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
              <Icon.Link />
            </span>
          </Link>
        )
      },
    },
    {
      dataField: 'order_date',
      text: intl({ id: columnHelpers?.dateColumnText('orders') }),
      hidden: !['orders', 'invoices'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bold text-nowrap text-center pr-4',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        documentType === 'orders'
          ? <OverlayTrigger overlay={<Tooltip id="order-date-tooltip" className='font-weight-bold'>{UIDates.formatDateTime(row.order_date)}</Tooltip>}>
            <span className={`label label-inline label-lg label-rounded svg-icon svg-icon-sm ${UIDates.isOrderOlderThan(row.order_date, 2) ? 'label-outline-danger' : 'label-outline-success'}`}>
              <Icon.CardPlus className="mr-2" />  {UIDates.formNow(row.order_date)}
            </span>
          </OverlayTrigger>
          : row.order_date && <div className='font-size-sm text-dark-50'>
            {UIDates.formatDateTime(row.order_date)}
          </div>
      ),
    },
    {
      dataField: 'created_at',
      text: intl({ id: columnHelpers?.dateColumnText(documentType) }),
      hidden: ['orders'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'font-weight-bold text-nowrap text-center pr-4',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="date-tooltip" className='font-weight-bold'>{UIDates.formatDateTime(row.created_at)}</Tooltip>}>
          <span>
            {UIDates.formatDate(row.created_at)}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'latest_ship_date',
      text: "📦",
      hidden: !['orders'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center h4 py-3',
      classes: 'font-weight-bold text-nowrap text-center pr-4',
      formatter: (cell, row) => {
        const diff = UIDates.getDaysDifference(row.latest_ship_date)
        if (!row.latest_ship_date || row.order_status === "cancelled") { return }
        return (
          (row.order_status === "open" && row.marketplace !== "amazon_prime")
            ? <OverlayTrigger overlay={<Tooltip id="latest-ship-date-tooltip" className='font-weight-bold'>
              <div className='border-bottom text-dark-75 font-size-lg pb-1 mb-2'><FormattedMessage id="DOCUMENT.TABLE.LAST_SHIPMENT_DATE" /></div>
              <div className={clsx('mb-1', diff < 1 && 'text-danger')}>{UIDates.formatDateTime(cell)}</div>
              <div className='text-muted'>{UIDates.formNow(cell)}</div>
            </Tooltip>}>
              <span className={`label label-inline label-lg label-rounded pulse pulse-danger svg-icon svg-icon-sm px-2 py-4 ${diff >= 4
                ? 'label-light-success'
                : diff === 3
                  ? 'label-light-warning'
                  : diff === 2 || diff === 1
                    ? 'label-light-danger'
                    : 'label-light-dangerous'
                }`}>
                {diff > 0 ? <Icon.Clock /> : <Icon.ExclamationCircleFill />}
                {diff < 2 && <span className="pulse-ring" />}
              </span>
            </OverlayTrigger>
            : <OverlayTrigger overlay={<Tooltip id="latest-ship-date2-tooltip" className='font-weight-bold text-capitalize'><FormattedMessage id="DOCUMENT.EDIT.HISTORY.SENT" /></Tooltip>}>
              <span className='label label-inline label-lg label-rounded label-light-success svg-icon svg-icon-sm px-2 py-4'>
                <Icon.CheckCircleFill />
              </span>
            </OverlayTrigger>
        )
      },
    },
    {
      dataField: 'position',
      isDummyField: true,
      hidden: !['offers', 'orders'].includes(documentType),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ARTICLE' }),
      headerStyle: { minWidth: '365px' },
      style: { fontSize: '11px' },
      classes: 'font-weight-bold',
      formatter: (cell, row, rowIndex) => <columnFormatters.ProductColumnFormatter cell={cell} row={row} rowIndex={rowIndex} />,
    },
    {
      dataField: 'customer_details.name',
      text: intl({ id: 'CUSTOMER.TITLE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '200px' },
      formatter: (cell, row) => {
        return (
          <div type="button" className="d-flex align-items-center text-decoration-none text-hover-primary text-nowrap preview" onClick={() => UIProps.openCustomerDetailsDialog(row.id)} id='btn_customer_preview'>
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold'>{row.customer_details?.name}</span>
              <span className='text-dark-50'>{row.customer_details?.email}</span>
            </div>
            <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
              <Icon.FileEarmarkText />
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'customer_details.delivery_address.country_code',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '160px' },
      formatter: (cell, row) => {
        const address = row.customer_details?.delivery_address
        return (
          <OverlayTrigger overlay={<Tooltip id="address-tooltip">
            <div className='font-weight-bold text-dark-75 text-left'>
              <div className='font-weight-bolder'>{address?.name}</div>
              <div className='font-weight-bolder text-dark-50'>{address?.company}</div>
              <hr className='my-1' />
              <div>{address?.address_line1} {address?.address_line2}</div>
              <div>{address?.post_code} {address?.city}</div>
              <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
            </div>
          </Tooltip>}>
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold text-nowrap'>{address.city}</span>
              <span className='text-dark-50'>{`${address?.country_code}-${address?.post_code}`}</span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'entered_payment',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT_TYPE' }),
      hidden: !['invoices', 'credits'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-muted font-weight-bold pr-7',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const payments = cell?.map(payment => {
          const method = UIHelpers.paymentMethods.find(method => method.value === payment.payment_method);
          return method ? method.labelId ? intl({ id: method.labelId }) : method.label : payment.payment_method;
        });
        return [...new Set(payments)].join(', ');
      }
    },
    {
      dataField: 'payment_status',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT' }),
      hidden: !['invoices', 'orders', 'credits'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      formatter: (cell, row) => {
        const paymentStatus = UIHelpers.getPaymentStatus(cell);
        return (
          <OverlayTrigger overlay={<Tooltip id="payment-tooltip" className='font-weight-bold'><FormattedMessage id='DOCUMENT.ACTION.PAYMENTS' /></Tooltip>}>
            <div type='button' className={`label label-inline label-lg font-weight-bold label-${paymentStatus?.className}`} onClick={() => UIProps.openEnterPaymentDialog(row.id)}>
              {paymentStatus?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'pricing_summary.total',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.TOTAL_AMOUNT' }),
      hidden: !['invoices'].includes(documentType),
      isDummyField: true,
      headerClasses: 'text-right',
      classes: 'font-weight-bolder text-right pl-3',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const paymentWarnings = row.payment_warnings?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
        const discount = row.pricing_summary?.discount ?? 0;
        const totalPriceDiscount = row.pricing_summary?.total_price_discount ?? 0;
        const totalPayment = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100) + +row.pricing_summary.shipping_price + paymentWarnings + totalPriceDiscount;
        const currency = row.pricing_summary.currency;
        return (
          <span type="button" onClick={() => UIProps.openEnterPaymentDialog(row.id)}>
            {convertPriceCurrency(totalPayment, currency)}
          </span>
        )
      },
    },
    {
      dataField: 'rest',
      isDummyField: true,
      hidden: !['invoices', 'orders'].includes(documentType),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.OPEN_AMOUNT' }),
      headerClasses: 'text-right',
      classes: 'font-weight-bolder text-right',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.RestPriceColumnFormatter,
      formatExtraData: {
        openEnterPaymentDialog: UIProps.openEnterPaymentDialog,
        openPaymentWarningsDialog: UIProps.openPaymentWarningsDialog,
      },
    },
    {
      dataField: 'pricing_summary.total',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.AMOUNT' }),
      hidden: !['orders'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-right',
      classes: 'font-weight-bolder text-right pr-7 pl-3',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const paymentWarnings = row.payment_warnings?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
        const discount = row.pricing_summary?.discount ?? 0;
        const priceDiscount = row.pricing_summary?.total_price_discount ?? 0;
        const totalPayment = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100) + +row.pricing_summary.shipping_price + paymentWarnings + priceDiscount;
        const currency = row.pricing_summary.currency;
        return convertPriceCurrency(totalPayment, currency);
      },
    },
    {
      dataField: 'shipping',
      text: intl({ id: 'GENERAL.LABEL' }),
      hidden: ['credits', 'waybills'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center',
      classes: 'text-center pr-7',
      headerStyle: { minWidth: '80px' },
      formatter: columnFormatters.ShippingDataColumnFormatter,
      formatExtraData: {
        orderStatusOpen: !['completed', 'cancelled'].includes(query.get('filter.status')),
        dispatch,
        openLabelsTable: UIProps.openLabelsTable,
        openPrintLabelsDialog: UIProps.openPrintLabelsDialog,
        openLabelSettingDialog: UIProps.openLabelSettingDialog,
      },
    },
    {
      dataField: 'history',
      hidden: !['orders'].includes(documentType),
      text: intl({ id: 'DOCUMENT.INVOICE_TITLE' }),
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (cell, row) => {
        // FIXME: 'send_email' mutlaka kaldırılmalı
        const invoice = cell?.find(item => ['convert_document', 'send_email'].includes(item?.action) && item?.document_to === "invoice" && item?.status === 'success');
        return (
          row?.related_invoice_id === 'ebay'
            ? <OverlayTrigger overlay={<Tooltip id="invoice-tooltip" className='font-weight-bold'>{row.marketplace_id} {row?.ebay_invoice_id && <div>{row.ebay_invoice_id}</div>}</Tooltip>}>
              <div type='button' className='svg-icon svg-icon-xl text-dark-50 text-hover-primary'>
                <Icon.EbayLong />
                <span className={clsx("label label-md label-dot ml-1", `label-${row?.ebay_invoice_id ? "success" : "warning"}`)} />
              </div>
            </OverlayTrigger>
            : invoice && <OverlayTrigger overlay={<Tooltip id="invoice-tooltip">
              <div className="d-flex align-items-center">
                <div className="svg-icon svg-icon-xl text-success mr-2">
                  <Icon.FileEarmarkCheck />
                </div>
                <div className='d-flex flex-column text-left'>
                  <div className='font-weight-bolder text-dark-75'>
                    {invoice.relation_id}
                  </div>
                  <div className='font-size-sm text-dark-50'>
                    {UIDates.formatDateTime(invoice.created_at)}
                  </div>
                </div>
              </div>
            </Tooltip>}>
              <Link to={`${ModuleRoutes.DOCUMENT_INVOICE}?page=1&search=${invoice.relation_id}`} type='button' className='svg-icon text-success text-hover-primary'>
                <Icon.FileEarmarkCheck />
              </Link>
            </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'history',
      hidden: !['invoices'].includes(documentType),
      text: intl({ id: 'DOCUMENT.CREDIT_TITLE' }),
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (cell, row) => {
        const credit = cell?.find(item => item?.action === 'convert_document' && item?.document_to === "credit" && item?.status === 'success');
        return (
          credit && <OverlayTrigger overlay={<Tooltip id="invoice-tooltip">
            <div className="d-flex align-items-center">
              <div className="svg-icon svg-icon-xl text-danger mr-2">
                <Icon.FileEarmarkX />
              </div>
              <div className='d-flex flex-column text-left'>
                <div className='font-weight-bolder text-dark-75'>
                  {credit.relation_id}
                </div>
                <div className='font-size-sm text-dark-50'>
                  {UIDates.formatDateTime(credit.created_at)}
                </div>
              </div>
            </div>
          </Tooltip>}>
            <Link to={`${ModuleRoutes.DOCUMENT_CREDIT}?page=1&search=${credit.relation_id}`} type='button' className='svg-icon text-danger text-hover-primary'>
              <Icon.FileEarmarkX />
            </Link>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'notes',
      hidden: ['offers'].includes(documentType),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.NOTE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap pl-5 pr-12',
      classes: 'text-center pl-5 pr-15',
      formatter: (cell, row) => {
        return (
          <div className='d-flex align-items-center'>
            {row.notes && <OverlayTrigger overlay={<Tooltip id="note-tooltip" className='font-weight-bold text-left'>{row.notes}</Tooltip>}>
              <div className={clsx('svg-icon text-info', row.order_notice && "mr-3")}>
                <Icon.ExclamationCircle />
              </div>
            </OverlayTrigger>}
            {row.order_notice && <OverlayTrigger overlay={<Tooltip id="note-tooltip" className='font-weight-bold text-left'>{row.order_notice}</Tooltip>}>
              <div className='svg-icon text-danger'>
                <Icon.ChatSquareText />
              </div>
            </OverlayTrigger>}
          </div>
        );
      },
    },
    {
      dataField: 'invoice_uploaded',
      hidden: !['invoices'].includes(documentType) || !isAmazonInvoiceUpload,
      text: intl({ id: 'GENERAL.UPLOAD' }),
      headerClasses: 'text-center pl-5 pr-15',
      classes: 'text-center pl-5 pr-15',
      formatter: (cell, row) => {
        const uploadSuccess = row.history?.find(history => history.api === "amazon" && history.action === "invoice_uploaded" && history.status === "success");
        const uploadFailed = row.history?.find(history => history.api === "amazon" && history.action === "invoice_uploaded" && history.status === "fail");
        const uploadInfo = <div>
          <div>
            {UIDates.formatDateTime((uploadSuccess ?? uploadFailed)?.created_at)}
          </div>
          <span>
            {uploadSuccess
              ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.SUCCESS' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} />
              : uploadFailed
                ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.FAIL' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} />
                : null}
          </span>
        </div>
        return ['amazon', 'amazon_prime'].includes(row.marketplace)
          ? <OverlayTrigger overlay={<Tooltip id="note-tooltip">{uploadInfo}</Tooltip>}>
            <div className='svg-icon text-dar-50'>
              {(cell && uploadSuccess) ? <Icon.FileEarmarkArrowUp className="text-success" /> : (!cell && uploadFailed) ? <Icon.FileEarmarkMinus className="text-danger" /> : null}
            </div>
          </OverlayTrigger>
          : null
      },
    },
    {
      dataField: 'credit_uploaded',
      hidden: !['credits'].includes(documentType) || !isAmazonCreditUpload,
      text: intl({ id: 'GENERAL.UPLOAD' }),
      headerClasses: 'text-center pl-5 pr-15',
      classes: 'text-center pl-5 pr-15',
      formatter: (cell, row) => {
        const uploadSuccess = row.history?.find(history => history.api === "amazon" && history.action === "credit_uploaded" && history.status === "success");
        const uploadFailed = row.history?.find(history => history.api === "amazon" && history.action === "credit_uploaded" && history.status === "fail");
        const uploadInfo = <div>
          <div>
            {UIDates.formatDateTime((uploadSuccess ?? uploadFailed)?.created_at)}
          </div>
          <span>
            {uploadSuccess ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.SUCCESS' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} /> : uploadFailed ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.FAIL' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} /> : null}
          </span>
        </div>
        return ['amazon', 'amazon_prime'].includes(row.marketplace)
          ? <OverlayTrigger overlay={<Tooltip id="note-tooltip">{uploadInfo}</Tooltip>}>
            <div className='svg-icon text-dark-50'>
              {(cell && uploadSuccess) ? <Icon.FileEarmarkArrowUp className="text-success" /> : (!cell && uploadFailed) ? <Icon.FileEarmarkMinus className="text-danger" /> : null}
            </div>
          </OverlayTrigger>
          : null
      },
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      classes: 'hover-menu',
      formatter: columnFormatters.ResponsiveActionFormatter,
      formatExtraData: {
        openPrintDocumentDialog,
        openEditDocumentDialog: UIProps.openEditDocumentDialog,
        copyDocumentForCreateNewOne: UIProps.copyDocumentForCreateNewOne,
        openDeleteDocumentDialog: UIProps.openDeleteDocumentDialog,
        openExportDocumentDialog: UIProps.openExportDocumentDialog,
        documentType: documentType,
        documentTitle: documentTitle,
        documentConvertHandler: documentConvertHandler,
        ...columnHelpers.getFormatterExtraEvents(documentType, UIProps),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={documents || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes='table table-head-custom overflow-hidden table-vertical-center'
        bootstrap4
        bordered={false}
        striped
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={useLoading} onClick={() => setFilterQuery('DELETE_PARAMS', UIHelpers.allQueryKeys)} />}
        selectRow={remoteGetSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: documents,
          key: documentType,
        })}
      // defaultSorted={[{ dataField: documentType === 'orders' ? "order_date" : "created_at", order: "desc" }]}
      // defaultSorted={columnHelpers.defaultSorted}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countDocuments}
        entitiesCount={documents?.length}
        loading={useLoading}
      />
    </>
  );
}
