import { call, select, put, takeEvery, all, fork } from 'redux-saga/effects';
import { DocumentGenerator, tokenErrorHandler, documentMiddleware, productMiddleware, customerMiddleware, companyMiddleware } from 'middlewares';
import { DHL_ACCOUNT_OPTIONS } from 'app/modules/connections/_context/SettingsUIHelpers';
import { getNotification } from '_metronic/layout/components/header/_redux/saga';
import { shippingsMiddleware } from 'middlewares/shipping/middleware';
import * as documentUIHelpers from '../_context/DocumentsUIHelpers';
import { fieldParamsForTable } from 'middlewares/documents/params';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { isEmpty, isString, isUndefined } from 'lodash';
import { documentTypes } from '_metronic/_helpers';
import { DocumentActions } from './actions';
import actionTypes from './actionTypes';
import { v4 as uuidv4 } from 'uuid';



// -------------------------------------- DOCUMENT -------------------------------------- //

function* createDocument({ payload: { document, convertParams } }) {
  try {
    let access = yield call(DocumentGenerator.getAccessDataFromStore);

    const { data } = yield call(documentMiddleware.createDocument, access, document);

    // convert edilerek oluşan documanda shipping var ise shipping page ilişkilendirme yapma
    if (convertParams && document?.shipping?.length && data.id) {
      yield call(documentMiddleware.updateShippingRelation, access, data.id, document.shipping?.map(item => item.id));
    }

    // if is with convertion than update converted document
    if (convertParams && data?.id) {
      const { id, model, type } = convertParams;
      access.documentModel = model;

      const response = yield call(documentMiddleware.getDocumentById, access, id);

      const history = [...response.data.history, {
        id: uuidv4(),
        status: "success",
        action: "convert_document",
        document_from: documentTypes[model],
        document_to: type,
        description: data.id,
        relation_id: data.id,
      }];

      yield call(documentMiddleware.updateDocumentWithPatch, access, id, { history });
    }

    yield put(DocumentActions.createDocumentSuccess(data.id));
  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.createDocumentFail(err?.response?.data?.error));
  }
}


function* updateDocument({ payload: { document } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    yield call(documentMiddleware.updateDocument, access, document);
    yield put(DocumentActions.updateDocumentSuccess());
  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.updateDocumentFail(err?.response?.data));
  }
}


function* deleteDocument({ payload: { ids } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    yield call(documentMiddleware.deleteDocument, access, "archive", ids);

    yield put(DocumentActions.deleteDocumentSuccess(ids));

    // Notification update
    yield call(getNotification);

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.deleteDocumentFail(err));
  }
}


function* getDocuments({ payload: { documentQueryParams } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    //FIXME:  DocumentGenerator düzeltilmeli sonra kaldırılacak
    if (access.documentModel) {
      const [documents, counts] = yield all([
        call(documentMiddleware.getDocuments, access, documentQueryParams),
        call(documentMiddleware.getCount, access, documentQueryParams),
      ]);
      yield put(DocumentActions.getDocumentsSuccess(documents.data, counts.data));

      // Notification update
      yield call(getNotification);
    }

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.getDocumentsFail());
  }
}


function* getDocumentById({ payload: { documentId, isCopy, isConvert } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    if (isString(isConvert) || !isUndefined(isConvert)) {
      access.documentModel = isConvert;
    }

    const response = yield call(documentMiddleware.getDocumentById, access, documentId);

    yield put(DocumentActions.getDocumentByIdSuccess(response.data, isCopy, isConvert));

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.getDocumentByIdFail(err.response));
  }
}


function* getDocumentByIds({ payload: { ids, type } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const documentQueryParams = {
      ids,
      pagination: {},
      filter: {
        status: type
      }
    };
    const response = yield call(documentMiddleware.getDocuments, access, documentQueryParams)

    yield put(DocumentActions.getDocumentByIdsSuccess(response.data, type));

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.getDocumentByIdsFail());
  }
}


function* createAllShippings({ payload: { ids, service, dhlServiceOptions, defaultProfileName } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const documentQueryParams = {
      pagination: {},
      ids,
    };

    const shippingSettings = yield select(state => state.settings.shippingSettings);
    const response = yield call(documentMiddleware.getDocuments, access, documentQueryParams)

    if (service === 'dhl_new' || service === 'dhl_new_warenpost') {

      const connections = yield call(documentMiddleware.getUserTokens, access);
      const dhlNewConnection = connections?.data?.find(token => token.name === 'dhl_new');
      const dhlSubscriptions = dhlNewConnection?.dhl?.subscriptions || [];
      const dhlCustomerNumber = dhlNewConnection.dhl?.customerNumber;
      const dhlAccountOptions = dhlSubscriptions.map(item => {
        const baseOption = DHL_ACCOUNT_OPTIONS?.find((opt) => opt.accountNumber === item.accountNumber);
        return baseOption;
      })
      const billingNumberMap = response.data.map((item) => {
        const billing_number = dhlAccountOptions?.filter((option) => {
          return item?.customer_details?.delivery_address?.country_code === "DE"
            ? service === "dhl_new_warenpost"
              ? dhlServiceOptions?.goGreen
                ? (option?.type === "dhl_new_warenpost" && option?.location === "domestic" && option?.goGreen)
                : option?.type === 'dhl_new_warenpost' && option?.location === 'domestic' && option?.accountNumber === '6201'
              : dhlServiceOptions?.goGreen
                ? (option?.type === "dhl_new" && option?.location === "domestic" && option?.goGreen)
                : (option?.type === 'dhl_new' && option?.location === 'domestic' && option?.accountNumber === '0101') || (option?.type === 'dhl_new' && option?.location === 'domestic' && option?.accountNumber === '0102')
            : service === "dhl_new_warenpost"
              ? dhlServiceOptions?.goGreen
                ? (option?.type === "dhl_new_warenpost" && option?.location === "abroad" && option?.goGreen)
                : (option?.type === 'dhl_new_warenpost' && option?.location === 'abroad' && option?.accountNumber === '6601') || (option?.type === 'dhl_new_warenpost' && option?.location === 'abroad' && option?.accountNumber === '6603')
              : dhlServiceOptions?.goGreen
                ? (option?.type === "dhl_new" && option?.location === "abroad" && option?.goGreen) || (option?.type === "dhl_new" && option?.location === "eu" && option?.goGreen)
                : (option?.type === 'dhl_new' && option?.location === 'abroad' && option?.accountNumber === '5301') || (option?.type === 'dhl_new' && option?.location === 'eu' && option?.accountNumber === '5401')
        }).map(option => ({ ...option, accountNumber: dhlCustomerNumber + option.accountNumber }))[0]?.accountNumber;
        return { ...item, billing_number };
      });

      const updatedResponseData = response.data.map((item, index) => {
        const billing_number = billingNumberMap[index]?.billing_number;
        return {
          ...item,
          billing_number,
        };
      });

      yield put(DocumentActions.createAllDocumentsSuccess(updatedResponseData, service, shippingSettings, defaultProfileName));
    } else {
      yield put(DocumentActions.createAllDocumentsSuccess(response.data, service, shippingSettings, defaultProfileName));
    }

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.createAllDocumentsFail());
  }
}


// -------------------------------------- CUSTOMER -------------------------------------- //

function* getDocumentCustomerDetails({ payload: { documentId } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const response = yield call(documentMiddleware.getDocumentById, access, documentId, 'customer_fields');

    yield put(DocumentActions.getDocumentCustomerDetailsSuccess(response.data));

  } catch (err) {
    yield put(DocumentActions.getDocumentCustomerDetailsFail(err));
  }
}


function* getCustomers({ payload: { event } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const filters = {
      search: {
        searchKeys: ['name', 'email', 'company', 'phone', 'number'],
        searchText: event,
      },
    };

    const response = yield call(customerMiddleware.getCustomersForDocument, accessToken, filters);

    yield put(DocumentActions.getCustomersSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.getCustomersFail());
  }
}


function* getCustomer({ payload: { customerId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(customerMiddleware.getCustomerById, accessToken, customerId);
    yield put(DocumentActions.getCustomerSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.getCustomerFail());
  }
}


function* updateCustomer({ payload: { updatedCustomer } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(customerMiddleware.putCustomer, accessToken, updatedCustomer);
    yield put(DocumentActions.updateCustomerSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.updateCustomerFail());
  }
}




// -------------------------------------- ENTERED PAYMENT  -------------------------------------- //

function* enterPayment({ payload: { paymentValues, paymentStatus, documentId } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    const res = yield call(documentMiddleware.getDocumentById, access, documentId, null, { fields: { entered_payment: true } });
    const { entered_payment } = res.data;

    const currentEnteredPayment = entered_payment && Array.isArray(entered_payment) ? entered_payment : [];
    currentEnteredPayment.push(paymentValues);

    const updateDocument = {
      entered_payment: currentEnteredPayment,
      payment_status: paymentStatus
    };

    const resp = yield call(documentMiddleware.updateDocumentWithPatch, access, documentId, updateDocument);

    yield put(DocumentActions.enterPaymentSuccess(paymentValues, paymentStatus, documentId, resp?.data?.history));

  } catch (err) {
    const error = err?.response?.data ?? err?.message;
    yield put(DocumentActions.enterPaymentFail(error ?? 'Payment failed'));
  }
}


function* updateEnterPayment({ payload: { documentId, paymentId, updatedPayment, isPaid } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    const res = yield call(documentMiddleware.getDocumentById, access, documentId, null, { fields: { entered_payment: true } });
    const { entered_payment } = res.data;

    const findUpdatedPaymentIndex = entered_payment.findIndex(pay => pay.id === paymentId);
    entered_payment[findUpdatedPaymentIndex] = {
      ...updatedPayment,
      id: paymentId,
    };

    const updateDocument = { entered_payment: entered_payment, payment_status: isPaid };

    yield call(documentMiddleware.updateDocumentWithPatch, access, documentId, updateDocument);
    yield fork(getDocumentById, { payload: { documentId } });
    yield put(DocumentActions.updateEnterPaymentSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.enterPaymentFail());
  }
}


function* deleteEnteredPayment({ payload: { paymentId, documentId } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    const res = yield call(documentMiddleware.getDocumentById, access, documentId, null, { fields: { entered_payment: true } });
    const { entered_payment } = res.data;

    if (!entered_payment) {
      throw new Error('Keine eingegebene Zahlung im Dokument gefunden');
    }

    const deletePayment = entered_payment.filter(pay => pay.id !== paymentId);
    const updateDocument = { entered_payment: deletePayment, payment_status: 'open' };

    yield call(documentMiddleware.updateDocumentWithPatch, access, documentId, updateDocument);

    yield put(DocumentActions.deleteEnteredPaymentSuccess(paymentId, documentId));

  } catch (err) {
    yield put(DocumentActions.deleteEnteredPaymentFail(err?.response));
  }
}



// --------------------------------- OTHER ---------------------------------

// DOCUMENT TABLE INLINE EDIT
function* updateDocumentByFieldSaga({ payload: { documentId, key, value } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    const updatedDocument = { [key]: value };
    yield call(documentMiddleware.updateDocumentWithPatch, access, documentId, updatedDocument);
    const getUpdatedDocument = yield call(documentMiddleware.getDocumentById, access, documentId, null, fieldParamsForTable);
    yield put(DocumentActions.updateDocumentByFieldSuccess(getUpdatedDocument.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.updateDocumentByFieldFail(err));
  }
}


// Add multiple positions to document
function* addMultiplePositionsSaga({ payload: { ids } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const responseAll = yield all(ids.map(id => call(productMiddleware.getProductById, accessToken, id)));
    const resData = responseAll.map(res => res.data);
    yield put(DocumentActions.addMultiplePositionsSuccess(resData));
  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.addPositionFail(err));
  }
}


// GET DOCUMENT BY ID FOR POSITIONS
function* getProductByIdForPositions({ payload: { productId, variant } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const product = yield call(productMiddleware.getProductById, accessToken, productId);
    yield put(DocumentActions.addPositionSuccess(product.data, variant));

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.addPositionFail());
  }
}


function* deletePaymentWarning({ payload: { documentId, id } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    yield call(documentMiddleware.deletePaymentWarning, access, documentId, id);
    yield put(DocumentActions.deletePaymentWarningSuccess(documentId, id));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.deletePaymentWarningFail(err?.response?.data || 'error'));
  }
}



// -------------------------------------- GET SETTINGS -------------------------------------- //
// tüm ayarlar settings den alinacak sekilde yapildi icin gerek kalmadi
// function* getUserSettingsSaga({ payload: { settingsType } }) {
//   try {
//     const accessToken = yield select(state => state.auth.access_token);

//     const productSettings = yield call(settingsMiddleware.getByType, accessToken, 'products');
//     const documentSettings = yield call(settingsMiddleware.getByType, accessToken, settingsType);

//     const result = {
//       ...documentSettings?.data[0],
//       ...productSettings?.data[0],
//     };
//     yield put(DocumentActions.getSettingsByTypeSuccess(result));
//   } catch (err) {
//     yield put(DocumentActions.getSettingsByTypeFail(JSON.stringify(err?.response)));
//   }
// }


function* getCompanyBaseDataSaga() {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const params = {
      filter: {
        fields: ['id', 'company_name'],
      },
    };
    const response = yield call(companyMiddleware.get, accessToken, params);
    yield put(DocumentActions.getCompanyBaseDataSuccess(response.data[0]));
  } catch (err) {
    yield put(DocumentActions.getCompanyBaseDataFail(JSON.stringify(err?.response)));
  }
}


// Get User Tokens
function* getUserTokens() {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const response = yield call(documentMiddleware.getUserTokens, access);

    yield put(DocumentActions.getUserTokensSuccess(response.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.getUserTokensFail(err));
  }
}


// Get Amazon Prime Orders
function* getAmazonPrimeOrders() {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    const getToken = yield call(documentMiddleware.findUserToken, access, {
      token_type: 'marketplace',
      name: 'amazon',
    });

    if (!getToken.data || isEmpty(getToken.data) || isUndefined(getToken.data.id)) {
      const error = new Error('Amazon Token not found.');
      return yield put(DocumentActions.getAmazonPrimeOrdersFail(JSON.stringify(error)));
    }

    const responseOrders = yield call(documentMiddleware.getAmazonPrimeOrders, access, getToken.data.id);
    yield put(DocumentActions.getAmazonPrimeOrdersSuccess(responseOrders.data));

    //TODO: We must find a better way to do this
    return yield fork(getDocuments, { payload: { documentQueryParams: documentUIHelpers.initialDocumentQueryParams } });
  } catch (err) {
    yield put(DocumentActions.getAmazonPrimeOrdersFail(JSON.stringify(err.response)));
  }
}


// -------------------------------------- LABELS -------------------------------------- //

function* createLabels({ payload: { shipper, data } }) {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const results = yield call(documentMiddleware.createLabel, access, shipper, data);

    yield put(DocumentActions.createLabelsSuccess(results.data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.createLabelsFail(err?.response?.data || 'error'));
  }
}


function* getLabel({ payload: { labelId, type } }) {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    const results = yield call(documentMiddleware.getLabel, access, labelId, type);

    yield put(DocumentActions.getLabelSuccess(results.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.getLabelFail(err?.response?.data || 'error'));
  }
}


// Delete DHL Label
function* deleteLabel({ payload: { labelId, documentId } }) {
  try {
    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const response = yield call(documentMiddleware.deleteLabel, access, documentId, labelId);

    yield put(DocumentActions.deleteLabelSuccess(labelId, documentId, response.data));

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.deleteLabelFail(err?.response?.data?.error));
  }
}


/* function* returnLabels({ payload: { shipper, data } }) {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const results = yield call(documentMiddleware.returnLabel, access, shipper, data);
    yield put(DocumentActions.returnLabelsSuccess(results.data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.returnLabelsFail(err?.response?.data || 'error'));
  }
} */


// Return Label Email
function* returnLabelEmail({ payload: { documentType, documentId, shipperId } }) {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const results = yield call(documentMiddleware.returnLabelEmail, access, documentType, documentId, shipperId);
    yield put(DocumentActions.returnLabelEmailSuccess(results.data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.returnLabelEmailFail(err?.response?.data || 'error'));
  }
}


function* updateLabel({ payload: { data } }) {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const results = yield call(documentMiddleware.updateLabel, access, data);
    yield put(DocumentActions.updateLabelSuccess(results.data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.updateLabelFail(err?.response?.data || 'error'));
  }
}

function* createUnlabeled({ payload: { data } }) {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);
    const results = yield call(documentMiddleware.createUnlabeled, access, data);

    yield put(DocumentActions.createUnlabeledSuccess(results.data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.createUnlabeledFail(err?.response?.data || 'error'));
  }
}

// Get Shipping
function* getShipping({ payload: { shippingIds } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(shippingsMiddleware.getShipping, accessToken, shippingIds);
    yield put(DocumentActions.getShippingSuccess(response.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.getShippingFail());
  }
}

// -------------------------------------- FULFILLMENT -------------------------------------- //

function* createFulfillments({ payload: { data, queryParams } }) {
  try {

    const access = yield call(DocumentGenerator.getAccessDataFromStore);

    const result = yield call(documentMiddleware.createFulfillments, access, data);

    yield put(DocumentActions.createFulfillmentsSuccess(result.data));
    yield put(DocumentActions.getDocuments(queryParams));
  } catch (err) {
    yield call(tokenErrorHandler, err, DocumentActions.createFulfillmentsFail(err));
  }
}



// -------------------------------------- PRINT DOCUMENT -------------------------------------- //

function* printDocument({ payload: { data, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(documentMiddleware.printDocument, accessToken, data);

    if (response.data.status === 'error') {
      yield put(DocumentActions.printDocumentFail(response.data));
    } else {
      yield put(DocumentActions.printDocumentSuccess(response.data));
    }

    if (data?.reminder) {
      yield put(DocumentActions.getDocuments(queryParams));
    }

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(DocumentActions.printDocumentFail(err));
  }
}


export function* documentSaga() {
  yield takeEvery(actionTypes.CREATE_DOCUMENT, createDocument);
  yield takeEvery(actionTypes.UPDATE_DOCUMENT, updateDocument);
  yield takeEvery(actionTypes.DELETE_DOCUMENT, deleteDocument);
  yield takeEvery(actionTypes.GET_DOCUMENTS, getDocuments);
  yield takeEvery(actionTypes.GET_DOCUMENT_BY_ID, getDocumentById);
  yield takeEvery(actionTypes.GET_DOCUMENT_BY_IDS, getDocumentByIds);

  yield takeEvery(actionTypes.GET_DOCUMENT_CUSTOMER_DETAILS, getDocumentCustomerDetails);
  yield takeEvery(actionTypes.GET_CUSTOMERS, getCustomers);
  yield takeEvery(actionTypes.GET_CUSTOMER, getCustomer);
  yield takeEvery(actionTypes.UPDATE_CUSTOMER, updateCustomer);

  yield takeEvery(actionTypes.ENTER_PAYMENT, enterPayment);
  yield takeEvery(actionTypes.UPDATE_ENTER_PAYMENT, updateEnterPayment);
  yield takeEvery(actionTypes.DELETE_ENTERED_PAYMENT, deleteEnteredPayment);

  yield takeEvery(actionTypes.UPDATE_DOCUMENT_BY_FIELD, updateDocumentByFieldSaga);
  yield takeEvery(actionTypes.ADD_MULTIPLE_POSITION_ROW, addMultiplePositionsSaga);
  yield takeEvery(actionTypes.ADD_POSITION, getProductByIdForPositions);
  yield takeEvery(actionTypes.DELETE_PAYMENT_WARNING, deletePaymentWarning);

  // yield takeEvery(actionTypes.GET_SETTINGS_BY_TYPE, getUserSettingsSaga);
  yield takeEvery(actionTypes.GET_COMPANY_BASE_DATA, getCompanyBaseDataSaga);
  yield takeEvery(actionTypes.GET_USER_TOKENS, getUserTokens);
  yield takeEvery(actionTypes.GET_AMAZON_PRIME_ORDERS, getAmazonPrimeOrders);

  yield takeEvery(actionTypes.CREATE_LABELS, createLabels);
  yield takeEvery(actionTypes.GET_LABEL, getLabel);
  yield takeEvery(actionTypes.DELETE_LABEL, deleteLabel);
  //yield takeEvery(actionTypes.RETURN_LABELS, returnLabels);
  yield takeEvery(actionTypes.RETURN_LABEL_EMAIL, returnLabelEmail);
  yield takeEvery(actionTypes.UPDATE_LABEL, updateLabel);
  yield takeEvery(actionTypes.CREATE_UNLABELED, createUnlabeled);
  yield takeEvery(actionTypes.CREATE_ALL_SHIPPINGS, createAllShippings);
  yield takeEvery(actionTypes.GET_SHIPPING, getShipping);

  yield takeEvery(actionTypes.CREATE_FULFILLMENT_METHODS, createFulfillments);

  yield takeEvery(actionTypes.PRINT_DOCUMENT, printDocument);
}
