import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import { digitalProductsActions } from '../../_redux/actions';
import { customerTypeOptions } from 'app/modules/customers/_context/CustomersUIHelpers';
import { useDigitalProductsUIContext } from '../../_context/DigitalProductsUIContext';
import { COUNTRY_DE, COUNTRY_EN, addressType } from '_metronic/_helpers';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { useLang } from '_metronic/i18n';
import { Portal } from 'react-portal';
import { isEmpty } from 'lodash';



export function CustomerDetailsDialog() {

  const locale = useLang();
  const dispatch = useDispatch();

  const UIContext = useDigitalProductsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showCustomerDetailsDialog,
    onHide: UIContext.closeCustomerDetailsDialog,
    documentId: UIContext.documentId,
  }), [
    UIContext.documentId,
    UIContext.closeCustomerDetailsDialog,
    UIContext.showCustomerDetailsDialog,
  ]);

  const { customer } = useSelector(state => ({
    customer: state.digitalProduct.customerDetails,
  }));


  useEffect(() => {
    UIProps.documentId && dispatch(digitalProductsActions.getCustomerDetails(UIProps.documentId));
  }, [dispatch, UIProps.documentId]);


  useEffect(() => {
    return () => {
      dispatch(digitalProductsActions.cleanStatesInStore({
        customerDetails: {},
        error: ""
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.documentId]);


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-md" size="md" centered>

        <Modal.Header>

          <Modal.Title>
            <Icon.Person className="mr-3" />
            <FormattedMessage id="DOCUMENT.CUSTOMER.TITLE" />
          </Modal.Title>

          <CloseButton onClick={UIProps.onHide} />

        </Modal.Header>

        <Modal.Body className='overflow-hidden px-10 py-2'>

          {customer && !isEmpty(customer) && (

            <div className='d-flex flex-column font-weight-bold text-break'>

              {(customer.id || customer.number) &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.CUSTOMER_NUMBER" />
                  </Col>
                  <Col lg='7'>
                    {customer.number ? customer.number : customer.id}
                  </Col>
                </Row>
              }

              {customer.type &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.TYPE" />
                  </Col>
                  <Col lg='7'>
                    {customerTypeOptions.find(item => item.value === customer.type)?.label}
                  </Col>
                </Row>
              }

              {customer.name &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.NAME" />
                  </Col>
                  <Col lg='7'>
                    {customer.name}
                  </Col>
                </Row>
              }

              {customer.email &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.EMAIL" />
                  </Col>
                  <Col lg='7'>
                    {customer.email}
                  </Col>
                </Row>
              }

              {customer.phone &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.PHONE" />
                  </Col>
                  <Col lg='7'>
                    {customer.phone}
                  </Col>
                </Row>
              }

              {customer.company &&
                <Row className='border-bottom py-3'>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    <FormattedMessage id="GENERAL.COMPANY" />
                  </Col>
                  <Col lg='7'>
                    {customer.company}
                  </Col>
                </Row>
              }

              {/* --------------------------------- Address --------------------------------- */}

              {[customer.invoice_address, customer.delivery_address].map((address, i) => (
                <Row className={`${i === 0 ? "border-bottom" : ""} py-3`} key={i}>
                  <Col lg='5' className="text-muted mb-2 mb-sm-0">
                    {addressType.find(item => item.value === address?.type)?.label ?? <FormattedMessage id="GENERAL.ADDRESS" />}
                  </Col>
                  <Col lg='7'>
                    <div>{address?.name}</div>
                    <div>{address?.company}</div>
                    <div>{address?.address_line1} {address?.address_line2}</div>
                    <div>{address?.post_code} {address?.city}</div>
                    <div>{(locale === 'de' ? COUNTRY_DE : COUNTRY_EN).find(code => code.value === address?.country_code)?.label}</div>
                  </Col>
                </Row>
              ))}

            </div>
          )}
        </Modal.Body>

        <Modal.Footer>

          {/* <Button variant='primary' size='sm' type="button" className="svg-icon-sm mr-2" onClick={() => { history.push(ModuleRoutes.EDIT_CUSTOMER_FN(id)); UIProps.onHide() }} id='btn_edit_modal'>
            <FormattedMessage id='GENERAL.EDIT' />
          </Button> */}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};