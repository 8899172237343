import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getConnection } from 'constants/connectionSettings';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function TitleColumnFormatter(cell, row, rowIndex) {
  return (
    <div className='d-flex align-items-center'>

      <div className={clsx("text-decoration-none svg-icon mr-3", row.marketplace ? "svg-icon-2x" : "text-dark-50 text-hover-primary svg-icon-xl")}>
        {row.marketplace ? getConnection(row.marketplace)?.icon : <Icon.ShopWindow />}
      </div>

      <div className='d-flex flex-column'>

        <span className="text-hover-primary text-dark-75">
          {row.id}
        </span>

        {row.marketplace && <OverlayTrigger overlay={<Tooltip id="market-tooltip" className={clsx('font-weight-bold', row.marketplace_url === "" ? 'd-none' : 'd-flex')}>{row.marketplace_url}</Tooltip>}>
          <a href={row.marketplace_url} target="_blank" rel="noopener noreferrer" className='text-decoration-none text-hover-primary text-dark-50 font-size-sm preview'>
            {row.order_number ? `#${row.order_number}` : row.order_id ? `#${row.order_id}` : ''}
            <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
              <Icon.Link />
            </span>
          </a>
        </OverlayTrigger>}

      </div>

    </div>
  );
}