import React, { useContext, createContext, useState, useCallback } from 'react';
import { useSearchQuery } from 'app/hooks';
import { useIntl } from 'react-intl';



const UserUIContext = createContext({});
export const useUserUIContext = () => useContext(UserUIContext);


export const UserUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl()
  const [ids, setIds] = useState([]);
  const [userId, setUserId] = useState();

  const defaultSorted = [{ dataField: "created_at", order: "desc" }];

  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'created_at DESC',
    search: '',
    filter: {},
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const initialDateRange = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );

  const changeDateRange = (date = initialDateRange) =>
    setDateRange((prev) => ({
      ...prev,
      ...date,
    }));

  const initialDateRange2 = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange2, setDateRange2] = useState(
    initialDateRange2
  );

  const changeDateRange2 = (date = initialDateRange2) =>
    setDateRange2((prev) => ({
      ...prev,
      ...date,
    }));


  const initialDateRange3 = {
    id: "clear-date",
    label: intl({ id: "GENERAL.CLEAR" }),
  };

  const [dateRange3, setDateRange3] = useState(
    initialDateRange3
  );

  const changeDateRange3 = (date = initialDateRange3) =>
    setDateRange3((prev) => ({
      ...prev,
      ...date,
    }));

  //* User details dialog
  const [showUserDetailsDialog, setShowUserDetailsDialog] = useState(false);
  const openUserDetailsDialog = id => {
    setUserId(id);
    setShowUserDetailsDialog(true);
  };
  const closeUserDetailsDialog = () => {
    setUserId(undefined);
    setShowUserDetailsDialog(false);
  };

  //* User note dialog
  const [user, setUser] = useState({ id: '', note: '' });
  const [showUserNoteDialog, setShowUserNoteDialog] = useState(false);
  const openUserNoteDialog = data => {
    setUser(data);
    setShowUserNoteDialog(true);
  };
  const closeUserNoteDialog = () => {
    setUser({ id: '', note: '' });
    setShowUserNoteDialog(false);
  };

  // Log Entries Table için
  const [logsQueryParams, setLogsQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
    filter: {},
  });

  const setLogsQueryParams = useCallback((params) => setLogsQueryBase(params), [])
  const { setSearchQuery: setLogsSearchQuery, setFilterQuery: setLogsFilterQuery, setPaginationQuery: setLogsPaginationQuery, setOrderQuery: setLogsOrderQuery } = useSearchQuery(setLogsQueryParams);
  const logsInitialDateRange = { ...initialDateRange };
  const [logsDateRange, setLogsDateRange] = useState(logsInitialDateRange);
  const changeLogsDateRange = (date = logsInitialDateRange) => setLogsDateRange(date);
  // Log Entries Table için

  const value = {
    ids,
    setIds,
    userId,
    setUserId,
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
    changeDateRange,

    dateRange,
    setDateRange,
    defaultSorted,
    initialDateRange,

    dateRange2,
    setDateRange2,
    initialDateRange2,
    changeDateRange2,

    dateRange3,
    setDateRange3,
    initialDateRange3,
    changeDateRange3,

    logsQueryParams,
    setLogsSearchQuery,
    setLogsFilterQuery,
    setLogsPaginationQuery,
    setLogsOrderQuery,
    changeLogsDateRange,
    logsDateRange,
    setLogsDateRange,

    showUserDetailsDialog,
    closeUserDetailsDialog,
    openUserDetailsDialog,

    user,
    showUserNoteDialog,
    openUserNoteDialog,
    closeUserNoteDialog,
  };


  return (
    <UserUIContext.Provider value={value}>
      {children}
    </UserUIContext.Provider>
  );
};
