import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { connectionForEdit } from "../_context/SettingsUIHelpers";
import { CardItems } from '_metronic/_partials/components';
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectionsActions } from "../_redux/actions";
import { UIDates } from "_metronic/_helpers";
import { Button } from "react-bootstrap";
import { Icon } from "_metronic/_icons";
import RemainingBalance from "../connection-table/column-formatter";



export function ConnectionGeneralCard({ connectionId, connectionName }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const connection = useSelector((state) => state.connections[connectionForEdit(connectionName)]);

  const COMPANY_INFO = [
    {
      label: "CONNECTIONS.HEALTH",
      content: <span className={`text-${connection?.check === 'success' ? 'success' : 'danger'}`}>
        {connection?.check === 'success' ? intl({ id: "GENERAL.SUCCESSFUL" }) : connection?.error ?? ""}
      </span>
    },
    {
      label: "CONNECTIONS.TOKEN_TYPE",
      content: <span className='text-capitalize'>{connection?.token_type}</span>
    },
    ...(["shopify", "woocommerce", "shopware6", "shopware5", "prestashop", "etsy"].includes(connectionName) ? [
      {
        label: "CONNECTIONS.SHOP_WEBSITE",
        content: connection?.[connectionName]?.website,
      }
    ] : []),
    ...(connectionName === "amazon" ? [
      {
        label: "CONNECTIONS.MARKET_NAME",
        content: connection?.amazon?.market_name,
      },
      {
        label: "CONNECTIONS.SHOP.AMAZON_SELLER_ID",
        content: connection?.amazon?.MerchantToken,
      },
    ] : []),
    ...(connectionName === "ebay" ? [
      {
        label: "CONNECTIONS.MARKET_NAME",
        content: connection?.ebay?.market_name,
      },
      {
        label: "CONNECTIONS.ACCOUNT_TYPE",
        content: <span className='text-capitalize'>{connection?.ebay?.account_type}</span>,
      },
      {
        label: "CONNECTIONS.TOKEN_EXPIRE",
        content: UIDates.formatDateTime(connection?.ebay?.token_expire),
      }
    ] : []),
    ...(connectionName === "kaufland" ? [
      {
        label: "CONNECTIONS.MARKET_NAME",
        content: connection?.kaufland?.market_name,
      },
    ] : []),
    ...(connectionName === "deutsche_post" ? [
      {
        label: "CONNECTIONS.SHIPPER.DP.WALLET_BALANCE",
        content: RemainingBalance(connection?.deutsche_post?.wallet_balance),
      },
    ] : []),
    ...(connectionName === "etsy" ? [
      {
        label: "CONNECTIONS.MARKET_NAME",
        content: connection?.etsy?.market_name,
      },
      {
        label: "CONNECTIONS.SHOP.ETSY_MARKETPLACE_ID",
        content: connection?.etsy?.marketplace_id,
      },
      {
        label: "CONNECTIONS.SHOP.ETSY_USER_ID",
        content: connection?.etsy?.user_id,
      }
    ] : []),
    ...(connectionName === "gls" ? [
      {
        label: "CONNECTIONS.SHIPPER.GLS_CONTACT_ID",
        content: connection?.gls?.contact_id,
      },
      {
        label: "CONNECTIONS.SHIPPER.GLS_DEPTOR_NUMBER",
        content: connection?.gls?.customer_number,
      }
    ] : []),
    ...((connectionName === "dhl" || connectionName === "dhl_new") ? [
      {
        label: "GENERAL.CUSTOMER_NUMBER",
        content: <span className='text-capitalize'>{connection?.dhl?.customerNumber}</span>
      },
      {
        label: "ACCOUNT.BENUTZERNAME",
        content: <span className='text-capitalize'>{connection?.dhl?.username}</span>
      },
      {
        label: "CONNECTIONS.SHIPPER.DHL_SHIPPER_REFERENCE",
        content: <span className='text-capitalize'>{connection?.dhl?.shipperReference}</span>
      },
    ] : []),
    {
      label: "GENERAL.REGISTRATION_DATE",
      content: UIDates.formatDateTime(connection?.created_at)
    },
    {
      label: "GENERAL.UPDATE_DATE",
      content: UIDates.formatDateTime(connection?.updated_at)
    }
  ]


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.Tag />} title={<FormattedMessage id="GENERAL.GENERAL" />}>
        <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => dispatch(ConnectionsActions.connectionCheck(connectionId))} id="btn_dhl">
          <Icon.ArrowRepeat />
          <span className="d-none d-sm-inline font-weight-bold ml-2">
            <FormattedMessage id="CONNECTIONS.CHECK" />
          </span>
        </Button>
      </CardSubHeader>

      <CardBody>
        <CardItems Content={COMPANY_INFO} />
      </CardBody>

    </Card>
  );
}