import React from 'react';
import { UIDates } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { every } from 'lodash';



export const initialStatesForReducer = {
  request: {
    loading: false,
    name: null, // request name {create, update, delete ...}
    specify: null, // some pointer {id, sku, ...}
    error: null, // if has error
    status: null, //'start','fulfilled','failed'
  },
  actionController: {
    loading: false,
    error: null,
    result: null, // result of action ["success", "error"]
    id: null, // action id for fetching simplified action
  },
};


const initialAddress = {
  type: '',
  salutation: '',
  name: '',
  company: '',
  address_line1: '',
  address_line2: '',
  address_line3: '',
  post_code: '',
  city: '',
  country_code: '',
  country: '',
  is_default: false
};


// initial position data
export const initialPosition = {
  sku: '',
  variant_sku: '',
  title: '',
  url: '',
  tax_rate: 19,
  quantity: 1,
  net_price: 0,
  total_net_price: 0,
  gross_price: 0,
  total_gross_price: 0,
  category: '',
  unit: 'piece',
  currency: 'EUR',
  id_order_unit: '',
  product_url: '',
  manuel_product: true,
  weight: 0,
  //priceBrutto: '0'
};


// initial position Totals
export const initialPositionTotals = {
  sub_total: 0,
  total_tax: 0,
  total: 0,
  shipping_price: 0,
  discount: 0,
  currency: 'EUR',
};

export const initialEnteredValues = {
  date: UIDates.getCurrentDate(),
  payment_method: '',
  notes: '',
  amount: 0,
};


// initial position data
export const INITIAL_SHIPPING = {
  profile_name: "",
  service: "",
  type: "shipment",
  billing_number: "",
  service_options: {
    premium: true, //waren post icin premium modalda swich olacak oradan true false gelecek
    endorsement: false,//enum:["RETURN","ABANDON"] default: "RETURN"
    additional_insurance: false,
    goGreen: false,
    parcel_outlet_routing: false,
  },
  additional_insurance: { //Transportversicherung
    currency: "EUR", //enum:["EUR","AED","AFN","ALL","AMD","ANG"], 
    value: 0 //value:min:0, max:10000
  },
  tracking_id: "",
  dp_product_id: "",
  price: 0,
  weight: 2,
  delivery_date: UIDates.getCurrentDate(),
  labeled: "labeled",
  customs: {
    export_type: "", //enum:["OTHER","PRESENT","COMMERCIAL_SAMPLE","DOCUMENT","RETURN_OF_GOODS","COMMERCIAL_GOODS"] required
    export_description: "", // if exportType is OTHER, this field is required
  }
};

export const initialTax = {
  category: '',
  country_code: '',
  legal_text: '',
};

export const initialPaymentWarnings = {
  type: null,
  amount: 0,
  after_text: 'Sehr geehrte Damen und Herren,',
  before_text: 'Beste Dank.\nBei Problemen oder Fragen stehen wir Ihnen gerne jederzeit zur Verfügung.\nWie wünschen Ihnen noch einen schönen Tag.',
};

// Formik Initial Document
export const initialDocument = {

  order_status: 'open',
  order_id: '',
  order_date: null,

  marketplace: '',
  marketplace_url: '',
  marketplace_id: '',

  payment_status: 'open',
  pricing_summary: initialPositionTotals,
  entered_payment: [],
  position: [initialPosition],
  tax: initialTax,

  shipping: [],

  customer_details: {
    id: '',
    number: 0,
    type: '',
    name: '',
    phone: '',
    email: '',
    company: '',
    mobile: '',
    tax_number: '',
    vat_id: '',
    fax: '',
    notes: '',
    discount: 0,
    invoice_address: initialAddress,
    delivery_address: initialAddress,
    bank: {
      name: '',
      iban: '',
      bic: ''
    },
    source: {
      buyer_id: '',
      marketplace: ''
    },
  },
  payment_warnings: [],
  subtitle: {
    header: '',
    footer: ''
  },

  total_weight: 2,
  order_notice: '',
  notes: '',

};


export const searchKeys = [
  'id',
  'order_id',
  'marketplace',
  'marketplace_url',
  'customer_details.number',
  'customer_details.email',
  'customer_details.phone',
  'customer_details.name',
  'customer_details.company',
  'customer_details.delivery_address.post_code',
];


export const initialSearchParams = {
  searchText: '',
  searchKeys: searchKeys,
};

export const initialQueryParams = { page: 1, limit: 10 };


export const initialDocumentQueryParams = {
  pagination: initialQueryParams,
  search: '',
  filter: {},
  order: 'order_date DESC',
};


export const allQueryKeys = [
  'date_from',
  'date_to',
  'open_invoice',
  'credit_created',
  'non_labeled',
  'search',
  'status',
  'invoice_ebay'
];


export const allFilterKeys = [
  'date_from',
  'date_to',
  'open_invoice',
  'credit_created',
  'non_labeled',
  'invoice_ebay'
];


export const LABEL_OPTIONS = [
  {
    value: 'dhl',
    label: 'DHL',
    icon: <Icon.DhlOld className='rounded' />,
    apiConnection: true,
    return: true
  },
  {
    value: 'dhl_new',
    label: 'DHL (Neue API)',
    icon: <Icon.Dhl className='rounded' />,
    apiConnection: true,
    return: true
  },
  {
    value: 'dhl_warenpost',
    label: 'DHL Kleinpaket',
    icon: <Icon.DhlOldWarenpost className='rounded' />,
    apiConnection: true,
    return: true
  },
  {
    value: 'dhl_new_warenpost',
    label: 'DHL Kleinpaket (Neue API)',
    icon: <Icon.DhlWarenpost className='rounded' />,
    apiConnection: true,
    return: true
  },
  {
    value: 'gls',
    label: 'GLS',
    icon: <Icon.Gls className='rounded' />,
    apiConnection: true,
  },
  {
    value: 'dpd',
    label: 'DPD',
    icon: <Icon.Dpd className='rounded' />,
    apiConnection: true,
    return: true
  },
  {
    value: 'deutsche_post',
    label: 'Deutsche Post',
    icon: <Icon.DeutschePost className='rounded' />,
    apiConnection: true
  },
  {
    value: 'ups',
    label: 'UPS',
  },
  {
    value: 'hermes',
    label: 'Hermes',
  },
  {
    value: 'fedex',
    label: 'FedEx',
  },
  {
    value: 'tnt_express',
    label: 'TNT Express',
  },
  {
    value: 'other',
    label: 'Other',
  },
];


export const getShipperName = (value) => {
  return LABEL_OPTIONS.find(item => item.value === value)?.label;
};


export const getShipperOptions = (apiConnection) => {
  if (apiConnection) {
    return LABEL_OPTIONS.filter(item => item.apiConnection === true);
  } else {
    return LABEL_OPTIONS;
  }
};


export const SHIPPER_TYPE_OPTIONS = [
  {
    value: 'shipment',
    label: <FormattedMessage id="DOCUMENT.SHIPPING.TYPE_SHIPMENT" />,
  },
  {
    value: 'return',
    label: <FormattedMessage id="DOCUMENT.SHIPPING.TYPE_RETURN" />,
  }
]


export const SHIPPER_LABEL_OPTIONS = [
  {
    value: 'labeled',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.CREATE_LABEL.AUTOMATIC' />,
  },
  {
    value: 'unlabeled',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.CREATE_LABEL.MANUEL' />,
  }
];


export const UNLABELED_OPTIONS = [
  {
    value: 'dhl',
    label: 'DHL',
  },
  {
    value: 'dhl_warenpost',
    label: 'DHL Kleinpaket',
  },
  {
    value: 'deutsche_post',
    label: 'Deutsche Post',
  },
  {
    value: 'dpd',
    label: 'DPD',
  },
  {
    value: 'gls',
    label: 'GLS',
  },
  {
    value: 'ups',
    label: 'UPS',
  },
  {
    value: 'hermes',
    label: 'Hermes',
  },
  {
    value: 'fedex',
    label: 'FedEx',
  },
  {
    value: 'tnt_express',
    label: 'TNT Express',
  },
  {
    value: 'other',
    label: 'Other',
  },
];


export const EXPORT_TYPE_OPTIONS = [
  {
    value: 'COMMERCIAL_GOODS',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE.COMMERCIAL_GOODS' />,
  },
  {
    value: 'DOCUMENT',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE.DOCUMENT' />,
  },
  {
    value: 'PRESENT',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE.PRESENT' />,
  },
  {
    value: 'COMMERCIAL_SAMPLE',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE.COMMERCIAL_SAMPLE' />,
  },
  {
    value: 'RETURN_OF_GOODS',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE.RETURN_OF_GOODS' />,
  },
  {
    value: 'OTHER',
    label: <FormattedMessage id='DOCUMENT.SHIPPING.DHL.CUSTOMS.EXPORT_TYPE.OTHER' />,
  },
];


export const paymentMethods = [
  {
    value: 'amazon_pay',
    label: 'Amazon Pay'
  },
  {
    value: 'apple_pay',
    label: 'Apple Pay'
  },
  {
    value: 'credit_card',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.CREDIT_CARD" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.CREDIT_CARD',
  },
  {
    value: 'click_and_buy',
    label: 'ClickandBuy'
  },
  {
    value: 'ec-card',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.EC_CARD" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.EC_CARD',
  },
  {
    value: 'google_pay',
    label: 'Google Pay'
  },
  {
    value: 'klarna',
    label: 'Klarna'
  },
  {
    value: 'transfer',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.TRANSFER" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.TRANSFER',
  },
  {
    value: 'purchase_invoice',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.PURCHASE_ON_BILL" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.PURCHASE_ON_BILL',
  },
  {
    value: 'direct_debit',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.LASTSCRIPT" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.LASTSCRIPT',
  },
  {
    value: 'money_bookers',
    label: 'Moneybookers'
  },
  {
    value: 'finance/hire_purchase',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.FINANCIAL_PURCHASE" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.FINANCIAL_PURCHASE',
  },
  {
    value: 'cash_payment',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.CASH_PAYMENT" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.CASH_PAYMENT',
  },
  {
    value: 'pre_payment',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.PRE_PAYMENT" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.PRE_PAYMENT',
  },
  {
    value: 'paypal',
    label: 'PayPal'
  },
  {
    value: 'paysafecard',
    label: 'Paysafecard'
  },
  {
    value: 'cash_on_delivery',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.COD" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.COD',
  },
  {
    value: 'otto',
    label: 'Otto'
  },
  {
    value: 'check24',
    label: 'Check24'
  },
  {
    value: 'ebay',
    label: 'Ebay'
  },
  {
    value: 'kaufland',
    label: 'Kaufland'
  },
  {
    value: 'manomano',
    label: 'ManoMano'
  },
  {
    value: 'sepa',
    label: 'Sepa'
  },
  {
    value: 'other',
    label: <FormattedMessage id="DOCUMENT.UI_HELPERS.PAYMENT_METHOD.OTHER" />,
    labelId: 'DOCUMENT.UI_HELPERS.PAYMENT_METHOD.OTHER',
  },
];


export const PAYMENT_STATUS = [
  {
    value: 'open',
    label: <FormattedMessage id="GENERAL.OPEN" />,
    className: 'danger'
  },
  {
    value: 'processing',
    label: <FormattedMessage id="GENERAL.PROCESSING" />,
    className: 'info'
  },
  {
    value: 'paid',
    label: <FormattedMessage id="GENERAL.PAID" />,
    className: 'success'
  },
  {
    value: 'partially_paid',
    label: <FormattedMessage id="GENERAL.PAID" />,
    className: 'warning'
  },
  {
    value: 'overpaid',
    label: <FormattedMessage id="GENERAL.OVERPAID" />,
    className: 'dangerous'
  }
];

export const getPaymentStatus = (cell) => PAYMENT_STATUS.find(payment => payment.value === cell);


export const TAX_CATEGORY = [
  //{ value: null, label: "Steuerkategorie auswählen" },
  {
    value: 'domestic', // Inland
    label: 'DOCUMENT.UI_HELPERS.TAX_CATEGORY.LABEL.INLAND',
  },
  {
    value: 'abroad', // Ausland
    label: "DOCUMENT.UI_HELPERS.TAX_CATEGORY.LABEL.ABROAD",
  },
  {
    value: 'eu_region', //Ausland-EU
    label: "DOCUMENT.UI_HELPERS.TAX_CATEGORY.LABEL.ABROAD_EU",
  },
  {
    value: 'eu_region_other', //Ausland-EU sonstige
    label: "DOCUMENT.UI_HELPERS.TAX_CATEGORY.LABEL.SERVICES",
  },
  {
    value: 'small_business', // umzatzfreie für Kleinunternehmer
    label: "DOCUMENT.UI_HELPERS.TAX_CATEGORY.LABEL.SALES_FREE",
  },
  {
    value: 'different_tax', // Diffenzbesteuerung
    label: "DOCUMENT.UI_HELPERS.TAX_CATEGORY.LABEL.DIFFERENCE",
  },
];


export const countries = [
  { value: 'AD', label: 'Andorra' },
  { value: 'AE', label: 'Vereinigte Arabische Emirate' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AG', label: 'Antigua und Barbuda' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AL', label: 'Albanien' },
  { value: 'AM', label: 'Armenien' },
  { value: 'AN', label: 'Niederländische Antillen' },
  { value: 'AO', label: 'Angola' },
  { value: 'AQ', label: 'Antarktis' },
  { value: 'AR', label: 'Argentinien' },
  { value: 'AS', label: 'Amerikanisch-Samoa' },
  { value: 'AT', label: 'Österreich' },
  { value: 'AU', label: 'Australien' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AX', label: 'Åland' },
  { value: 'AZ', label: 'Aserbaidschan' },
  { value: 'BA', label: 'Bosnien und Herzegowina' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BD', label: 'Bangladesch' },
  { value: 'BE', label: 'Belgien' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BG', label: 'Bulgarien' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BI', label: 'Burundi' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BO', label: 'Bolivien' },
  { value: 'BR', label: 'Brasilien' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BV', label: 'Bouvetinsel' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BY', label: 'Belarus (Weißrussland)' },
  { value: 'BZ', label: 'Belize' },
  { value: 'CA', label: 'Kanada' },
  { value: 'CC', label: 'Kokosinseln (Keelinginseln)' },
  { value: 'CD', label: 'Kongo' },
  { value: 'CF', label: 'Zentralafrikanische Republik' },
  { value: 'CG', label: 'Republik Kongo' },
  { value: 'CH', label: 'Schweiz' },
  { value: 'CI', label: 'Elfenbeinküste' },
  { value: 'CK', label: 'Cookinseln' },
  { value: 'CL', label: 'Chile' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CN', label: 'China, Volksrepublik' },
  { value: 'CO', label: 'Kolumbien' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CU', label: 'Kuba' },
  { value: 'CV', label: 'Kap Verde' },
  { value: 'CX', label: 'Weihnachtsinsel' },
  { value: 'CY', label: 'Zypern' },
  { value: 'CZ', label: 'Tschechische Republik' },
  { value: 'DE', label: 'Deutschland' },
  { value: 'DJ', label: 'Dschibuti' },
  { value: 'DK', label: 'Dänemark' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominikanische Republik' },
  { value: 'DZ', label: 'Algerien' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EE', label: 'Estland (Reval)' },
  { value: 'EG', label: 'Ägypten' },
  { value: 'EH', label: 'Westsahara' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'ES', label: 'Spanien' },
  { value: 'ET', label: 'Äthiopien' },
  { value: 'FI', label: 'Finnland' },
  { value: 'FJ', label: 'Fidschi' },
  { value: 'FK', label: 'Falklandinseln (Malwinen)' },
  { value: 'FM', label: 'Mikronesien' },
  { value: 'FO', label: 'Färöer' },
  { value: 'FR', label: 'Frankreich' },
  { value: 'GA', label: 'Gabun' },
  { value: 'GB', label: 'Großbritannien' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GE', label: 'Georgien' },
  { value: 'GF', label: 'Französisch-Guayana' },
  { value: 'GG', label: 'Guernsey (Kanalinsel)' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GL', label: 'Grönland' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GQ', label: 'Äquatorialguinea' },
  { value: 'GR', label: 'Griechenland' },
  { value: 'GS', label: 'Südgeorgien und die Südl. Sandwichinseln' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GU', label: 'Guam' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HK', label: 'Hongkong' },
  { value: 'HM', label: 'Heard- und McDonald-Inseln' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HR', label: 'Kroatien' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HU', label: 'Ungarn' },
  { value: 'ID', label: 'Indonesien' },
  { value: 'IE', label: 'Irland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IM', label: 'Insel Man' },
  { value: 'IN', label: 'Indien' },
  { value: 'IO', label: 'Britisches Territorium im Indischen Ozean' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IR', label: 'Iran' },
  { value: 'IS', label: 'Island' },
  { value: 'IT', label: 'Italien' },
  { value: 'JE', label: 'Jersey (Kanalinsel)' },
  { value: 'JM', label: 'Jamaika' },
  { value: 'JO', label: 'Jordanien' },
  { value: 'JP', label: 'Japan' },
  { value: 'KE', label: 'Kenia' },
  { value: 'KG', label: 'Kirgisistan' },
  { value: 'KH', label: 'Kambodscha' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KM', label: 'Komoren' },
  { value: 'KN', label: 'St. Kitts und Nevis' },
  { value: 'KP', label: 'Nordkorea' },
  { value: 'KR', label: 'Südkorea' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KY', label: 'Kaimaninseln' },
  { value: 'KZ', label: 'Kasachstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LB', label: 'Libanon' },
  { value: 'LC', label: 'St. Lucia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LT', label: 'Litauen' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'LV', label: 'Lettland' },
  { value: 'LY', label: 'Libyen' },
  { value: 'MA', label: 'Marokko' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MD', label: 'Moldawien' },
  { value: 'MG', label: 'Madagaskar' },
  { value: 'MH', label: 'Marshallinseln' },
  { value: 'MK', label: 'Mazedonien' },
  { value: 'ML', label: 'Mali' },
  { value: 'MM', label: 'Myanmar (Burma)' },
  { value: 'MN', label: 'Mongolei' },
  { value: 'MO', label: 'Macau' },
  { value: 'MP', label: 'Nördliche Marianen' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauretanien' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MT', label: 'Malta' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MV', label: 'Malediven' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MX', label: 'Mexiko' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MZ', label: 'Mosambik' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NC', label: 'Neukaledonien' },
  { value: 'NE', label: 'Niger' },
  { value: 'NF', label: 'Norfolkinsel' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NL', label: 'Niederlande' },
  { value: 'NO', label: 'Norwegen' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NU', label: 'Niue' },
  { value: 'NZ', label: 'Neuseeland' },
  { value: 'OM', label: 'Oman' },
  { value: 'PA', label: 'Panama' },
  { value: 'PE', label: 'Peru' },
  { value: 'PF', label: 'Französisch-Polynesien' },
  { value: 'PG', label: 'Papua-Neuguinea' },
  { value: 'PH', label: 'Philippinen' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PL', label: 'Polen' },
  { value: 'PM', label: 'St. Pierre und Miquelon' },
  { value: 'PN', label: 'Pitcairninseln' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'PS', label: 'Palästina' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PW', label: 'Palau' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'QA', label: 'Katar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Rumänien' },
  { value: 'RU', label: 'Russische Föderation' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'SA', label: 'Saudi-Arabien' },
  { value: 'SB', label: 'Salomonen' },
  { value: 'SC', label: 'Seychellen' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SE', label: 'Schweden' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SH', label: 'St. Helena' },
  { value: 'SI', label: 'Slowenien' },
  { value: 'SJ', label: 'Svalbard und Jan Mayen' },
  { value: 'SK', label: 'Slowakei' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SM', label: 'San Marino' },
  { value: 'SN', label: 'Senegal' },
  { value: 'SO', label: 'Somalia' },
  { value: 'SR', label: 'Suriname' },
  { value: 'ST', label: 'São Tomé und Príncipe' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'SY', label: 'Syrien' },
  { value: 'SZ', label: 'Swasiland' },
  { value: 'TC', label: 'Turks- und Caicosinseln' },
  { value: 'TD', label: 'Tschad' },
  { value: 'TF', label: 'Französische Süd- und Antarktisgebiete' },
  { value: 'TG', label: 'Togo' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TJ', label: 'Tadschikistan' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TN', label: 'Tunesien' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TR', label: 'Türkei' },
  { value: 'TT', label: 'Trinidad und Tobago' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TZ', label: 'Tansania' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UM', label: 'Amerikanisch-Ozeanien' },
  { value: 'US', label: 'Vereinigte Staaten von Amerika' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Usbekistan' },
  { value: 'VA', label: 'Vatikanstadt' },
  { value: 'VC', label: 'St. Vincent und die Grenadinen' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VG', label: 'Britische Jungferninseln' },
  { value: 'VI', label: 'Amerikanische Jungferninseln' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'WF', label: 'Wallis und Futuna' },
  { value: 'WS', label: 'Samoa' },
  { value: 'YE', label: 'Jemen' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'ZA', label: 'Südafrika' },
  { value: 'ZM', label: 'Sambia' },
  { value: 'ZW', label: 'Simbabwe' },
  { value: 'RS', label: 'Serbien' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'BL', label: 'Saint-Barthélemy' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius und Saba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'MF', label: 'Saint-Martin (franz. Teil)' },
  { value: 'SX', label: 'Sint Maarten (niederl. Teil)' },
  { value: 'SS', label: 'Sudsudan!Südsudan' },
];


export const ORDER_STATUS = [
  {
    value: "open",
    label: <FormattedMessage id="GENERAL.OPEN" />,
    long_label: <FormattedMessage id="DOCUMENT.OPEN_ORDER" />,
    status: <FormattedMessage id="GENERAL.STATUS_OPEN" />,
    className: 'danger',
  },
  {
    value: "completed",
    label: <FormattedMessage id="GENERAL.COMPLETED" />,
    long_label: <FormattedMessage id="DOCUMENT.COMPLETED_ORDER" />,
    status: <FormattedMessage id="GENERAL.STATUS_COMPLETED" />,
    className: 'success',
  },
  {
    value: "cancelled",
    label: <FormattedMessage id="GENERAL.CANCELLED" />,
    long_label: <FormattedMessage id="DOCUMENT.CANCELLED_ORDER" />,
    status: <FormattedMessage id="GENERAL.STATUS_CANCELLED" />,
    className: 'warning',
  },
  {
    value: "all-orders",
    label: <FormattedMessage id="DOCUMENT.ORDER_ALL" />,
    long_label: <FormattedMessage id="DOCUMENT.ORDER_ALL" />,
    className: 'secondary',
  }
];


export const paymentWarnings = [
  {
    value: "reminder",
    label: <FormattedMessage id='DOCUMENT.ACTION.PAYMENT_REMINDER' />,
  },
  {
    value: "warning_1",
    label: <FormattedMessage id='DOCUMENT.ACTION.REMINDER1' />,
  },
  {
    value: "warning_2",
    label: <FormattedMessage id='DOCUMENT.ACTION.REMINDER2' />,
  }
];


export const documentToastMessage = (customerDetails, positions) => {

  const { id, invoice_address, delivery_address } = customerDetails;

  const isPiece = positions?.some(item => item.unit === 'piece' && !Number.isInteger(+item.quantity))

  switch (true) {
    case invoice_address.name === '' && invoice_address.company === '':
      return 'DOCUMENT.EDIT.BILLING_ADDRESS.NAME';
    case delivery_address.name === '' && delivery_address.company === '':
      return `DOCUMENT.EDIT.SHIPPING_ADDRESS.NAME`;
    case invoice_address.address_line1 === '':
      return `DOCUMENT.EDIT.BILLING_ADDRESS.STRASSE`;
    case delivery_address.address_line1 === '':
      return `DOCUMENT.EDIT.SHIPPING_ADDRESS.STRASSE`;
    case invoice_address.post_code === '':
      return 'DOCUMENT.EDIT.BILLING_ADDRESS.POST_CODE';
    case delivery_address.post_code === '':
      return 'DOCUMENT.EDIT.SHIPPING_ADDRESS.POST_CODE';
    case invoice_address.city === '':
      return 'DOCUMENT.EDIT.BILLING_ADDRESS.CITY';
    case delivery_address.city === '':
      return 'DOCUMENT.EDIT.SHIPPING_ADDRESS.CITY';
    case positions && positions.length <= 0:
      return 'DOCUMENT.EDIT.POSITION';
    case !every(positions, 'title'):
      return 'DOCUMENT.EDIT.CHECK.TITLE_PRODUCT';
    case isPiece:
      return 'PRODUCT.VARIANT.IS_VALID.QUANTITY1';
    case !id:
      return 'DOCUMENT.EDIT.CUSTOMER_SELECT';
    // case !every(positions, 'sku'):
    //   return 'DOCUMENT.EDIT.CHECK.SKU_PRODUCT';
    default:
      return null;
  }
}



export const EU_COUNTRIES = [
  { value: 'AT', label: 'Österreich' },
  { value: 'BE', label: 'Belgien' },
  { value: 'BG', label: 'Bulgarien' },
  { value: 'CY', label: 'Republik Zypern' },
  { value: 'CZ', label: 'Tschechien' },
  { value: 'DE', label: 'Deutschland' },
  { value: 'DK', label: 'Dänemark' },
  { value: 'EE', label: 'Estland' },
  { value: 'ES', label: 'Spanien' },
  { value: 'FI', label: 'Finnland' },
  { value: 'FR', label: 'Frankreich' },
  { value: 'GR', label: 'Griechenland' },
  { value: 'HR', label: 'Kroatien' },
  { value: 'HU', label: 'Ungarn' },
  { value: 'IE', label: 'Irland' },
  { value: 'IT', label: 'Italien' },
  { value: 'LT', label: 'Litauen' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'LV', label: 'Lettland' },
  { value: 'MT', label: 'Malta' },
  { value: 'NL', label: 'Niederlande' },
  { value: 'PL', label: 'Polen' },
  { value: 'PT', label: 'Portugal' },
  { value: 'RO', label: 'Rumänien' },
  { value: 'SE', label: 'Schweden' },
  { value: 'SI', label: 'Slowenien' },
  { value: 'SK', label: 'Slowakei' }
];

// Zafer Bey
// ---------------- Inland --------------------
// Herzaman aksi manule yapilmadigi sürece ürün kisminda Vergi olacak hep

// -------------------- Ausland -----------------
// Avrupa disi vergisiz hep olacak manuel aksi yapilmadigi sürece ve vergisiz oldugunda yani aksi yapilmadiginda vergi kannu "Umsatzsteuerfreie Lieferung gemäß §4 Nr. 1a,  §6 UStG"

// -------------------- Ausland-EU ---------------------
// Firma ve UstId girmisse Vergisiz olacak gerisinde vergili aksi olmadigi sürece vergi kanunu "Umsatzsteuerfreie Lieferung gemäß §4 Nr. 1b,  §6a UStG"

// ----------------- Ausland-EU sonstige Leistungen --------------
// Bunu sectiginde herhangi bir sart yok vergisiz olacak ve kanunu "Die Leistung unterliegen dem Reverse-Change-Verfahren - die Mehrwertsteuer schuldet der Leistungsemfänger It. §13b UStG."

// ------------ Umsatzfrei für Kleinunternehmer ----------
// Secildiginde vergisi olacak ülke ici ve kanunu "Als Kleinunternehmer im Sinne von § 19 Abs. 1 UStG wird keine Umsatzsteuer berechnet"

// ------------- Differenzbesteuerung -----------
// Oradan kalmasi lazim o degisik bir olay yani bir ürün ikinci el alinmis vergisi zaten ödemis bir daha ödememek icin yapilan birsey bunu söyle cözülebilir ek alan acilir mesela checkbox gibi aktiv oldugunda ve  positionlarda en az bir tane 0 vergili satis olursa ülke icin gecerli olmak üzere ve müsteri onu aktivlestirmisse faturada olmasi gereken yazi  "Diese Ware unterliegt der Differenzbesteuerung. Daher wird die im Kaufpreis enthaltene Umsatzsteuer in der Rechnung nicht gesondert ausgewiesen."


// calculate prices for position table
export const calculatePositionPrices = positions => {
  const netTotal = positions.reduce((acc, net) => acc + +net.total_net_price, 0);
  const bruttoTotal = positions.reduce((acc, net) => acc + +net.total_gross_price, 0);
  const tax = bruttoTotal - netTotal;
  // calculate price discount tax total
  const priceDiscountTaxTotal = positions.reduce((total, position) => {
    const priceDiscount = position.price_discount ?? 0;
    const taxRate = position.tax_rate ?? 0;
    if (priceDiscount !== 0) {
      const priceDiscountTax = priceDiscount - priceDiscount / (1 + taxRate / 100);
      return total + priceDiscountTax;
    }
    return total;
  }, 0);
  return { sub_total: netTotal, total: bruttoTotal, total_tax: tax, currency: positions[0]?.currency, priceDiscountTaxTotal };
};



export const paymentAmountDifference = (positions, document) => {

  const { entered_payment, payment_warnings, pricing_summary: { shipping_price, discount, total_price_discount } } = document;

  const bruttoTotal = positions?.reduce((acc, net) => acc + (+net.total_gross_price || 0), 0) || 0;

  const paid = entered_payment?.reduce((acc, payment) => acc + (+payment.amount || 0), 0) || 0;

  const paymentWarning = payment_warnings?.reduce((acc, warning) => acc + (+warning.amount || 0), 0) || 0;

  const discountRate = +(((bruttoTotal || 0) * Math.max(0, discount || 0)) / 100) || 0;

  const discountAmount = +(total_price_discount ? total_price_discount : 0);

  const totalPrice = (bruttoTotal || 0) + (shipping_price ? +shipping_price : 0) + (paymentWarning || 0) - (discountRate || 0) - (discountAmount || 0);

  const paymentAmountDifference = (+totalPrice?.toFixed(2) - paid?.toFixed(2));

  return paymentAmountDifference;
};


export const PaymentStatus = (positions, document) => {

  const paymentAmountDifferenceResult = paymentAmountDifference(positions, document);

  return paymentAmountDifferenceResult > 0 ? 'open' : paymentAmountDifferenceResult < 0 ? "overpaid" : document.payment_status === "processing" ? "processing" : 'paid';
};


export const totalAmountPaymentWarning = (pricingSummary) => {

  const { total, shipping_price, discount, total_price_discount } = pricingSummary;

  const discountRate = +((+total * Math.max(0, discount || 0)) / 100) ?? 0;

  const discountAmount = +(total_price_discount && total_price_discount > 0 ? -total_price_discount : 0)

  const totalPrice = total + +shipping_price - discountRate - discountAmount;

  return totalPrice;

}


export const getTaxStatus = (userCountryCode, orderCountryCode) => {

  if (userCountryCode === "" || orderCountryCode === "" || userCountryCode === orderCountryCode) {
    return 'domestic';
  };

  // Check if the order is from a European Union member country ES
  const isEUMember = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'SE'];
  if (isEUMember.includes(orderCountryCode)) {
    return 'eu_region';
  } else {
    return 'abroad';
  }
}