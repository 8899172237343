import React, { useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



function ProductItem({ pos, row }) {

  const truncatedTitle = `${pos?.quantity}`.length + (pos?.sku?.length || 0) + (pos?.title?.length || 0) > 50
    ? pos?.title.substring(0, Math.max(50 - (pos?.sku?.length || 0), 0)) + '...'
    : pos?.title;

  const productName = (
    <>
      <span className="label label-inline label-rounded label-md label-light text-muted font-weight-bold mr-1 mb-1">{pos.quantity}x</span>
      {pos?.sku && <span className="text-primary mr-1">[{pos.sku}]</span>}
      <span>{truncatedTitle}</span>
    </>
  );

  return (
    <OverlayTrigger overlay={
      <Tooltip id="document-print-tooltip">

        <div className="d-flex align-items-start text-left">

          <div className="symbol symbol-40 symbol-light mr-3">
            <span className="symbol-label font-size-h5 text-dark-25">
              {`${pos?.quantity}x`}
            </span>
          </div>

          <div>
            <div className="font-weight-bolder text-dark-75">
              {pos?.title}
            </div>
            <div className="font-weight-bold font-size-sm text-dark-50">
              {pos?.sku}
              {(pos?.id_order_unit && row.marketplace === 'ebay') && <div className='mt-1'>{"line Item Id: " + pos?.id_order_unit}</div>}
            </div>
          </div>

        </div>

      </Tooltip>
    }>
      {pos.product_url
        ? <a href={pos.product_url.toString()} className='d-block text-dark-75 text-hover-primary' target='_blank' rel="noopener noreferrer">
          {productName}
        </a>
        : <div>
          {productName}
        </div>
      }
    </OverlayTrigger>
  );
}



export function ProductColumnFormatter({ cell, row, rowIndex }) {
  const [collapse, setCollapse] = useState(false);
  const contentRef = useRef(null);
  return (
    <>

      <div className='overflow-hidden' ref={contentRef} style={{ maxHeight: collapse ? `${contentRef.current?.scrollHeight}px` : "45px", transition: "max-height 0.4s ease" }}>
        {row.position.map((pos, i) => (
          <ProductItem key={i} pos={pos} row={row} />
        ))}
      </div>

      {row.position.length > 2 && <div type="button" className="d-flex align-items-center justify-content-center text-primary mt-n1 py-0" onClick={() => setCollapse(prev => !prev)} id='btn_chevron'>
        <div className='d-flex mr-2' style={{ transform: collapse ? "" : "rotate(-180deg)", transition: "transform 333ms" }}><Icon.ChevronUp /></div> {collapse ? "Show less..." : `Show ${row.position.length - 2} more...`}
      </div>}

    </>
  );
}
