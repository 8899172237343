import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { ShippingSettings } from './shipping-settings/ShippingSettings';
import { DocumentSettings } from './document-settings/DocumentSettings';
import { ProductSettings } from './product-settings/ProductSettings';
import { CompanyEdit } from './company/company-edit/CompanyEdit';
import { CancelButton, SaveButton } from '_metronic/_partials';
import LoginSettings from './login-settings/LoginSettings';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { CategoryPage } from './category/CategoryPage';
import { CompanyCard } from './company/CompanyCard';
import { ContentRoute } from '_metronic/layout';
import SettingsMenu from './SettingsMenu';
import { Icon } from '_metronic/_icons';



export default function SettingsCard() {

  const { formatMessage: intl } = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();

  const { loading } = useSelector(state => ({
    loading: state.settings.loading,
  }));


  // Company settings
  const companyBtnRef = useRef();
  const saveCompany = () => companyBtnRef.current?.click();

  // Product settings
  const productBtnRef = useRef();
  const saveProduct = () => productBtnRef.current?.click();


  // Document settings
  const docBtnRef = useRef();
  const saveDocument = () => docBtnRef.current?.click();


  const SETTINGS_MENU = [
    {
      name: "company",
      title: "COMPANY.TITLE",
      route: ModuleRoutes.SETTINGS_COMPANY,
      toolbar: <Link to={ModuleRoutes.SETTINGS_COMPANY_EDIT} className='btn btn-primary svg-icon svg-icon-sm font-weight-bold' disabled={loading}>
        <FormattedMessage id="GENERAL.EDIT" />
      </Link>,
    },
    {
      name: "company_edit",
      title: "COMPANY.EDIT.TITLE",
      route: ModuleRoutes.SETTINGS_COMPANY_EDIT,
      toolbar:
        <>
          <CancelButton onClick={() => history.push(ModuleRoutes.SETTINGS_COMPANY)} disabled={loading} />
          <SaveButton companyBtnRef={companyBtnRef} onClick={saveCompany} disabled={loading} />
        </>
    },
    {
      name: "category",
      title: "CATEGORY.TITLE_PLURAL",
      route: ModuleRoutes.SETTINGS_CATEGORY,
    },
    {
      name: "login",
      title: "AUTH.GENERAL.ACCOUNT_SETTINGS",
      route: ModuleRoutes.SETTINGS_LOGIN,
    },
    {
      name: "products",
      title: "PRODUCT.TITLE",
      route: ModuleRoutes.SETTINGS_PRODUCT,
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveProduct} disabled={loading} />
    },
    {
      name: "shipping",
      title: "GENERAL.SHIPPING",
      route: ModuleRoutes.SETTINGS_SHIPPING,
      toolbar: <SaveButton className='invisible' disabled={true} />
    },
    {
      name: "digital_product",
      title: "MENU.ASIDE.SETTING.DIGITAL_PRODUCTS",
      route: ModuleRoutes.SETTINGS_DOCUMENT_FN('digital_product'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "invoices",
      title: "DOCUMENT.INVOICE_TITLE",
      route: ModuleRoutes.SETTINGS_DOCUMENT_FN('invoices'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "orders",
      title: "DOCUMENT.ORDER_TITLE",
      route: ModuleRoutes.SETTINGS_DOCUMENT_FN('orders'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "offers",
      title: "DOCUMENT.OFFERS_TITLE",
      route: ModuleRoutes.SETTINGS_DOCUMENT_FN('offers'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "waybills",
      title: "DOCUMENT.WAYBILL_TITLE",
      route: ModuleRoutes.SETTINGS_DOCUMENT_FN('waybills'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "credits",
      title: "DOCUMENT.CREDIT_TITLE",
      route: ModuleRoutes.SETTINGS_DOCUMENT_FN('credits'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
  ];


  const page = SETTINGS_MENU.find(item => item.name === "category" ? pathname.includes(item.route) : pathname === item.route);


  return (
    <Card className="card-box">

      <CardHeader
        icon={<Icon.Settings />}
        // title={`${intl({ id: "SETTINGS.TITLE" })}${page ? " > " + intl({ id: page?.title }) : ""}`}
        title={intl({ id: "SETTINGS.TITLE" })}
        className="display-5"
        id='title_settings'
        sticky
      >
        <CardHeaderToolbar>
          {page?.toolbar}
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody className="d-flex flex-column flex-md-row">

        <div className="flex-md-row-auto w-md-200px mr-md-8">
          <SettingsMenu />
        </div>

        <div className="flex-row-fluid">
          <Switch>

            <Redirect exact={true} from={ModuleRoutes.SETTINGS} to={ModuleRoutes.SETTINGS_COMPANY} />

            <ContentRoute exact path={ModuleRoutes.SETTINGS_COMPANY} component={CompanyCard} />

            <ContentRoute exact path={ModuleRoutes.SETTINGS_COMPANY_EDIT}>
              <CompanyEdit companyBtnRef={companyBtnRef} onClick={saveCompany} />
            </ContentRoute>

            <ContentRoute path={ModuleRoutes.SETTINGS_CATEGORY} component={CategoryPage} />

            <ContentRoute exact path={ModuleRoutes.SETTINGS_DOCUMENT}>
              <DocumentSettings docBtnRef={docBtnRef} />
            </ContentRoute>

            <ContentRoute exact path={ModuleRoutes.SETTINGS_PRODUCT}>
              <ProductSettings productBtnRef={productBtnRef} />
            </ContentRoute>

            <ContentRoute exact path={ModuleRoutes.SETTINGS_SHIPPING} component={ShippingSettings} />

            <ContentRoute exact path={ModuleRoutes.SETTINGS_LOGIN} component={LoginSettings} />

            <ContentRoute exact path={ModuleRoutes.SETTINGS} component={CompanyCard} />

          </Switch>
        </div>

      </CardBody>

    </Card>
  );
}