import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Modal, Row, Col, OverlayTrigger, Tooltip, Alert, Dropdown } from 'react-bootstrap';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { DHL_ACCOUNT_OPTIONS } from 'app/modules/connections/_context/SettingsUIHelpers';
import { CloseButton, SelectCounter, UserInfoDropdown } from '_metronic/_partials';
import { LABEL_OPTIONS } from 'app/modules/documents/_context/DocumentsUIHelpers';
import * as columnFormatters from '../../../document-table/column-formatters';
import { DocumentActions } from 'app/modules/documents/_redux/actions';
import { convertPriceCurrency, theme, UIDates } from '_metronic/_helpers';
import { getConnection } from 'constants/connectionSettings';
import { validator } from '../column-formatters/validator';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useWindowSize } from 'app/hooks';
import { toast } from 'react-toastify';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';
import RemainingBalance from 'app/modules/connections/connection-table/column-formatter';



export function ShippingAddFormGrouping() {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();
  const { windowSize } = useWindowSize();

  const [processed, setProcessed] = useState(false);
  const [labelPrint, setLabelPrint] = useState(true);
  const [returnLabelPrint, setReturnLabelPrint] = useState(true);
  const [profileName, setProfileName] = useState("");
  const [manualClear, setManualClear] = useState(false);
  const [serviceOptions, setServiceOptions] = useState({
    goGreen: false,
    premium: false,
    endorsement: false,
  });

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showLabelGroupingDialog,
    onHide: UIContext.closeLabelGroupingDialog,
    shipperName: UIContext.shipperName,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    openLabelsTable: UIContext.openLabelsTable,
    openLabelSettingDialog: UIContext.openLabelSettingDialog,
    ids: UIContext.ids,
    showPrintLabel: UIContext.showPrintLabel,
  }),
    [
      UIContext.showLabelGroupingDialog,
      UIContext.closeLabelGroupingDialog,
      UIContext.shipperName,
      UIContext.openPrintLabelsDialog,
      UIContext.openLabelsTable,
      UIContext.openLabelSettingDialog,
      UIContext.ids,
      UIContext.showPrintLabel,
    ]);

  const { shippingGrouping, connections, default_shipping } = useSelector(state => ({
    shippingGrouping: state.documents.shippingGrouping,
    connections: state.connections.entities,
    default_shipping: state.settings.shippingSettings.default_shipping,
  }), shallowEqual);

  const tableRowHandler = (oldValue, newValue, row, column) => {

    if (oldValue === newValue) { return; }

    if (column.dataField === 'weight') {
      row.weight = +row.weight.replace(',', '.');
    }
    if (column.dataField === 'price') {
      row.price = +row.price.replace(',', '.');
    }

    if (column.dataField === 'dp_product_id' && row.marketplace === "") {
      const findDeutschePost = deutschePost?.deutsche_post?.product_list.find(item => item.id === newValue)
      row.price = +findDeutschePost?.price / 100 || 0
    }

    dispatch(DocumentActions.updateShippingGrouping(row));
  };

  const onHide = () => {
    UIProps.onHide();
    dispatch(DocumentActions.cleanStatesInStore({ shippingGrouping: [] }));
    dispatch(DocumentActions.cleanStatesInStore({ labels: [] }));
    setServiceOptions({
      goGreen: false,
      premium: false,
      endorsement: false,
    });
    setProcessed(false);
    setLabelPrint(true);
    setReturnLabelPrint(true);
  };
  // const { values: { type, profile_name, labeled }, setFieldValue } = useFormikContext();

  const dhlCustomerNumber = connections.find((item) => item?.name === 'dhl_new')?.dhl?.customerNumber;
  const dhlSubscriptions = connections.find((item) => item?.name === 'dhl_new')?.dhl?.subscriptions || [];
  const dhlAccountOptions = dhlSubscriptions.map(item => {
    const baseOption = DHL_ACCOUNT_OPTIONS?.find((opt) => opt.accountNumber === item.accountNumber);
    return baseOption;
  })

  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setServiceOptions(prevState => ({
      ...prevState,
      [name]: checked
    }));

    if (name === 'goGreen') {
      const getIds = [];
      UIProps.ids.forEach(id => Object.values(id).forEach(i => getIds.push(i)));
      dispatch(DocumentActions.createAllDocuments(getIds, UIProps.shipperName, { goGreen: checked }, profileName));
    }
  }

  const getDHLAccountOptions = (country_code) => {
    return dhlAccountOptions.filter((item) => {
      return country_code === "DE"
        ? UIProps.shipperName === "dhl_new_warenpost"
          ? serviceOptions?.goGreen
            ? (item?.type === "dhl_new_warenpost" && item?.location === "domestic" && item?.goGreen)
            : (item?.type === "dhl_new_warenpost" && item?.location === "domestic" && !item?.goGreen)
          : serviceOptions?.goGreen
            ? (item?.type === "dhl_new" && item?.location === "domestic" && item?.goGreen)
            : (item?.type === "dhl_new" && item?.location === "domestic" && !item?.goGreen)
        : UIProps.shipperName === "dhl_new_warenpost"
          ? serviceOptions?.premium
            ? serviceOptions?.goGreen
              ? (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && item?.goGreen && item?.premium)
              : (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && !item?.goGreen && item?.premium)
            : serviceOptions?.goGreen
              ? (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && item?.goGreen)
              : (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && !item?.goGreen)
          : serviceOptions?.goGreen
            ? (item?.type === "dhl_new" && item?.location === "abroad" && item?.goGreen) || (item?.type === "dhl_new" && item?.location === "eu" && item?.goGreen)
            : (item?.type === "dhl_new" && item?.location === "abroad" && !item?.goGreen) || (item?.type === "dhl_new" && item?.location === "eu" && !item?.goGreen)
    }).map(item => ({ label: `${item?.accountNumber} - ${intl({ id: item?.name_short })}`, value: `${dhlCustomerNumber}${item?.accountNumber}` })) || []
  }

  const saveShipping = (shippingType) => {

    if (UIProps.shipperName === "deutsche_post" && shippingGrouping.some(item => !item.dp_product_id)) {
      return toast.warning(<FormattedMessage id='DOCUMENT.SHIPPING.DEUTSCHE_POST.PRODUCT_SELECT' />);
    }

    const documents = [];

    for (const item of shippingGrouping) {

      const { order_id, order_number, dp_product_id, email, number, delivery_address, documentId, price, weight, position } = item;
      const shipping = { price, weight, type: shippingType === 'return_label' ? 'return' : "shipment" }

      if (UIProps.shipperName === "deutsche_post") {

        const findDeutschePost = deutschePost?.deutsche_post?.product_list.find(product => product.id === dp_product_id);
        shipping.dp_product_name = findDeutschePost?.name || '';
        shipping.dp_product_id = dp_product_id;

      } else if ((UIProps.shipperName === "dhl_new" || UIProps.shipperName === "dhl_new_warenpost") && item.billing_number) {

        shipping.billing_number = item.billing_number;
        shipping.service_options = {
          premium: serviceOptions.premium,
          endorsement: serviceOptions.endorsement
        };
      }

      documents.push({
        id: documentId,
        order_id,
        order_number,
        customer_details: { email, number, delivery_address },
        shipping,
        ...(['dhl', 'dhl_warenpost', 'dhl_new', 'dhl_new_warenpost'].includes(UIProps.shipperName) && {
          positions: position
        })
      })
    }

    if (UIProps.shipperName && documents.length) {
      dispatch(DocumentActions.createLabels(UIProps.shipperName, documents));
      UIProps.openPrintLabelsDialog(UIProps.shipperName, '', '', '');
      setProcessed(true);
      shippingType === 'return_label' ? setReturnLabelPrint(false) : setLabelPrint(false);
    };
    // onHide();
  };

  const openPrintLabelsDialog = (shippingType) => {

    const shipmentIds = shippingGrouping.flatMap(item => item.shipping?.filter(i => i.type === 'shipment').map(i => i.id) || []);
    const returnIds = shippingGrouping.flatMap(item => item.shipping?.filter(i => i.type === 'return').map(i => i.id) || []);

    const labelType = shippingType === 'return_label' ? 'return' : 'shipment';
    const getIds = shippingType === 'return_label' ? returnIds : shipmentIds;

    if (getIds.length) {
      dispatch(DocumentActions.getShipping(getIds));
      UIProps.openPrintLabelsDialog(UIProps.shipperName, '', '', labelType);
    }
  };

  const deutschePost = connections?.find(item => item.name === 'deutsche_post') || null;
  const DEUTSCHE_POST = deutschePost?.deutsche_post?.product_list?.map(item => ({ label: item.name, value: item.id })) || []

  const SERVICE_OPTIONS = [
    {
      name: "premium",
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.PREMIUM' }),
      info: intl({ id: UIProps.shipperName === 'dhl_new' ? 'DOCUMENT.SHIPPING.DHL_SERVICE.PREMIUM_INFO' : 'DOCUMENT.SHIPPING.DHL_SERVICE.WARENPOST_PREMIUM_INFO' }),
      show: UIProps.shipperName === 'dhl_new_warenpost'
    },
    {
      name: "goGreen",
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.GO_GREEN' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.GO_GREEN_INFO' }),
      show: true
    },
    {
      name: "endorsement",
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.ENDORSEMENT' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.ENDORSEMENT_INFO' }),
      show: (UIProps.shipperName === 'dhl_new' && shippingGrouping.some(item => item?.delivery_address?.country_code !== "DE"))
    }
  ]

  const COLUMNS = [
    {
      dataField: 'documentId',
      text: 'Id',
      hidden: true,
      headerStyle: { width: "15%" },
      editable: false,
    },
    {
      dataField: 'marketplace',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.MARKETPLACE' }),
      headerStyle: { width: "15%" },
      headerClasses: 'text-center pl-10',
      classes: 'font-weight-bold text-nowrap pl-10',
      editable: false,
      formatter: (cell, row) => {
        return (
          cell ?
            <div className='d-flex align-items-center'>
              <div className='svg-icon svg-icon-2x mr-3'>
                {getConnection(row.marketplace)?.icon ?? row.marketplace}
              </div>
              <div className='font-weight-bold'>
                <div className=' text-dark-75'>
                  {row.documentId}
                </div>
                <div className='text-dark-50'>
                  {row.order_number ? `#${row.order_number}` : row.order_id ? `#${row.order_id}` : ''}
                </div>
              </div>
            </div>
            : <>
              <span className='text-dark-50 svg-icon svg-icon-xl mr-3'>
                <Icon.ShopWindow />
              </span>
              <span className='font-weight-bold text-dark-75'>
                {row.documentId}
              </span>
            </>
        )
      },
    },
    {
      dataField: 'position',
      isDummyField: true,
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ARTICLE' }),
      headerStyle: { minWidth: '365px' },
      style: { fontSize: '11px' },
      editable: false,
      formatter: (cell, row, rowIndex) => <columnFormatters.ProductColumnFormatter cell={cell} row={row} rowIndex={rowIndex} />,
    },
    {
      dataField: 'delivery_address.country_code',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      headerStyle: { minWidth: '80px', width: "20%" },
      headerClasses: 'text-left',
      classes: 'text-left',
      editable: false,
      formatter: (cell, row) => {
        const address = row?.delivery_address
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{address.city}</span>
            <span className='text-dark-50'>{`${address?.country_code}-${address?.post_code}`}</span>
          </div>
        );
      },
    },
    {
      dataField: 'dp_product_id',
      text: "Product List",
      headerStyle: { width: "20%" },
      hidden: UIProps.shipperName !== "deutsche_post",
      headerClasses: 'text-center',
      classes: 'text-center',
      editor: { type: Type.SELECT, options: DEUTSCHE_POST },
      editorStyle: () => ({ height: "34.7px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => (
        <div type="button" className={`edit-table ${!cell && "form-control form-control-sm is-invalid"}`}>
          {DEUTSCHE_POST.find(item => `${item.value}` === `${cell}`)?.label}
        </div>
      ),
    },
    {
      dataField: 'billing_number',
      text: intl({ id: "DOCUMENT.SHIPPING.DHL.BILLING_DATA" }),
      headerStyle: { width: "25%" },
      hidden: !["dhl_new", "dhl_new_warenpost"].includes(UIProps.shipperName),
      headerClasses: 'text-center',
      classes: 'text-left',
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column, cell }) => {
          return getDHLAccountOptions(row?.delivery_address?.country_code);
        }
      },
      editorStyle: () => ({ height: "34.7px", padding: "0.5rem 0.75rem 0.5rem 1.75rem" }),
      formatter: (cell, row) => (
        <div type="button" className={`edit-table ${!cell && "form-control form-control-sm is-invalid"}`}>
          {getDHLAccountOptions(row?.delivery_address?.country_code).find(item => item.value === cell)?.label}
        </div>
      ),
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      headerStyle: { width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold font-size-lg',
      editorStyle: () => ({ height: "34.7px", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidWeight(cell, UIProps.shipperName, intl),
      formatter: (cell, row) => <div type="button" className="edit-table">{+cell}</div>,
    },
    {
      dataField: 'price',
      text: intl({ id: 'DOCUMENT.SHIPPING_PRICE2' }),
      headerClasses: 'text-center',
      classes: 'font-weight-bold text-center',
      headerStyle: { width: "15%", minWidth: '100px' },
      style: (cell, row) => ({ cursor: (row?.marketplace === "" && UIProps.shipperName !== "deutsche_post") ? 'pointer' : 'default' }),
      editable: (cell, row) => row.marketplace === "" && UIProps.shipperName !== "deutsche_post",
      editorStyle: () => ({ height: "34.7px", textAlign: "center" }),
      validator: (cell, row, column) => validator.isValidPrice(cell, intl),
      formatter: (cell, row) => {
        const isEditTable = row.marketplace === "" && UIProps.shipperName !== "deutsche_post";
        return (
          <div className={` ${isEditTable ? 'edit-table' : ''} `}>
            {convertPriceCurrency(+cell, row.currency)}
          </div>
        );
      },
    },
    {
      dataField: 'shipping',
      text: intl({ id: 'GENERAL.LABEL' }),
      hidden: !shippingGrouping.some(item => item?.shipping?.length),
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      headerStyle: { minWidth: '50px' },
      formatter: (cell, row) => (
        <div className="symbol-group symbol-hover justify-content-center">
          {cell.map((item, index) => {
            const service = LABEL_OPTIONS.find(service => service.value === item?.service)
            return (
              <OverlayTrigger key={index} overlay={<Tooltip id="shipping-tooltip">
                <div className='font-weight-bolder border-bottom'>
                  {service.label}
                </div>
                <div className={clsx('font-weight-bold mt-2', row?.error && 'text-danger')}>
                  {item?.error ? item?.error : item?.tracking_id}
                </div>
              </Tooltip>
              }>
                <div className="symbol d-flex bg-white" style={{ fontSize: "2.5rem" }}>
                  {item?.unlabeled ? <Icon.SupplierFIll /> : item?.error ? <Icon.ErrorFill /> : service.icon}
                </div>
              </OverlayTrigger>
            )
          })}
        </div>
      ),
    },
    {
      dataField: '#',
      text: "",
      editable: false,
      headerStyle: { minWidth: '50px' },
      hidden: processed,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className='hover-icon'>
            <OverlayTrigger placement="top" overlay={<Tooltip id={`fulfillment-delete-tooltip-${rowIndex}`}><FormattedMessage id="DOCUMENT.SHIPPING.DELIST" /></Tooltip>}>
              <span className="d-inline-block">
                <span className="btn btn-icon btn-sm" onClick={() => dispatch(DocumentActions.deleteShippingsList(row?.id))} id={`btn_delete_${row?.id}`}>
                  <Icon.Close className="text-danger" />
                </span>
              </span>
            </OverlayTrigger>
          </div>
        )
      },
    }
  ]

  const dhlExtras = ["dhl_new", "dhl_new_warenpost"].includes(UIProps.shipperName)

  const myShipper = connections?.filter((item) => item?.token_type === 'shipper') || [];

  const newOptions = myShipper?.map((item) => {
    const baseOption = LABEL_OPTIONS?.find((opt) => opt.value === item.name);
    if (item.name === 'dhl') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_warenpost");
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    if (item.name === 'dhl_new') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_new_warenpost" && dhlAccountOptions.some(item => item?.type === 'dhl_new_warenpost'));
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    return baseOption;
  }).flat();

  //Profile Name Options
  const PROFILE_NAME_OPTION = default_shipping?.filter(item => !item.unlabeled).map(item => {
    const baseOption = newOptions?.find((opt) => opt.value === item.service && opt.value === UIProps.shipperName);
    if (baseOption) {
      return {
        ...baseOption,
        value: item.profile_name,
        service: item.service,
        weight: item.weight,
        price: item.price,
      };
    }
    return null;
  }).filter(item => item !== null) || [];


  const defaultProfileName = default_shipping?.find(item => item.default)?.profile_name ?? "";
  const defaultOptions = PROFILE_NAME_OPTION?.find(item => item.value === defaultProfileName);

  useEffect(() => {
    // Eğer bir seçim yapılmamışsa ve manuel temizleme yapılmamışsa varsayılan değeri ayarla
    if (!profileName && defaultOptions?.value && !manualClear) {
      setProfileName(defaultOptions.value);
    }

  }, [defaultOptions, profileName, manualClear, dispatch]);

  const currentProfileName = PROFILE_NAME_OPTION?.find(i => i.value === profileName)
  const defaultWeight = default_shipping?.find(item => item.default)?.weight;
  const defaultPrice = default_shipping?.find(item => item.default)?.price;
  const defaultService = default_shipping?.find(item => item.default)?.service;


  return (
    <Modal show={UIProps.show} onHide={onHide} size='xl' backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" className={clsx(UIProps.showPrintLabel && "d-none")} centered>

      <Modal.Header>

        <CloseButton onClick={onHide} className="position-absolute z-index-10 top-0 right-0 mr-10 mt-10" />

        <Row className='justify-content-center w-100'>

          <Col md={dhlExtras ? '7' : '11'} className={`d-flex justify-content-${dhlExtras ? "end" : "center"} icon-4x my-10`}>
            {getConnection(UIProps.shipperName)?.iconLong}
            <UserInfoDropdown description={<FormattedMessage id='DOCUMENT.SHIPPING.GROUPING_PRINT_INFO' />} />
          </Col>


          <Col md={dhlExtras ? '5' : '1'} className='d-flex flex-column justify-content-center align-items-end pr-10 pt-3'>
            <Dropdown className="dropdown-inline" drop="down">

              <Dropdown.Toggle variant="outline-secondary" className="d-flex justify-content-between align-items-center text-left font-weight-bold" style={{ height: theme.units.input.height['md'] }} id="dropdown-service">
                {!defaultProfileName || !currentProfileName
                  ? <span className='text-muted'>
                    <FormattedMessage id="DOCUMENT.SHIPPING_PROFILE_NAME.PLACEHOLDER" />
                  </span>
                  : <span className='d-flex align-items-center pr-5'>
                    <span className="svg-icon svg-icon-xl mr-2">{currentProfileName?.icon}</span>
                    <span className="navi-text text-nowrap">{currentProfileName?.value}</span>
                  </span>
                }
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-md" id="dropdown_service_options">
                <ul className="navi navi-hover">
                  {PROFILE_NAME_OPTION.length
                    ? <>
                      {PROFILE_NAME_OPTION?.map((opt, index) => (
                        <li className="navi-item" key={index}>
                          <Dropdown.Item className="navi-link py-2"
                            onClick={() => { setProfileName(opt.value); dispatch(DocumentActions.updateShippingGrouping(undefined, { value: opt.value, weight: opt.weight, price: opt.price })) }}
                            id="dropdown_item_profile_name">
                            <span className='d-flex flex-column align-items-start'>
                              <span className='d-flex align-items-center'>
                                <span className="svg-icon svg-icon-xl mr-1">{opt.icon}</span>
                                <span className="navi-text text-nowrap font-weight-bold">{opt.value}</span>
                              </span>
                              <span className="text-muted small mt-1">
                                <FormattedMessage id='GENERAL.WEIGHT' />  : {opt.weight} - <FormattedMessage id='GENERAL.PRICE' /> : {convertPriceCurrency(opt.price, 'EUR')}
                              </span>
                            </span>
                          </Dropdown.Item>
                        </li>
                      ))}
                      <Dropdown.Divider />
                      <li className="navi-item">
                        <Dropdown.Item
                          className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                          onClick={() => {
                            setProfileName('');
                            setManualClear(true);
                            dispatch(DocumentActions.updateShippingGrouping(undefined, {
                              value: ((UIProps.shipperName === defaultService) && defaultProfileName ? defaultProfileName : ''),
                              weight: ((UIProps.shipperName === defaultService) && defaultWeight ? defaultWeight : UIProps.shipperName === 'dhl_warenpost' || UIProps.shipperName === 'dhl_new_warenpost' ? 1 : 2 ?? ''),
                              price: ((UIProps.shipperName === defaultService) && defaultPrice ? defaultPrice : '')
                            }))
                          }}
                          id='clear-date'
                        >
                          <FormattedMessage id='GENERAL.CLEAR' />
                        </Dropdown.Item>
                      </li>
                    </>
                    : <li className="navi-item px-3">
                      <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                        <Icon.InfoCircle />
                      </span>
                      <span className="navi-text">
                        <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_PROFILE_NAME" values={{ url: ModuleRoutes.SETTINGS_SHIPPING, a: chunks => <a href='/' id="btn_add_profile_name">{chunks}</a> }} />
                      </span>
                    </li>
                  }
                </ul>
              </Dropdown.Menu>
            </Dropdown>

            {UIProps.shipperName === 'deutsche_post' &&
              <div className='mt-2'>
                {RemainingBalance(deutschePost?.deutsche_post?.wallet_balance)}
              </div>
            }

            {/*<label className='font-weight-bold text-capitalize text-dark-75'>
                <FormattedMessage id="DOCUMENT.SHIPPING.DHL_ADDITIONAL_SERVICE" />
              </label>*/}

            {dhlExtras &&
              SERVICE_OPTIONS?.map((item, i) => (item.show &&
                <label className="switch switch-sm switch-icon font-weight-bold" key={i}>
                  <input
                    type="checkbox"
                    name={item.name}
                    id={item.name}
                    checked={serviceOptions[item.name]}
                    onChange={(e) => handleCheckbox(e)}
                  // disabled={loading}
                  />

                  {item.label}

                  <OverlayTrigger overlay={<Tooltip id="document-print-tooltip">{item.info}</Tooltip>}>
                    <div type="button" className="d-flex svg-icon svg-icon-sm text-info mx-2" id="service_info">
                      <Icon.InfoCircle />
                    </div>
                  </OverlayTrigger>

                  <span />

                </label>
              ))

            }
          </Col>

        </Row>

      </Modal.Header>

      <Modal.Body className='p-0' style={{ minHeight: "400px" }}>

        <PerfectScrollbar className="scroll" style={{ maxHeight: '500px' }}>
          <BootstrapTable
            keyField="id"
            data={shippingGrouping || []}
            columns={COLUMNS}
            wrapperClasses="table-responsive pb-5"
            classes="table table-head-custom table-vertical-center"
            headerClasses="bg-info-o-10"
            bootstrap4
            bordered={false}
            deleteRow={true}
            condensed
            cellEdit={cellEditFactory({
              mode: windowSize < 576 ? 'click' : 'dbclick',
              blurToSave: true,
              afterSaveCell: tableRowHandler,
            })}
          />
        </PerfectScrollbar>

        {/* DPD */}
        {(UIProps.shipperName === 'dpd' && UIDates.isWeekend()) && <>
          <div className="separator separator-dashed" />
          <Alert variant='light-info' className="alert-custom alert-notice m-8">
            <div className="text-dark-75 line-height-xl">
              <FormattedMessage id='DOCUMENT.SHIPPING.DPD.SHIPMENT_DATE.NEXT_BUSINESS' />
            </div>
          </Alert>
        </>}

      </Modal.Body>

      <Modal.Footer>

        <SelectCounter count={shippingGrouping?.length} />

        <Button variant='light' className='ml-auto' onClick={onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        {['dhl', 'dhl_new', 'dpd', 'dhl_warenpost', 'dhl_new_warenpost'].includes(UIProps.shipperName) ?
          <Button variant='light-primary' className="ml-2" onClick={() => returnLabelPrint ? saveShipping('return_label') : openPrintLabelsDialog('return_label')} id="btn_create_return_label">
            <FormattedMessage id={returnLabelPrint ? "DOCUMENT.SHIPPING.CREATE_RETURN_LABEL" : "DOCUMENT.SHIPPING.PRINT_RETURN_LABEL"} />
          </Button>
          : null
        }

        <Button
          variant='primary' className="ml-2" onClick={() => labelPrint ? (saveShipping(), setLabelPrint(false)) : openPrintLabelsDialog()} id="btn_save_modal">
          <FormattedMessage id={labelPrint ? "DOCUMENT.SHIPPING.CREATE_LABEL" : "DOCUMENT.SHIPPING.PRINT_LABEL"} />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
