import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useSupplierUIContext } from "../../../_context/SupplierUIContext";
import { AddressEditForm } from "./AddressEditForm";
import { FormattedMessage } from "react-intl";



export function AddressEditDialog() {

  // Address UI Context
  const UIContext = useSupplierUIContext();
  const UIProps = useMemo(() => ({
    id: UIContext.selectedId,
    show: UIContext.showEditAddressDialog,
    onHide: UIContext.closeEditAddressDialog,
  }), [UIContext]);


  return (
    <Modal size="lg" show={UIProps.show} onHide={UIProps.onHide} aria-labelledby="example-modal-sizes-title-lg" backdrop="static" keyboard={false} centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={UIProps.id ? "ADDRESS.TITLE" : "ADDRESS.TITLE_NEW"} />
        </Modal.Title>
      </Modal.Header>

      <AddressEditForm />
    </Modal>
  );
}
