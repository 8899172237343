import React from 'react'
import { FormattedMessage } from 'react-intl';
import { UIDates } from '_metronic/_helpers'



export default function CreationAndUpdateInfo({ data }) {
  return (
    data?.created_at ?
      <div className="d-flex text-dark-25 font-italic mt-3 mx-3">
        {(data?.marketplace || (Array.isArray(data?.source?.marketplace) ? data?.source?.marketplace.length > 0 : data?.source?.marketplace)) &&
          <span className='mr-2'>
            Created by <span className='text-capitalize ml-1'>{data?.marketplace || ((Array.isArray(data?.source?.marketplace) && data?.source?.marketplace.length > 0) ? data?.source?.marketplace.join(', ') : data?.source?.marketplace)}</span>
          </span>}
        <span className='ml-auto mr-2'>
          <FormattedMessage id='GENERAL.REGISTERED_ON' /> {UIDates.formatDateTime(data?.created_at)}.
        </span>
        {data?.created_at !== data?.updated_at &&
          <span>
            <FormattedMessage id='GENERAL.UPDATED_ON' /> {UIDates.formatDateTime(data?.updated_at)}.
          </span>
        }
      </div>
      : <></>
  )
}
