import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { HeaderMobile } from "../header-mobile/HeaderMobile";
import { ScrollTop } from "../extras/ScrollTop";
import { LayoutInit } from "./LayoutInit";
import { Header } from "../header/Header";
import { Aside } from "../aside/Aside";
import { Footer } from "../footer/Footer";



//container sınırlandırılması gereken sayfalar
const narrowPage = [
  "/new",
  "/edit",
  "/convert",
  "/copy",
  "/dashboard",
  "/export",
  "/import",
  "/connections",
  "/company",
  "/category",
  "/settings",
  "/icons",
  "/support",
  "/order-list",
  "/invoices-to-ebay",
]

// const fluidPage = [
//   "/import/product-preparation",
// ]


const isNarrowPage = (pathname) => {
  return narrowPage.some((page) => pathname.includes(page))
}


export function Layout({ children }) {

  // Layout settings (cssClasses/cssAttributes)
  const UIService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    layoutConfig: UIService.config,
    selfLayout: objectPath.get(UIService.config, "self.layout"),
    asideDisplay: objectPath.get(UIService.config, "aside.self.display"),
    contentCssClasses: UIService.getClasses("content", true),
    contentContainerClasses: UIService.getClasses("content_container", true),
    contentExtended: objectPath.get(UIService.config, "content.extended"),
  }), [UIService]);

  const { pathname } = useLocation();


  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}

          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            {/*begin::Content*/}
            <div id="kt_content" className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}>

              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={isNarrowPage(pathname) ? layoutProps.contentContainerClasses : "container-fluid"}>
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}
              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}

            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}

        </div>
        {/*end::Page*/}
      </div>
      <ScrollTop />
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
