import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { getConnection } from 'constants/connectionSettings';
import { LastLoginFn } from '../../user-table/LastLoginFn';
import { CardItems } from '_metronic/_partials/components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { flags, UIDates } from "_metronic/_helpers";
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';
//import { Button } from 'react-bootstrap';
//import { API } from 'constants/apiUrl';
//import { useSelector } from 'react-redux';
//import { toast } from 'react-toastify';



export default function Info({ userBaseData, connections }) {

  const { formatMessage: intl } = useIntl();

  const connection = ['marketplace', 'shop', 'shipper'].map((type, i) => {
    const filteredItems = connections?.filter(item => item.type === type);
    if (!filteredItems || filteredItems.length === 0) return null;
    return (
      <div className={clsx('d-flex symbol-group symbol-hover mb-3', i !== 2 && "border-right mr-3")} key={type}>
        {filteredItems.map((item, i) => (
          <OverlayTrigger key={item.id} overlay={<Tooltip id="connection-tooltip" className="font-weight-bold">{item.market_name ? item.market_name : item.website ? item.website : getConnection(item.name)?.label}</Tooltip>}>
            <span className="symbol d-flex bg-white svg-icon svg-icon-xl mr-3">
              {getConnection(item.name)?.icon}
            </span>
          </OverlayTrigger>
        ))}
      </div>
    );
  });

  const currentLanguage = flags.find((x) => x.lang === userBaseData?.language);
  const { newsletter, fail_log, success_login, license } = userBaseData;
  // const accessToken = useSelector(state => state.auth.access_token);

  /* const handleShippingMigration = async () => {
      try {
        const data = await API.get(`/migrations-shipping/${userBaseData.id}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
  
        if (data.status === 204) {
          toast.success('Shipping migration initiated successfully');
        }
  
      } catch (error) {
        toast.error('An error occurred while initiating shipping migration');
        console.error('An error occurred while initiating shipping migration:', error);
      }
    }; */

  const USER_INFO = [
    {
      label: "ADMIN.LANGUAGE",
      content: <>
        <span className="svg-icon svg-icon-md mr-2">{currentLanguage?.flag}</span>
        <span>{currentLanguage?.name}</span>
      </>,
    },
    {
      label: "Newsletter",
      content: <label className={`label label-lg label-inline label-rounded font-weight-bold label-outline-${userBaseData?.newsletter ? 'success' : 'danger'}`}>
        {intl({ id: newsletter ? 'ADMIN.TEMP_USER.SUBSCRIBED' : 'ADMIN.TEMP_USER.NOT_SUBSCRIBED' })}
      </label>
    },
    success_login?.time && {
      label: "ADMIN.USER.LAST_LOGIN",
      content: LastLoginFn(success_login?.time),
    },
    success_login?.count !== 0 && {
      label: "AUTH.GENERAL.LOGIN",
      content: <>
        <span className='text-success mr-1'>{success_login?.count}</span>
        {fail_log?.login?.count > 0 && <span className='text-danger'> / {fail_log?.login?.count}</span>}
      </>
    },
    {
      label: "GENERAL.REGISTRATION_DATE",
      content: UIDates.formatDateTime(license?.updated_at),
    },
    {
      label: "MENU.ASIDE.CONNECTIONS",
      icon: <Icon.Connection />,
      content: connections?.length !== 0 ? <div className='d-flex flex-wrap'>{connection}</div> : <FormattedMessage id="ADMIN.USER.NO_CONNECTION" />
    },
    /* ,{
      label: "GENERAL.SYNC",
      content: <Button variant="danger" onClick={handleShippingMigration}>WithoutTrackingId Shipping Migrations</Button>
    }, */
  ].filter(Boolean);


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.PersonLines />} title={<FormattedMessage id="ADMIN.USER.INFO" />} />

      <CardBody>
        <CardItems Content={USER_INFO} />
      </CardBody>

    </Card>
  );
}
