import React, { useMemo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { useDocumentLocalUIContext } from './_context/DocumentLocalUIContext';
import { DocumentsGrouping } from './document-filter/DocumentsGrouping';
import { useDocumentsUIContext } from './_context/DocumentsUIContext';
import { DocumentsFilter } from './document-filter/DocumentsFilter';
import { UserGuideHelper } from '../../user-guides/UserGuideHelper';
import { DocumentsTable } from './document-table/DocumentsTable';
import { BlankMessage } from '_metronic/_partials/components';
import { LoadingDialog } from "_metronic/_partials/loading";
import { ModuleRoutes } from 'constants/moduleRoutes';
import { DocumentActions } from './_redux/actions';
import { NewButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';



export function DocumentsCard() {

  const dispatch = useDispatch();

  const { loading, documents, countDocuments, totalDocuments, marketplaces, entities, openOrders, ordersCount } = useSelector(state => ({
    loading: state.documents.isLoading,
    documents: state.documents.documents,
    countDocuments: state.documents.countDocuments,
    totalDocuments: state.documents.totalDocuments,
    marketplaces: state.documents.marketplaces,
    entities: state.connections.entities,
    openOrders: state.documents.openOrders,
    ordersCount: state.notification.orders_count,
  }),
    shallowEqual
  );

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    requestedDocument: UIContext.requestedDocument,
    newDocumentButtonClick: UIContext.newDocumentButtonClick,
  }),
    [UIContext.requestedDocument, UIContext.newDocumentButtonClick]
  );

  const contextLocal = useDocumentLocalUIContext();
  const { queryParams, setPaginationQuery, setOrderQuery, setSearchQuery, setFilterQuery } = useMemo(() => ({
    queryParams: contextLocal.queryParams,
    setPaginationQuery: contextLocal.setPaginationQuery,
    setOrderQuery: contextLocal.setOrderQuery,
    setSearchQuery: contextLocal.setSearchQuery,
    setFilterQuery: contextLocal.setFilterQuery,
  }), [contextLocal]);

  useEffect(() => {
    dispatch(DocumentActions.getUserTokens());
  }, [dispatch]);

  useEffect(() => {
    let delayDebounceFn;
    if (queryParams?.search) {
      delayDebounceFn = setTimeout(() => {
        dispatch(DocumentActions.getDocuments(queryParams));
      }, 350);
    } else {
      //FIXME: documents özel çift istek atmaması için bakılacak 
      dispatch(DocumentActions.getDocuments(queryParams));
    }
    return () => { clearTimeout(delayDebounceFn); };
  }, [dispatch, queryParams, queryParams.search]);

  // cleaning reducer on unmount
  useEffect(() => {
    return () => {
      dispatch(DocumentActions.cleanUpDocumentStore());
    };
  }, [dispatch]);


  // Sipariş sayısı değiştiğinde siparişleri yeniden yükle
  useEffect(() => {
    if (UIProps.requestedDocument.type === 'orders' && ordersCount > openOrders) {
      dispatch(DocumentActions.getDocuments(queryParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersCount]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={UIProps.requestedDocument.icon}
        title={UIProps.requestedDocument.plural}
        info={<UserGuideHelper />}
        className="display-5"
        id="title_document"
      >

        <CardHeaderToolbar>
          <NewButton
            label={UIProps.requestedDocument.type === 'offers' ? 'DOCUMENT.NEUES' : 'DOCUMENT.NEW'}
            values={UIProps.requestedDocument.title}
            link={ModuleRoutes.DOCUMENT_NEW_FN(UIProps.requestedDocument.type)}
          />
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>
        {totalDocuments === 0
          ? <BlankMessage
            icon={UIProps.requestedDocument.icon}
            title={<FormattedMessage id='DOCUMENT.BLANK_MESSAGE_TITLE' values={{ value: UIProps.requestedDocument.plural }} />}
            message={<FormattedMessage id='DOCUMENT.BLANK_MESSAGE' values={{ value: UIProps.requestedDocument.title }} />}
            className='min-50-height'
            loading={loading}
            arrow={!['waybills'].includes(UIProps.requestedDocument.type)}
          />
          : <>
            <DocumentsGrouping />
            <DocumentsFilter
              requestedDocument={UIProps.requestedDocument}
              queryParams={queryParams}
              setSearchQuery={setSearchQuery}
              setFilterQuery={setFilterQuery}
              connectionEntities={entities}
              marketFilterContent={marketplaces}
              UIContext={UIContext}
            />
            <DocumentsTable
              documents={documents}
              countDocuments={countDocuments}
              queryParams={queryParams}
              setOrderQuery={setOrderQuery}
              setPaginationQuery={setPaginationQuery}
              setFilterQuery={setFilterQuery}
              connectionEntities={entities}
              loading={loading}
            />
          </>
        }
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}