import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getConnection, getOrderLink } from 'constants/connectionSettings';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function TitleColumnFormatter(cell, row, rowIndex, { documentType, documentTitle, UIHelpers, UIProps }) {

  const orderStatus = UIHelpers.ORDER_STATUS.find(item => item.value === row.order_status)
  const deliveryStatus = row.shipping?.length > 0 ? row.shipping?.every(item => item.error?.length > 0) ? 'error' : 'shipped' : 'not_shipped';
  const paymentStatus = UIHelpers.getPaymentStatus(row.payment_status);


  return (
    <div className='d-flex align-items-center'>

      <OverlayTrigger overlay={<Tooltip id="shop-tooltip" className='d-flex font-weight-bold'>

        <div className={clsx('text-dark-75', UIProps.requestedDocument.type === 'orders' && 'font-size-lg')}>
          <FormattedMessage id='DOCUMENT.DETAIL_DIALOG.TITLE' values={{ title: documentTitle }} />
        </div>

        {UIProps.requestedDocument.type === 'orders' && <div className='text-left border-top pt-3 mt-1'>

          <div className='d-flex align-items-center justify-content-between mb-2'>
            <div className='text-dark-75 mr-8'>
              <FormattedMessage id="DOCUMENT.TABLE.ORDER_STATUS" />
            </div>
            <div className={clsx("label label-inline label-md font-weight-bold", `label-light-${orderStatus?.className}`)}>
              {orderStatus?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-between mb-2'>
            <div className='text-dark-75 mr-8'>
              <FormattedMessage id='DOCUMENT.TABLE.PAYMENT_STATUS' />
            </div>
            <div className={`label label-inline label-md font-weight-bold label-light-${paymentStatus?.className}`}>
              {paymentStatus?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-between'>
            <div className='text-dark-75 svg-icon svg-icon-sm mr-8'>
              <FormattedMessage id='DOCUMENT.TABLE.DELIVERY_STATUS' />
              {row.shipping?.some(item => item.error?.length > 0) && <Icon.ExclamationCircle className='text-danger ml-1' />}
            </div>
            <div className={`label label-inline label-md font-weight-bold label-light-${deliveryStatus === "shipped" ? 'success' : 'danger'}`}>
              <FormattedMessage id={deliveryStatus === "shipped" ? 'GENERAL.DELIVERED' : 'GENERAL.OPEN'} />
            </div>
          </div>

        </div>}

      </Tooltip>}>
        <div type="button" className={clsx("text-decoration-none svg-icon mr-3", row.marketplace ? "svg-icon-2x" : "text-dark-50 text-hover-primary svg-icon-xl")} onClick={() => UIProps.openDocumentDetailsDialog(row.id)}>
          {row.marketplace ? getConnection(row.marketplace)?.icon ?? <Icon.Shop /> : <Icon.ShopWindow />}
        </div>
      </OverlayTrigger>

      <div>

        <OverlayTrigger overlay={<Tooltip id="order-tooltip" className='font-weight-bold'>
          {documentType === 'orders' ? orderStatus?.status ?? <FormattedMessage id="GENERAL.OTHER" /> : <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.EDIT' values={{ documentTitle: documentTitle }} />}
        </Tooltip>}>
          <span type="button" className="d-flex align-items-center text-decoration-none text-hover-primary text-dark-75 preview" onClick={() => UIProps.openEditDocumentDialog(row.id)}>
            {row.id}
            {documentType === 'orders' && <span className={clsx("label label-xl label-dot ml-2", `label-${orderStatus?.className}`)} />}
            <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2 pb-1'>
              <Icon.PencilSquare />
            </span>
          </span>
        </OverlayTrigger>

        {(row?.order_id || row?.order_number) &&
          <OverlayTrigger overlay={<Tooltip id="market-tooltip" className='d-flex font-weight-bold'><FormattedMessage id="DOCUMENT.TABLE.ORDER_PAGE" /></Tooltip>}>
            <a href={getOrderLink(row.marketplace, row.order_id, row.marketplace_url)} onClick={() => navigator.clipboard.writeText(row.order_number ? `${row.order_number}` : row.order_id ? `${row.order_id}` : '')} className='text-decoration-none text-hover-primary text-dark-50 font-size-sm preview' target="_blank" rel="noopener noreferrer">
              {row.order_number ? `#${row.order_number}` : row.order_id ? `#${row.order_id}` : ''}
              <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                <Icon.Link />
              </span>
            </a>
          </OverlayTrigger>}

      </div>

    </div>
  );
}