import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LABEL_OPTIONS } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';
import clsx from 'clsx';



export function ShippingDataColumnFormatter(cell, row, rowIndex) {

  if (cell && isArray(cell) && cell.length > 0) {

    let sliceCell = cell
    const iconLimit = 2

    if (cell.length > iconLimit) {
      sliceCell = cell.sort((a, b) => a?.id ? -1 : 1).slice(0, iconLimit)
    }

    return (
      <div className="symbol-group symbol-hover justify-content-center">

        {sliceCell.map((item, index) => {

          const service = LABEL_OPTIONS.find(service => service.value === item?.service)

          return (
            <OverlayTrigger key={index} overlay={
              <Tooltip id="shipping-tooltip">
                <div className='d-flex align-items-start' key={index}>
                  <div className='d-flex mt-1 mr-3' style={{ fontSize: "2.5rem" }}>
                    {item?.unlabeled ? <Icon.SupplierFIll /> : service.icon}
                  </div>
                  <div className='d-flex flex-column text-left'>
                    <span className='font-weight-bolder'>
                      {service.label}
                    </span>
                    <span className={clsx('font-weight-bold font-size-sm', item?.error ? 'text-danger' : 'text-dark-50')}>
                      {item?.error ? item?.error : item?.tracking_id}
                    </span>
                  </div>
                </div>
              </Tooltip>
            }>

              <div className="symbol d-flex bg-white" style={{ fontSize: "2.5rem" }}>
                {item?.unlabeled ? <Icon.SupplierFIll /> : item?.error ? <Icon.ErrorFill /> : service.icon}
              </div>

            </OverlayTrigger>
          )
        })}

        {cell.length > iconLimit &&
          <OverlayTrigger overlay={<Tooltip id="type-tooltip">
            {cell.slice(iconLimit, cell.length).map((item, index) => {
              const service = LABEL_OPTIONS.find(service => service.value === item?.service)
              return (
                <div key={index}>
                  <div className='d-flex align-items-start'>
                    <div className='d-flex mt-1 mr-3' style={{ fontSize: "2.5rem" }}>
                      {item?.unlabeled ? <Icon.SupplierFIll /> : service.icon}
                    </div>
                    <div className='d-flex flex-column text-left'>
                      <span className='font-weight-bolder'>
                        {service.label}
                      </span>
                      <span className={clsx('font-weight-bold font-size-sm', item?.error ? 'text-danger' : 'text-dark-50')}>
                        {item?.error ? item?.error : item?.tracking_id}
                      </span>
                    </div>
                  </div>
                  {(index > cell.length - 4) ? null : <div className='separator separator-solid my-2' />}
                </div>
              )
            })}
          </Tooltip>}>
            <div className="symbol symbol-light bg-white">
              <span className="symbol-label font-weight-bolder text-dark-50" style={{ width: "2.5rem", height: "2.5rem" }}>
                +{cell.length - iconLimit}
              </span>
            </div>
          </OverlayTrigger>}

      </div>
    )
  }
}