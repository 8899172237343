import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { DHL_ACCOUNT_OPTIONS } from "../../../_context/SettingsUIHelpers";
import { ConnectionsActions } from "../../../_redux/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteButton } from "_metronic/_partials";
import { UIDates } from "_metronic/_helpers";
import { Icon } from '_metronic/_icons';



export function DhlNewList() {

  const { formatMessage: intl } = useIntl()
  const dispatch = useDispatch();

  const [forceRerender, setForceRerender] = useState(false);

  const dhlNewForEdit = useSelector((state) => (state.connections.dhlNewForEdit));
  const customerNumber = dhlNewForEdit?.dhl?.customerNumber;
  const dhlAccountOptions = dhlNewForEdit?.dhl?.subscriptions?.map(item => {
    // Müşteri numarasını eklemek için accountNumber'ı yeniden oluştur
    const newAccountNumber = customerNumber + item.accountNumber;

    // Eğer accountNumber baseOption içinde bulunamazsa item.accountNumber'ı kullan
    const baseOption = DHL_ACCOUNT_OPTIONS?.find((opt) => opt.accountNumber === item.accountNumber);

    // Eğer baseOption başarılı bir şekilde bulunursa, yeni accountNumber özelliği eklenir
    if (baseOption) {
      // baseOption ile item'ın tüm özelliklerini birleştir
      return { ...item, ...baseOption, accountNumber: newAccountNumber };
    } else {
      // baseOption bulunamazsa sadece item'ın özelliklerini kullan
      return { ...item, accountNumber: newAccountNumber };
    }
  });


  const COLUMNS = [
    {
      dataField: "name",
      text: intl({ id: "GENERAL.NAME" }),
      headerClasses: "pl-9",
      classes: "font-weight-bold pl-9",
      formatter: (cell, row) => cell && intl({ id: cell }),
    },
    {
      dataField: "accountNumber",
      text: intl({ id: "DOCUMENT.SHIPPING.DHL.BILLING_DATA" }),
      headerStyle: { minWidth: '100px' },
      headerClasses: "text-center",
      classes: "font-weight-bold text-dark-50 text-center",
      formatter: (cell, row) => cell,
    },
    {
      dataField: "product",
      text: intl({ id: "CONNECTIONS.PRODUCT" }),
      headerClasses: "text-center",
      classes: "font-weight-bold text-center",
      formatter: (cell, row) => cell,
    },
    {
      dataField: "error",
      text: intl({ id: "CONNECTIONS.CHECK" }),
      headerClasses: "text-center",
      classes: "font-weight-bold text-center",
      formatter: (cell, row) =>
        <OverlayTrigger overlay={
          <Tooltip id="ebay-listing-edit-tooltip">
            <div className='d-flex flex-column font-weight-bold'>
              <span>{row?.error ? row?.error : <FormattedMessage id='CONNECTIONS.CHECK_SUCCESS' />}</span>
              <span className="text-dark-50">{UIDates.formatDateTime(row?.created_at)}</span>
            </div>
          </Tooltip>
        }>
          <span className="svg-icon svg-icon-md">
            {row?.error
              ? <Icon.ExclamationCircleFill className="text-danger" />
              : <Icon.CheckCircleFill className="text-success" />
            }
          </span>
        </OverlayTrigger>
    },
    {
      dataField: "action",
      text: "...",
      style: { width: "100px" },
      headerClasses: "font-size-h3 text-center pt-0 pr-4",
      classes: "text-center pr-4",
      formatter: (cell, row) => <DeleteButton onClick={() => deleteSubscriptions(row.id)} disabled={dhlNewForEdit?.dhl?.subscriptions?.length === 1} />,
    },
  ];


  const deleteSubscriptions = (subscriptionId) => {
    const updatedSubscriptions = {
      ...dhlNewForEdit,
      dhl: {
        ...dhlNewForEdit.dhl,
        subscriptions: dhlNewForEdit.dhl.subscriptions.filter((sub) => sub.id !== subscriptionId),
      }
    };
    dispatch(ConnectionsActions.updateSettings(updatedSubscriptions));
  }


  useEffect(() => {
    setForceRerender(prev => !prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dhlNewForEdit?.dhl?.subscriptions?.length]);


  const handleSubmit = (values) => {

    const data = {
      ...dhlNewForEdit,
      dhl: {
        ...dhlNewForEdit?.dhl,
        subscriptions: [
          ...(dhlNewForEdit?.dhl?.subscriptions || []),
          values
        ],
      },
    };

    dispatch(ConnectionsActions.updateSettings(data));
  }

  const subscribedIds = dhlNewForEdit?.dhl?.subscriptions?.map(({ id }) => id) || [];
  const options = DHL_ACCOUNT_OPTIONS.filter(({ id }) => !subscribedIds.includes(id))


  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.List />} title={<FormattedMessage id="CONNECTIONS.SHIPPER.DHL.SUBSCRIPTION" />}>
        <Dropdown className="dropdown-inline" drop="down" alignRight>

          <Dropdown.Toggle variant='outline-primary' size="sm" className="svg-icon svg-icon-sm" id="dropdown-subscription-add">
            <Icon.Plus />
            <span className="d-none d-sm-inline font-weight-bold ml-2">
              <FormattedMessage id="CONNECTIONS.SHIPPER.DHL.SUBSCRIPTION_ADD" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              {options?.map((opt, index) => (
                <li className="navi-item" key={index}>
                  <Dropdown.Item className="navi-link preview py-2" onClick={() => handleSubmit(opt)} id={`dropdown_item_${opt.name}`}>
                    <span className="icon-2x mr-1">
                      <Icon.Box />
                    </span>
                    <div>
                      <div className="navi-text font-weight-bold">
                        <FormattedMessage id={opt.name} />
                      </div>
                      <div className="text-dark-50 font-weight-bold font-size-sm">
                        {customerNumber + opt.accountNumber + " - " + opt.product}
                      </div>
                    </div>
                    <span className="svg-icon svg-icon-sm invisible ml-auto pl-2">
                      <Icon.Plus />
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </CardSubHeader>

      <CardBody className="pt-0 px-0">
        <BootstrapTable
          keyField="id"
          key={forceRerender}
          data={dhlAccountOptions || []}
          columns={COLUMNS}
          wrapperClasses="table-responsive"
          headerClasses="bg-info-o-10"
          classes="table table-head-custom table-vertical-center"
          bootstrap4
          bordered={false}
          condensed
        />
      </CardBody>

    </Card>
  );
}