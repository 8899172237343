import { call, put, takeEvery, select, fork } from 'redux-saga/effects';
import { adminMiddleware, tokenErrorHandler } from 'middlewares';
import { AdminActions } from './actions';
import ActionTypes from './actionTypes';



// --------------------------- Users ---------------------------

// Get Users
function* getUsersSaga({ payload: { dateRange, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getUsers = yield call(adminMiddleware.getUsers, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminMiddleware.getUsersCount, accessToken, '', queryParams);

    yield put(AdminActions.getUsersSuccess(getUsers.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUsersFail(JSON.stringify(err.response)));
  }
}


// Get User
function* getUser({ payload: { id } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getUser = yield call(adminMiddleware.getUser, accessToken, id);

    yield put(AdminActions.getUserSuccess(getUser.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserFail(JSON.stringify(err.response)));
  }
}


// Update User
function* updateUser({ payload: { id, user } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.patchUser, accessToken, id, user);
    yield put(AdminActions.updateUserSuccess(id, user));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.updateUserFail(err.response));
  }
}


// Delete User
function* deleteUser({ payload: { id, user, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(adminMiddleware.deleteUser, accessToken, id, user);

    yield put(AdminActions.deleteUserSuccess(response.data));

    yield fork(getUsersSaga, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.deleteUserFail(err.response.data.error));
  }
}


// --------------------------- Temp Users ---------------------------

// Get Temp Users
function* getTempUsersSaga({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countTempUsers = yield call(adminMiddleware.getTempUsersCount, accessToken, queryParams);
    const getTempUsers = yield call(adminMiddleware.getTempUsers, accessToken, queryParams);

    yield put(AdminActions.getTempUsersSuccess(getTempUsers.data, countTempUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getTempUsersFail(JSON.stringify(err.response)));
  }
}


// Update Temp user
function* updateTempUser({ payload: { tempUser } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.patchTempUser, accessToken, tempUser);
    yield put(AdminActions.updateTempUserSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.updateTempUserFail(err.response));
  }
}


// Delete Temp User
function* deleteTempUser({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.deleteTempUser, accessToken, ids);
    yield put(AdminActions.deleteTempUserSuccess(ids));

    yield fork(getTempUsersSaga, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.deleteTempUserFail(err.response.data.error));
  }
}


// Get Users Reports
function* getReportsUsers({ payload: { dateRange, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getReports = yield call(adminMiddleware.getReportsUsers, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminMiddleware.getUsersCount, accessToken, dateRange, queryParams);

    yield put(AdminActions.getReportsUsersSuccess(getReports.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getReportsUsersFail(JSON.stringify(err.response)));
  }
}


// Get User Details
function* getUserCompany({ payload: { id, filter } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const userDetail = yield call(adminMiddleware.getUserCompany, accessToken, id, filter);

    yield put(AdminActions.getUserCompanySuccess(userDetail.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserCompanyFail(err.response));
  }
}


function* getOrderReport({ payload: { id, filter } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const orderReport = yield call(adminMiddleware.getOrderReport, accessToken, id, filter);

    yield put(AdminActions.getOrderReportSuccess(orderReport.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getOrderReportFail(err.response));
  }
}


function* getUserLogEntries({ payload: { id, order, search, filter, page, pageSize } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    
    const logEntries = yield call(adminMiddleware.getUserLogEntries, accessToken, id, order, search, filter, page, pageSize);

    yield put(AdminActions.getUserLogEntriesSuccess(logEntries.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserLogEntriesFail(err.response));
  }
}



// Get Users Contact
function* getUsersContact({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const getUsers = yield call(adminMiddleware.getUsersContact, accessToken, queryParams);
    const countUsers = yield call(adminMiddleware.getUsersCount, accessToken, '', queryParams);

    yield put(AdminActions.getUsersContactSuccess(getUsers.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUsersContactFail(JSON.stringify(err.response)));
  }
}



export function* adminSaga() {
  yield takeEvery(ActionTypes.GET_USERS, getUsersSaga);
  yield takeEvery(ActionTypes.GET_USER, getUser);
  yield takeEvery(ActionTypes.UPDATE_USER, updateUser);
  yield takeEvery(ActionTypes.DELETE_USER, deleteUser);
  yield takeEvery(ActionTypes.GET_TEMP_USERS, getTempUsersSaga);
  yield takeEvery(ActionTypes.UPDATE_TEMP_USER, updateTempUser);
  yield takeEvery(ActionTypes.DELETE_TEMP_USERS, deleteTempUser);
  yield takeEvery(ActionTypes.GET_REPORTS_USERS, getReportsUsers);
  yield takeEvery(ActionTypes.GET_USER_COMPANY, getUserCompany);
  yield takeEvery(ActionTypes.GET_ORDER_REPORT, getOrderReport);
  yield takeEvery(ActionTypes.GET_USER_LOG_ENTRIES, getUserLogEntries);
  yield takeEvery(ActionTypes.GET_USERS_CONTACT, getUsersContact);
}