import React, { forwardRef } from "react";



export const DropdownTopbarItemToggler = forwardRef((props, ref) => {
  return (
    <span ref={ref} className="topbar-item" onClick={e => { e.preventDefault(); props.onClick(e); }}>
      {props.children}
    </span>
  );
});

DropdownTopbarItemToggler.displayName = 'DropdownTopbarItemToggler';
