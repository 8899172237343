import React, { useState, useCallback } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from "_metronic/_partials/controls";
import { getCountryList } from "_metronic/_helpers";
import { AddressEditDialog } from "./AddressEditDialog";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useLang } from "_metronic/i18n";
import { Icon } from '_metronic/_icons';



export function AddressCard({ loading }) {

  const locale = useLang();

  const initialShow = { shippingShow: false, billingShow: false };

  const [show, setShow] = useState(initialShow);
  const billingAddressEdit = () => setShow({ ...show, billingShow: true });
  const shippingAddressEdit = () => setShow({ ...show, shippingShow: true });

  const closeAddressEditDialog = useCallback(() => {
    setShow(initialShow)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values: { customer_details } } = useFormikContext();


  return (<>

    {(show.billingShow || show.shippingShow) &&
      <AddressEditDialog
        show={show.billingShow || show.shippingShow}
        onHide={closeAddressEditDialog}
        title={show.billingShow ? "ADDRESS.INVOICE" : (show.shippingShow ? "ADDRESS.DELIVERY" : "")}
        loading={loading}
      />
    }

    <Row>
      {[customer_details.invoice_address, customer_details.delivery_address].map((item, i) => (
        <Col md={6} key={i}>
          <Card className="card-stretch gutter-b">

            <CardSubHeader icon={<Icon.GeoAlt />} title={<FormattedMessage id={i === 0 ? "ADDRESS.INVOICE" : "ADDRESS.DELIVERY"} />}>
              <CardHeaderToolbar>
                <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
                  <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={i === 0 ? billingAddressEdit : shippingAddressEdit} disabled={loading} id="btn_edit_address">
                    <Icon.PencilSquare />
                  </Button>
                </OverlayTrigger>
              </CardHeaderToolbar>
            </CardSubHeader>

            <CardBody className="font-weight-bold py-6">

              <div className="svg-icon svg-icon-lg">
                <Icon.Dot className="text-dark-50 mr-1" />
                {item?.name}
              </div>

              {item?.company &&
                <div className="svg-icon svg-icon-lg">
                  <Icon.Dot className="text-dark-50 mr-1" />
                  {item?.company}
                </div>}

              <div className="d-flex svg-icon svg-icon-lg">
                <Icon.Dot className="text-dark-50 mr-1" />
                <div>
                  <div>{item?.address_line1} {item?.address_line2}</div>
                  <div>{item?.post_code} {item?.city}</div>
                  <div>{getCountryList(locale).find(code => code.value === item?.country_code)?.label}</div>
                </div>
              </div>

            </CardBody>

          </Card>
        </Col>

      ))}
    </Row>

  </>);
}