import { API } from 'constants/apiUrl';
import { searchKeys, supportFieldTable } from './params';
import { paginationParams, searchParams } from '../_helpers/query-params';



export const ticketMiddleware = {

  createTicket: async function (accessToken, ticket) {
    return await API.post(`/supports`, ticket, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  putTicket: async function (accessToken, ticketId, data, sessionId) {
    return await API.put(`/supports/${ticketId}/${sessionId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchTicket: async function (accessToken, id, data) {
    return await API.patch(`/supports/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteTicket: async function (accessToken, id) {
    return await API.delete(`/supports/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getTickets: async function (accessToken, dateRange, { search, pagination, filter, order }) {
    /* If we do not exclude date_From and date_to here, it goes to backend and crashes the response. */
    const { stat, tag, priority, read, seen, date_from, date_to, ...rest } = filter;

    const statuses = stat ? Object.keys(stat).filter((key) => stat[key]) : [];
    const tags = tag ? Object.keys(tag).filter((key) => tag[key]) : [];
    const priorities = priority ? Object.keys(priority).filter((key) => priority[key]) : [];

    return await API.get(`/supports`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'updated_at DESC'}`],
          where: {
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(search && { ...searchParams(searchKeys, search) }),
            ...(filter && {
              ...rest,
              ...(Array.isArray(statuses) && !statuses.includes('all_tickets') && statuses.length > 0 && {
                'status': { inq: statuses },
              }),
              ...(Array.isArray(tags) && !tags.includes('all_tags') && tags.length > 0 && {
                'tag': { inq: tags },
              }),
              ...(Array.isArray(priorities) && !priorities.includes('all_priorities') && priorities.length > 0 && {
                'priority': { inq: priorities },
              }),
              ...(seen
                ? (seen && seen !== 'all_read' && { seen: seen === 'read' })
                : (read && read !== 'all_read' && { read: read === 'read' })
              ),
            }),
          },
          ...supportFieldTable,
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
  },


  getCount: async function (accessToken, dateRange, { search, filter }) {
    const { stat, tag, priority, read, seen, date_from, date_to, ...rest } = filter;

    const statuses = stat ? Object.keys(stat).filter((key) => stat[key]) : [];
    const tags = tag ? Object.keys(tag).filter((key) => tag[key]) : [];
    const priorities = priority ? Object.keys(priority).filter((key) => priority[key]) : [];

    return await API.get(`/supports/count`, {
      params: {
        where: {
          ...((dateRange.date_from && dateRange.date_to) && {
            created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
          }),
          ...(search && { ...searchParams(searchKeys, search) }),
          ...(filter && {
            ...rest,
            ...(Array.isArray(statuses) && !statuses.includes('all_tickets') && statuses.length > 0 && {
              'status': { inq: statuses },
            }),
            ...(Array.isArray(tags) && !tags.includes('all_tags') && tags.length > 0 && {
              'tag': { inq: tags },
            }),
            ...(Array.isArray(priorities) && !priorities.includes('all_priorities') && priorities.length > 0 && {
              'priority': { inq: priorities },
            }),
            ...(seen
              ? (seen && seen !== 'all_read' && { seen: seen === 'read' })
              : (read && read !== 'all_read' && { read: read === 'read' })
            ),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getTicketById: async function (accessToken, id) {
    return await API.get(`/supports/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  /*   ticketRead: async function (accessToken, id) {
      return await API.get(`/supports/read/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }, */


  uploadFile: async function (accessToken, ticketId, images, sessionId) {
    return await API.post(`/supports/upload/${ticketId}/${sessionId}`, images, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  deleteImage: async function (accessToken, imageId, sessionId, ticketId) {
    return await API.delete(`/supports/delete/${ticketId}/${sessionId}/${imageId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

};