import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ActionsColumnFormatter(cellContent, row, rowIndex, { openDeleteTicketDialog, openEditTicketDialog, setTicketId, hidden }) {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="ticket-view-tooltip"><FormattedMessage id="SUPPORT.VIEW_TICKET" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-primary btn-sm" to={openEditTicketDialog(row.id)} id={`btn_view_${row.number}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </Link>
      </OverlayTrigger>

      {hidden && <OverlayTrigger overlay={<Tooltip id="ticket-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
        <Link className="btn btn-icon btn-hover-danger btn-sm" to={openDeleteTicketDialog(row.id)} onClick={() => setTicketId(row.number)} id={`btn_delete_${row.number}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Trash />
          </span>
        </Link>
      </OverlayTrigger>}
    </>
  );
}
