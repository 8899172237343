import React from 'react';
import { Icon } from '_metronic/_icons';
import { BASE_URL } from 'constants/apiUrl';



export function NameColumnFormatter(cellContent, row, rowIndex, { openProductDetailsDialog }) {
  return (
    <div className="d-flex align-items-center">
      <div className="symbol symbol-40 symbol-light mr-3" onClick={() => openProductDetailsDialog(row.id)} id={`btn_open_product_${row.id}`}>
        <span className="symbol-label overflow-hidden">
          {row.images[0]?.thumbnail
            ? <img src={`${BASE_URL}/${row.images[0]?.thumbnail}`} className="h-100" alt={cellContent} />
            : row.images[0]?.url
              ? <img src={`${row.images[0]?.url}`} className="h-100" alt={cellContent} />
              : <Icon.Image className="h-100 w-50" style={{ opacity: 0.3 }} />
          }
        </span>
      </div>

      <div>
        <div className="font-weight-bolder text-dark-75">
          <span>
            {cellContent}
          </span>
          <span className="text-dark-25 svg-icon svg-icon-sm invisible pl-2 pb-1">
            <Icon.PencilSquare />
          </span>
        </div>
        <div className="font-size-sm font-weight-bold text-dark-50">
          {row.sku}
        </div>
      </div>
    </div>
  );
}
