import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TICKET_STATUS, TICKET_TAG, TICKET_PRIORITY, TICKET_READ_FILTER } from '../_context/SupportUIHelper';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { useSupportUIContext } from '../_context/SupportUIContext';
import { MultipleFilter } from '_metronic/_partials/components';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';
import clsx from 'clsx';



export function SupportFilter({ UIProps }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');

  const { role } = useSelector(state => ({
    role: state.auth.user.role,
  }));

  const filter = UIProps.queryParams.filter;
  const readItem = TICKET_READ_FILTER.find(item => item.value === (role === "644c41e257d397eb064fe0f9" ? filter?.seen : filter?.read) || item.value === 'all_read')

  const UIContext = useSupportUIContext();

  useEffect(() => {

    setSearch(query.get('search') ?? '');

    const statusFilterValues = TICKET_STATUS.reduce((acc, item) => {
      acc[item.value] = UIProps.queryParams?.filter?.stat?.[item.value] ? true : false;
      return acc;
    }, {});
    setStatusFilterValue(statusFilterValues);

    const tagFilterValues = TICKET_TAG.reduce((acc, item) => {
      acc[item.value] = UIProps.queryParams?.filter?.tag?.[item.value] ? true : false;
      return acc;
    }, {});
    setTagFilterValue(tagFilterValues);

    const priorityFilterValues = TICKET_PRIORITY.reduce((acc, item) => {
      acc[item.value] = UIProps.queryParams?.filter?.priority?.[item.value] ? true : false;
      return acc;
    }, {});
    setPriorityFilterValue(priorityFilterValues);

  }, [UIProps.queryParams.filter.priority, UIProps.queryParams.filter.stat, UIProps.queryParams.filter.tag, query]);

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }

  const onFilterHandler = (name, value) => {
    UIProps.setFilterQuery(name, value);
  };

  // Status Filtresi fonksiyonları
  const defaultStatusFilterValues = TICKET_STATUS.reduce((acc, item) => {
    acc[item.value] = UIProps.queryParams?.filter?.stat?.[item.value] ? true : false;
    return acc;
  }, {});

  const [statusFilterValue, setStatusFilterValue] = useState(defaultStatusFilterValues);

  const handleStatusFilterChange = (updatedFilters) => {
    setStatusFilterValue(updatedFilters);
    UIProps.setFilterQuery(updatedFilters, "stat");
  };

  // Tag Filtresi fonksiyonları
  const defaultTagFilterValues = TICKET_TAG.reduce((acc, item) => {
    acc[item.value] = UIProps.queryParams?.filter?.stat?.[item.value] ? true : false;
    return acc;
  }, {});

  const [tagFilterValue, setTagFilterValue] = useState(defaultTagFilterValues);

  const handleTagFilterChange = (updatedFilters) => {
    setTagFilterValue(updatedFilters);
    UIProps.setFilterQuery(updatedFilters, "tag");
  };

  // Priority Filtresi fonksiyonları
  const defaultPriorityFilterValues = TICKET_PRIORITY.reduce((acc, item) => {
    acc[item.value] = UIProps.queryParams?.filter?.priority?.[item.value] ? true : false;
    return acc;
  }, {});

  const [priorityFilterValue, setPriorityFilterValue] = useState(defaultPriorityFilterValues);

  const handlePriorityFilterChange = (updatedFilters) => {
    setPriorityFilterValue(updatedFilters);
    UIProps.setFilterQuery(updatedFilters, "priority");
  };


  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex flex-wrap align-items-start justify-content-start px-md-0'>

        {/* Status filter dropdown */}
        <MultipleFilter
          icon={<Icon.ChatLeftText />}
          title={<FormattedMessage id='GENERAL.STATUS' />}
          items={TICKET_STATUS}
          itemNamePlural={<FormattedMessage id="GENERAL.FILTER.STATUSES" />}
          noItemMessage={<FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" />}
          selectedFilters={statusFilterValue}
          onFilterChange={handleStatusFilterChange}
        />


        {role === "644c41e257d397eb064fe0f9"
          ? <>
            {/* Tag filter dropdown */}
            <MultipleFilter
              icon={<Icon.Tag />}
              title={<FormattedMessage id='SUPPORT.FILTER.TAG' />}
              items={TICKET_TAG}
              itemNamePlural={<FormattedMessage id="GENERAL.FILTER.TAGS" />}
              noItemMessage={<FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" />}
              selectedFilters={tagFilterValue}
              onFilterChange={handleTagFilterChange}
            />

            {/* Priority filter dropdown */}
            <MultipleFilter
              icon={<Icon.ChevronDoubleUp />}
              title={<FormattedMessage id='SUPPORT.FILTER.PRIORITY' />}
              items={TICKET_PRIORITY}
              itemNamePlural={<FormattedMessage id="GENERAL.FILTER.PRIORITIES" />}
              noItemMessage={<FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" />}
              selectedFilters={priorityFilterValue}
              onFilterChange={handlePriorityFilterChange}
            />
          </>
          : null}

        {/* Read filter dropdown */}
        <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center font-weight-bold svg-icon svg-icon-sm" style={{ height: theme.units.input.height['sm'] }} id="dropdown-tag">
            <span className={clsx("svg-icon svg-icon-md mr-0", `text-${readItem.className}`)}>
              {readItem.icon ?? <Icon.CheckAll />}
            </span>
            <div className="d-none d-sm-inline ml-3">
              {readItem?.label}
            </div>
          </Dropdown.Toggle>

          {readItem?.value !== "all_read" && <i className="symbol-badge bg-danger"></i>}

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down py-1' id='dropdown_document_tag'>
            <ul className="navi navi-hover">
              {TICKET_READ_FILTER.map((filter, index) => (
                <li className="navi-item" key={index}>
                  {filter.value === 'all_read' && <Dropdown.Divider />}
                  <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterHandler((role === "644c41e257d397eb064fe0f9" ? 'seen' : 'read'), filter.value)} id={`ticket_${filter.value}`}>
                    <span className={clsx("svg-icon svg-icon-md mr-3", `text-${filter.className}`)}>
                      {filter.icon}
                    </span>
                    <span className="navi-text">
                      {filter.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* Date filter dropdown */}
        <DatePickerDropdown UIContext={UIContext} setFilterQuery={UIProps.setFilterQuery} />

      </Col>
    </Row>
  );
}
