/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionsActions } from './_redux/actions';
import { Card, CardBody, CardHeader, CardHeaderToolbar, } from "_metronic/_partials/controls";
import { useSettingsEditContext } from './_context/SettingsEditContext';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import ConnectionsTable from './connection-table/ConnectionsTable';
import { UserGuideHelper } from 'app/user-guides/UserGuideHelper';
import { BlankMessage } from '_metronic/_partials/components';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';



export function ConnectionCard() {

  const dispatch = useDispatch();

  const { connections, loading, entities, license } = useSelector(state => ({
    connections: state.connections.entities,
    loading: state.connections.loading,
    entities: state.connections.entities,
    license: state.auth.license.type
  }))

  const UIContext = useSettingsEditContext();
  const UIProps = useMemo(() => ({
    openFetchOrdersDialog: UIContext.openFetchOrdersDialog,
    openConnectionAddDialog: UIContext.openConnectionAddDialog,
  }), [UIContext]);


  // active ve development-tester license olanları getir
  const isDevelopment = ["development", "tester", "test_user"].includes(license);
  const sortedTokens = CONNECTION_SETTINGS
    .filter(token => token.active && !(token.type === "shipper" && !token.product(isDevelopment)) && token.name !== "dhl")
    .sort((a, b) => {
      const typeOrder = { marketplace: 0, shop: 1, shipper: 2 };
      const typeComparison = typeOrder[a.type] - typeOrder[b.type];
      if (typeComparison === 0) {
        return a.name.localeCompare(b.name);
      }
      return typeComparison;
    });

  // except-entityNames listesinde olmayan ve multiple olanları getir
  const except = ['amazon_prime', 'dhl_warenpost', 'dhl_new_warenpost'];
  const entityNames = new Set(entities.map(entity => entity.name));
  const filtered = sortedTokens.filter(obj => !except.includes(obj.name) && !(!obj.multiple && entityNames.has(obj.name)));


  useEffect(() => {
    dispatch(ConnectionsActions.getConnections());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Connection />}
        title={<FormattedMessage id="MENU.ASIDE.CONNECTIONS" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_connections'
      >
        <CardHeaderToolbar>
          <Dropdown className="dropdown-inline" drop="down">

            <Dropdown.Toggle variant='primary' className="svg-icon svg-icon-sm font-weight-bold" id="dropdown-connections">
              <Icon.Plus />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id="CONNECTIONS.ADD_CONNECTIONS" />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu id='dropdown_document_grouping'>
              <ul className="navi navi-hover">
                {filtered?.map((token, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && token.type !== filtered[index - 1].type && <div className="separator separator-dashed my-1" />}
                    <li className="navi-item" key={token.name}>
                      <Dropdown.Item className="navi-link preview py-1" onClick={() => UIProps.openConnectionAddDialog(token.name, null)} id={`dropdown_item_${token.name}`}>
                        <span className="icon-2x mr-1">
                          {token.icon}
                        </span>
                        <span className="navi-text text-nowrap font-weight-bold">
                          {token.label}
                        </span>
                        <span className="svg-icon svg-icon-sm invisible pl-2">
                          <Icon.Plus />
                        </span>
                      </Dropdown.Item>
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </Dropdown.Menu>

          </Dropdown>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody className="p-0">
        {connections?.length === 0
          ? <BlankMessage
            icon={<Icon.Connection />}
            title={<FormattedMessage id='CONNECTIONS.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='CONNECTIONS.BLANK_MESSAGE' />}
            className="min-50-height"
            loading={loading}
            arrow
          />
          : <ConnectionsTable
            connections={connections}
            loading={loading}
            dispatch={dispatch}
            openFetchOrdersDialog={UIProps.openFetchOrdersDialog}
          />
        }
      </CardBody>

    </Card>
  );
}