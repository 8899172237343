export function useGetCategories(entities) {

  const sortEntity = entities.sort((a, b) => a.name.localeCompare(b.name));
  let map = {}, node, categories = [], i;

  for (i = 0; i < sortEntity.length; i += 1) {
    map[sortEntity[i].id] = i;
    sortEntity[i].children = [];
  }

  for (i = 0; i < sortEntity.length; i += 1) {
    node = sortEntity[i];
    if (node.parent_id !== '0') {
      const parentNode = sortEntity[map[node.parent_id]];
      if (parentNode) {
        parentNode.children.push(node);
      }
    } else {
      categories.push(node);
    }
  }

  return categories;
}