import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Table, OverlayTrigger, Tooltip, Button, } from 'react-bootstrap';
import { RemainingDays, TableButton } from '_metronic/_partials';
import { getConnection } from 'constants/connectionSettings';
import { ConnectionsActions } from '../_redux/actions';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { UIDates } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';
import RemainingBalance from './column-formatter';



export default function ConnectionsTable({ connections, loading, dispatch, openFetchOrdersDialog }) {
  return (
    <Table responsive className="table-head-custom table-head-b table-vertical-center table-striped table-hover">
      <thead>
        <tr className="text-left text-uppercase">

          <th className="rounded-0 pl-5 pl-md-12" style={{ minWidth: "200px" }}>
            <FormattedMessage id="GENERAL.NAME" />
          </th>

          <th style={{ minWidth: "50px" }}>
            <FormattedMessage id='CONNECTIONS.TYPE' />
          </th>

          <th className="text-center" style={{ minWidth: "50px" }}>
            <FormattedMessage id='CONNECTIONS.EXPIRATION_DATE' />
          </th>

          <th className="text-center" style={{ minWidth: "50px" }}>
            <FormattedMessage id='CONNECTIONS.CHECK' />
          </th>

          <th className="text-center" style={{ minWidth: "50px" }}>
            <FormattedMessage id='GENERAL.REGISTRATION_DATE' />
          </th>

          <th className="text-right rounded-0 pr-5 pr-md-12" style={{ minWidth: "120px" }}>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>

        </tr>
      </thead>

      <tbody>
        {connections?.length > 0 && connections?.sort((a, b) => a.name.localeCompare(b.name)).map((data, index) => (
          <tr key={index}>

            <td className='pl-5 pl-md-10'>
              <div className="d-flex align-items-center">

                <div className="d-flex text-center mr-3" style={{ fontSize: "2.5rem" }}>
                  {getConnection(data.name)?.icon}
                </div>

                <div>

                  <Link to={ModuleRoutes.CONNECTIONS_SETTINGS_FN(data.name, data.id)} className="d-flex align-items-center preview" id='btn_product_preview'>
                    <span className='font-weight-bolder text-dark-75 text-hover-primary'>
                      {getConnection(data.name)?.label}
                    </span>
                    <span className="d-flex text-dark-25 svg-icon svg-icon-sm invisible pl-2">
                      <Icon.Settings />
                    </span>
                  </Link>

                  <div className="font-weight-bold font-size-sm text-dark-50">
                    {data[data.name]?.website
                      ? <a href={`${data[data.name]?.website}`} target="_blank" rel="noopener noreferrer" className='text-decoration-none text-hover-primary text-dark-50 font-size-sm preview'>
                        {data[data.name]?.website}
                        <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                          <Icon.Link />
                        </span>
                      </a>
                      : data[data.name]?.MerchantToken
                        ? <a href={`https://www.amazon.de/s?i=merchant-items&me=${data[data.name]?.MerchantToken}&marketplaceID=${data[data.name]?.MarketplaceID}`} target="_blank" rel="noopener noreferrer" className='text-decoration-none text-hover-primary text-dark-50 font-size-sm preview'>
                          {data[data.name]?.MerchantToken}
                          <span className='text-dark-25 svg-icon svg-icon-sm invisible pl-2'>
                            <Icon.Link />
                          </span>
                        </a>
                        : data[data.name?.replace("dhl_new", "dhl")]?.customerNumber
                          ? data[data.name?.replace("dhl_new", "dhl")]?.customerNumber
                          : data[data.name]?.market_name}
                  </div>

                </div>

              </div>
            </td>

            <td>
              <div className='text-dark-75 font-weight-bold'>
                <FormattedMessage id={data.token_type === 'shipper' ? 'CONNECTIONS.SHIPPER' : data.token_type === 'shop' ? 'CONNECTIONS.SHOP' : 'CONNECTIONS.MARKETPLACE'} />
              </div>
            </td>

            <td className="text-center">
              {data.name === 'deutsche_post'
                ? RemainingBalance(data[data.name]?.wallet_balance)
                : RemainingDays(data[data.name]?.token_expires)
              }
            </td>

            <td className="text-center">
              <OverlayTrigger overlay={
                <Tooltip id="ebay-listing-edit-tooltip" className='font-weight-bold'>
                  <div className={clsx(data.check !== 'success' && "text-danger")}>{data.check === 'success' ? <FormattedMessage id='CONNECTIONS.CHECK_SUCCESS' /> : data.error === "Installation is deactivated or not found" ? <FormattedMessage id='CONNECTIONS.INSTALLATION_ERROR' /> : data.error}</div>
                  <div className='font-size-sm text-dark-50'>{UIDates.formatDateTime(data.updated_at)}</div>
                </Tooltip>
              }>
                <span className="svg-icon svg-icon-md">
                  {(data.name === 'gls')
                    ? "" // TODO: Kargo firmaları için check işlemi yapıldığında düzeltilecek.
                    : data.check === 'success'
                      ? <Icon.CheckCircleFill className="text-success" />
                      : data.name === 'dhl' ? <Icon.ExclamationCircleFill className="text-warning" /> : <Icon.BanFill className="text-danger" />
                  }
                </span>
              </OverlayTrigger>
            </td>

            <td className="text-center">
              <div className='font-weight-bold font-size-sm text-dark-50'>
                {UIDates.formatDateTime(data.created_at)}
              </div>
            </td>

            <td className="text-right pr-5 pr-md-8">

              {data.token_type !== 'shipper' &&
                <OverlayTrigger overlay={<Tooltip id={`fetch-tooltip-${data.id}`}><FormattedMessage id='CONNECTIONS.FETCH_ORDERS' /></Tooltip>}>
                  <Button variant='icon' size='sm' className="btn-hover-success" onClick={() => { dispatch(ConnectionsActions.connectionFetchOrders(data.id)); openFetchOrdersDialog(data.name) }} disabled={loading} id={`btn_check_${data.id}`}>
                    <span className="svg-icon svg-icon-md">
                      <Icon.CloudDownload />
                    </span>
                  </Button>
                </OverlayTrigger>
              }

              {(data.name !== 'gls') &&
                <OverlayTrigger overlay={<Tooltip id={`check-tooltip-${data.id}`}><FormattedMessage id='CONNECTIONS.CHECK_OVERLAY' /></Tooltip>}>
                  <Button variant='icon' size='sm' className="btn-hover-info" onClick={() => dispatch(ConnectionsActions.connectionCheck(data.id))} disabled={loading} id={`btn_check_${data.id}`}>
                    <span className="svg-icon svg-icon-md">
                      <Icon.ArrowRepeat />
                    </span>
                  </Button>
                </OverlayTrigger>
              }

              <TableButton type='settings' link={ModuleRoutes.CONNECTIONS_SETTINGS_FN(data.name, data.id)} id={data.id} disabled={loading} />

              <TableButton type='delete' link={ModuleRoutes.CONNECTIONS_DELETE_FN(data.name, data.id)} id={data.id} disabled={loading} />

            </td>

          </tr>
        ))}

      </tbody>
    </Table>
  )
}
