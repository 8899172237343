import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { Col, Row } from 'react-bootstrap';
import { MultipleFilter, DatePickerDropdown } from '_metronic/_partials/components';
import { useShippingsUIContext } from '../_context/ShippingsUIContext';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { SearchControl } from '_metronic/_partials/controls';
import { ShippingsGrouping } from './ShippingsGrouping';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { SelectCounter } from '_metronic/_partials';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function ShippingsFilter({ queryParams, setSearchQuery, setFilterQuery }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  const UIContext = useShippingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
  }), [UIContext]);

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = value => {
    setSearchQuery(value);
    setSearchBase(value);
  };

  // Shipping Service MultipleFilter Fonksiyonları

  // Shipping kayıtlarında mevcut olan servisleri al
  const { usedShippingServices } = useSelector(state => ({
    usedShippingServices: state.shippings.services,
  }));

  // Servisleri filtreye uygun formata çevir. CONNECTION_SETTINGS uygun setleri taşıyor. MultipeFilters'ta filtre içeriğini oluşturacak.
  const existingShippers = CONNECTION_SETTINGS.filter(setting => usedShippingServices?.includes(setting.name));

  // Default filtre değerlerini oluştur.
  const defaultShipperFilterValues = useMemo(() => {
    const values = {};
    existingShippers.forEach(shipper => {
      values[shipper.name] = queryParams?.filter?.[shipper.name] ? true : false;
    });
    return values;
  }, [existingShippers, queryParams]);

  // Filtre değerlerini yöneten değişken ve fonksiyonu oluştur. -> MultipleFilters'ta seçili olan değerleri gösterecek.
  const [shipperFilterValue, setShipperFilterValue] = useState(defaultShipperFilterValues);

  // Filtre değişimini yönet. -> MultipleFilters'a pas ediliyor.
  const handleShipperFilterChange = (updatedFilters) => {
    // MultipleFilter içinde seçili olan unsurları güncelle
    setShipperFilterValue(updatedFilters);
    // URL de seçili olan unsurları güncelle
    // Gönderdiğimiz filtreler connections başlığı altında middleware a gitsin
    setFilterQuery(updatedFilters, "connections");
  };

  // Attrib MultipleFilter Fonksiyonları
  const attribFilterContent = [
    { name: "api", label: <FormattedMessage id="GENERAL.SHIPPING_LABEL" />, icon: "" },
    { name: "manuel", label: <FormattedMessage id="GENERAL.MANUEL_ENTRY" />, icon: "" },
    { name: "send", label: <FormattedMessage id="DOCUMENT.SHIPPING.TYPE_SHIPMENT" />, icon: "" },
    { name: "return", label: <FormattedMessage id="DOCUMENT.SHIPPING.TYPE_RETURN" />, icon: "" },
  ]

  const defaultAttribFilterValues = {
    api: queryParams?.filter?.attrib?.api ? true : false,
    manuel: queryParams?.filter?.attrib?.manuel ? true : false,
    send: queryParams?.filter?.attrib?.send ? true : false,
    return: queryParams?.filter?.attrib?.return ? true : false,
  }

  const [attribFilterValue, setAttribFilterValue] = useState(defaultAttribFilterValues);

  const handleAttribFilterChange = (updatedFilters) => {
    // MultipleFilter içinde seçili olan unsurları güncelle
    setAttribFilterValue(updatedFilters);
    // URL de seçili olan unsurları güncelle
    // Gönderdiğimiz filtreler attrib başlığı altında middleware a gitsin
    setFilterQuery(updatedFilters, "attrib");
  };


  return (
    <Row className="mb-3">

      <Col sm="12" md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="8" className='d-flex flex-wrap align-items-start justify-content-start px-md-0 mb-lg-0 mb-2'>

        <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />


        {/* ---------------------------------- Shipper filter ---------------------------------- */}
        <MultipleFilter
          icon={<Icon.Supplier />}
          title={<FormattedMessage id="DOCUMENT.SHIPPING_SERVICE" />}
          items={existingShippers}
          itemNamePlural={<FormattedMessage id="GENERAL.FILTER.SHIPPING_SERVICES" />}
          noItemMessage={<FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_SHIPPER_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />}
          selectedFilters={shipperFilterValue}
          onFilterChange={handleShipperFilterChange}
        />


        {/* ---------------------------------- Filter ---------------------------------- */}
        <MultipleFilter
          icon={<Icon.Sliders />}
          title={<FormattedMessage id='DOCUMENT.FILTER.CONTENT' />}
          items={attribFilterContent}
          itemNamePlural={<FormattedMessage id="GENERAL.FILTER.CONTENTS" />}
          noItemMessage={<FormattedMessage id="GENERAL.NO_OPTIONS_AVAILABLE" />}
          selectedFilters={attribFilterValue}
          onFilterChange={handleAttribFilterChange}
        />

        <SelectCounter count={UIProps.ids.length} />

        <ShippingsGrouping UIProps={UIProps} />

      </Col>
    </Row>
  );
}
