import React from 'react';
import { convertPriceCurrency } from '_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';



export default function RemainingBalance(price) {

  if (!price) return null

  const remainingColors = (balance) => {
    return balance > 0
      ? balance < 200
        ? "warning"
        : balance < 100
          ? "danger"
          : "success"
      : "danger";
  }

  return (
    <OverlayTrigger overlay={<Tooltip id="remaining-day-tooltip" className='font-weight-bold'>Deutsche Post <FormattedMessage id='CONNECTIONS.SHIPPER.DP.WALLET_BALANCE' /></Tooltip>}>
      <label className={`label label-inline label-xl svg-icon svg-icon-sm text-nowrap label-outline-${remainingColors(price)}`}>
        <span className={`label label-lg label-dot label-${remainingColors(price)} mr-2`}></span>
        <span className='font-weight-bolder'>{convertPriceCurrency(price)}</span>
      </label>
    </OverlayTrigger>
  );
};
