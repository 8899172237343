import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { convertPriceCurrency, sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates, remoteGetSelectRow, getCountryList } from '_metronic/_helpers';
import { ProductColumnFormatter } from 'app/modules/documents/document-table/column-formatters';
import * as UIHelpers from '../../documents/_context/DocumentsUIHelpers';
import { useArchiveUIContext } from '../_context/ArchiveUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import * as columnFormatters from './column-formatters';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArchiveActions } from '../_redux/actions';
import { useLang } from '_metronic/i18n';



export function ArchiveTable({ documents, loading, countDocuments, queryParams, setPaginationQuery, setOrderQuery, setSearchQuery }) {

  const locale = useLang();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  // UI Context
  const UIContext = useArchiveUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    requestedArchive: UIContext.requestedArchive,
    openArchiveRelocateDialog: UIContext.openArchiveRelocateDialog,
    openArchivePrintDialog: UIContext.openArchivePrintDialog,
    changeDateRange: UIContext.changeDateRange,
  }), [UIContext]);

  const openArchivePrintDialog = (id) => {

    if (id) {
      const data = {
        ids: [id],
        type: UIProps.requestedArchive.value,
      };

      dispatch(ArchiveActions.printRequest(data));
      UIProps.openArchivePrintDialog();
    }
  };

  const COLUMNS = [
    {
      dataField: 'id',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.MARKETPLACE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '100px' },
      headerClasses: 'text-nowrap pl-2',
      classes: 'font-weight-bold text-nowrap pl-2',
      style: { height: '53px' },
      formatter: columnFormatters.TitleColumnFormatter,
      formatExtraData: {
        UIProps: UIProps,
        UIHelpers: UIHelpers,
      },
    },
    {
      dataField: UIProps.requestedArchive.value === 'orders' ? 'order_date' : 'created_at',
      text: intl({
        id: UIProps.requestedArchive.value === 'orders'
          ? "DOCUMENT.DETAIL_DIALOG.ORDER.DATE"
          : "DOCUMENT.TABLE.COLUMN.OFFER.DATE"
      }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'font-weight-bold text-nowrap text-center pr-4',
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="date-tooltip" className='font-weight-bold'>{UIDates.formatDateTime(UIProps.requestedArchive.value === 'orders' ? row.order_date : row.created_at)}</Tooltip>}>
          <span type="button">
            {UIDates.formatDate(UIProps.requestedArchive.value === 'orders' ? row.order_date : row.created_at)}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'position',
      isDummyField: true,
      hidden: !['orders'].includes(UIProps.requestedArchive.value),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ARTICLE' }),
      headerStyle: { minWidth: '365px' },
      style: { fontSize: '11px' },
      classes: 'font-weight-bold',
      formatter: (cell, row, rowIndex) => <ProductColumnFormatter cell={cell} row={row} rowIndex={rowIndex} />,
    },
    {
      dataField: 'customer_details.name',
      text: intl({ id: 'CUSTOMER.TITLE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => {
        return (
          <span className="d-flex align-items-center text-nowrap">
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold'>{row.customer_details.name}</span>
              <span className='text-dark-50'>{row.customer_details.email}</span>
            </div>
          </span>
        );
      },
    },
    {
      dataField: 'customer_details.delivery_address.country_code',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '160px' },
      formatter: (cell, row) => {
        const address = row.customer_details?.delivery_address
        return (
          <OverlayTrigger overlay={<Tooltip id="address-tooltip">
            <div className='font-weight-bold text-dark-75 text-left'>
              <div className='font-weight-bolder'>{address?.name}</div>
              <div className='font-weight-bolder text-dark-50'>{address?.company}</div>
              <hr className='my-1' />
              <div>{address?.address_line1} {address?.address_line2}</div>
              <div>{address?.post_code} {address?.city}</div>
              <div>{getCountryList(locale).find(code => code.value === address?.country_code)?.label}</div>
            </div>
          </Tooltip>}>
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold text-nowrap'>{address.city}</span>
              <span className='text-dark-50'>{`${address?.country_code}-${address?.post_code}`}</span>
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'entered_payment',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT_TYPE' }),
      hidden: !['invoices', 'orders', 'waybills'].includes(UIProps.requestedArchive.value),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-muted font-weight-bold pr-7',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {

        const payments = cell?.map(payment => {
          const method = UIHelpers.paymentMethods.find(method => method.value === payment.payment_method);
          return method ? method.labelId ? intl({ id: method.labelId }) : method.label : payment.payment_method;
        });

        return [...new Set(payments)].join(', ');
      },
    },
    {
      dataField: 'payment_status',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT' }),
      hidden: !['invoices', 'orders', 'waybills'].includes(UIProps.requestedArchive.value),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      formatter: (cell, row) => {
        const paymentStatus = UIHelpers.getPaymentStatus(cell);
        return (
          <span className={`label label-inline label-lg font-weight-bold label-${paymentStatus?.className}`} id={`btn_payment_${row.id}`}>
            {paymentStatus?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
          </span>
        );
      },
    },
    {
      dataField: 'rest',
      isDummyField: true,
      hidden: !['invoices', 'orders'].includes(UIProps.requestedArchive.value),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.OPEN_AMOUNT' }),
      headerClasses: 'text-right',
      classes: 'font-weight-bolder text-right',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.RestPriceColumnFormatter,
      formatExtraData: {
        openEnterPaymentDialog: UIProps.openEnterPaymentDialog,
        openPaymentWarningsDialog: UIProps.openPaymentWarningsDialog,
      },
    },
    {
      dataField: 'pricing_summary.total',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.AMOUNT' }),
      hidden: !['orders'].includes(UIProps.requestedArchive.value),
      isDummyField: true,
      headerClasses: 'text-right',
      classes: 'font-weight-bolder text-right pl-3',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const paymentWarnings = row.payment_warnings?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
        const discount = row.pricing_summary?.discount ?? 0;
        const totalPriceDiscount = row.pricing_summary?.total_price_discount ?? 0;
        const totalPayment = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100) + +row.pricing_summary.shipping_price + paymentWarnings + totalPriceDiscount;
        const currency = row.pricing_summary.currency;
        return (
          convertPriceCurrency(totalPayment, currency)
        )
      },
    },
    {
      dataField: 'shipping',
      text: intl({ id: 'GENERAL.LABEL' }),
      headerStyle: { minWidth: '80px' },
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: columnFormatters.ShippingDataColumnFormatter,
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerClasses: 'text-center',
      classes: 'text-center',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openArchivePrintDialog,
        openArchiveRelocateDialog: UIProps.openArchiveRelocateDialog,
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids && UIProps.requestedArchive.value}
        data={documents || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        striped
        bootstrap4
        bordered={false}
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.changeDateRange(); setSearchQuery('') }} />}
        selectRow={remoteGetSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: documents,
          key: UIProps.requestedArchive.value,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countDocuments}
        entitiesCount={documents?.length}
        loading={loading}
      />
    </>
  );
}
