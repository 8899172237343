import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { SHIPPER_LABEL_OPTIONS, SHIPPER_TYPE_OPTIONS, EU_COUNTRIES, getShipperOptions } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { Checkbox, DropDownControl, RadioControl, SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { getCurrencyList } from '_metronic/_helpers/constant/optionData';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Col, Row, Alert } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';
import ShippingCustoms from './ShippingCustoms';
import { UIDates } from '_metronic/_helpers';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export function ShippingAddForm({ isLabeled, newOptions, dhlAccountOptions, documentId }) {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  const { document, connections, positions, default_shipping, default_product } = useSelector(state => ({
    document: state.documents.documentForEdit,
    connections: state.connections.entities,
    default_shipping: state.settings.shippingSettings.default_shipping,
    default_product: state.settings.productSettings.default_product,
    positions: state.documents.positions
  }), shallowEqual);

  const marketShippingPrice = document.pricing_summary.shipping_price ?? 0;

  const { values: { service, dp_product_id, service_options, billing_number, profile_name, labeled, customs, weight }, setFieldValue } = useFormikContext();

  const countryCode = document?.customer_details?.delivery_address?.country_code
  const isInland = countryCode === 'DE';
  const isAbroadEU = !EU_COUNTRIES.map(item => item.value).includes(countryCode);
  const positionsTotalWeight = positions?.reduce((sum, item) => sum + (item.weight * item.quantity), 0);

  const dhlCustomerNumber = connections.find((item) => item?.name === 'dhl_new')?.dhl?.customerNumber;
  const selectedAccount = dhlAccountOptions.filter((item) => {
    return isInland
      ? service === "dhl_new_warenpost"
        ? service_options.goGreen
          ? (item?.type === "dhl_new_warenpost" && item?.location === "domestic" && item?.goGreen)
          : (item?.type === "dhl_new_warenpost" && item?.location === "domestic" && !item?.goGreen)
        : service_options.goGreen
          ? (item?.type === "dhl_new" && item?.location === "domestic" && item?.goGreen)
          : (item?.type === "dhl_new" && item?.location === "domestic" && !item?.goGreen)
      : service === "dhl_new_warenpost"
        ? service_options.premium
          ? service_options.goGreen
            ? (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && item?.goGreen && item?.premium)
            : (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && !item?.goGreen && item?.premium)
          : service_options.goGreen
            ? (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && item?.goGreen)
            : (item?.type === "dhl_new_warenpost" && item?.location === "abroad" && !item?.goGreen)
        : service_options.goGreen
          ? (item?.type === "dhl_new" && item?.location === "abroad" && item?.goGreen) || (item?.type === "dhl_new" && item?.location === "eu" && item?.goGreen && !isAbroadEU)
          : (item?.type === "dhl_new" && item?.location === "abroad" && !item?.goGreen) || (item?.type === "dhl_new" && item?.location === "eu" && !item?.goGreen && !isAbroadEU)
  })
  const customerNumberOptions = selectedAccount.map(item => ({ label: `${dhlCustomerNumber}${item?.accountNumber} - ${intl({ id: item?.name })}`, value: `${dhlCustomerNumber}${item?.accountNumber}` })) || []

  // Unlabeled shipping default settings
  const foundDefaultShipping = default_shipping?.find(item => item.default);

  useEffect(() => {
    if (foundDefaultShipping) {
      setFieldValue("profile_name", foundDefaultShipping.profile_name)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundDefaultShipping]);


  useEffect(() => {
    if (selectedAccount.length) {
      setFieldValue("billing_number", `${dhlCustomerNumber}${selectedAccount[0]?.accountNumber}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, selectedAccount[0]?.accountNumber, service_options?.goGreen, profile_name, isAbroadEU])

  //DEUTSCHE POST
  const deutschePost = connections?.find(item => item?.token_type === 'shipper' && item?.name === 'deutsche_post') || null;

  useEffect(() => {
    const foundDPostSettings = default_shipping?.find(item => item?.service === service && !item?.unlabeled);
    if (isLabeled && service === 'deutsche_post' && foundDPostSettings) {
      //setFieldValue('price', (+findDeutschePost?.price / 100) || 0);
      setFieldValue('weight', foundDPostSettings?.weight);
      setFieldValue('dp_product_id', foundDPostSettings?.dp_product_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, dp_product_id, labeled])

  useEffect(() => {
    !document?.marketplace ? setFieldValue('price', default_shipping?.price ?? 0) : setFieldValue('price', marketShippingPrice) ;
    !service && setFieldValue("service", default_shipping?.method)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labeled])


  // useEffect(() => {
  //   dispatch(DocumentActions.getSettingsByType('shipping'))
  // }, [dispatch])


  //Eger profile name secilirse default shipping ayarlarini getir
  useEffect(() => {

    if (profile_name && isLabeled) {
      const foundSettings = default_shipping?.find(item => item?.profile_name === profile_name && !item?.unlabelled);

      setFieldValue("service", foundSettings?.service)
      setFieldValue('weight', foundSettings?.weight ? foundSettings?.weight : (service === 'dhl_warenpost' || service === 'dhl_new_warenpost') ? 0.5 : 2)
      document?.marketplace ? setFieldValue('price', marketShippingPrice) : setFieldValue('price', foundSettings?.price ?? 0)

    } else if (profile_name && !isLabeled) {
      const foundSettings = default_shipping?.filter(item => item?.unlabeled)?.find(item => item?.profile_name === profile_name);

      setFieldValue("service", foundSettings?.service)
      setFieldValue('weight', foundSettings?.weight ? foundSettings?.weight : (service === 'dhl_warenpost' || service === 'dhl_new_warenpost') ? 0.5 : 2)
      document?.marketplace ? setFieldValue('price', marketShippingPrice) : setFieldValue('price', foundSettings?.price ?? 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile_name, isLabeled, labeled, document?.marketplace])

  //Eger profile name secilmezse default shipping ayarlarini getir
  useEffect(() => {

    if (service && isLabeled) {
      const foundSettings = default_shipping?.find(item => item?.service === service && !item?.unlabelled);
      setFieldValue('weight', foundSettings?.weight ? foundSettings?.weight : (service === 'dhl_warenpost' || service === 'dhl_new_warenpost') ? 0.5 : 2)
      document?.marketplace ? setFieldValue('price', marketShippingPrice) : setFieldValue('price', foundSettings?.price ?? 0)

    } else if (service && !isLabeled) {
      const foundSettings = default_shipping?.filter(item => item?.unlabeled)?.find(item => item?.service === service);

      setFieldValue('weight', foundSettings?.weight ? foundSettings?.weight : (service === 'dhl_warenpost' || service === 'dhl_new_warenpost') ? 0.5 : 2)
      document?.marketplace ? setFieldValue('price', marketShippingPrice) : setFieldValue('price', foundSettings?.price ?? 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, isLabeled, labeled, document?.marketplace])

  const currencyOptions = getCurrencyList(locale);
  const selectedCurrencyOption = currencyOptions.find(option => option.value === default_product?.currency);
  const [defaultCurrency, setDefaultCurrency] = useState(selectedCurrencyOption);

  const handleChangeCurrency = (value) => {
    setDefaultCurrency({ value: value.value, label: value.label, symbol: value.symbol });
    setFieldValue('additional_insurance.currency', value.value);
  };

  useMemo(() => {
    if (service_options?.additional_insurance?.currency) {
      setDefaultCurrency(currencyOptions.find(f => f.value === service_options?.additional_insurance.currency));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service_options?.additional_insurance]);

  //DHL SERVICE OPTIONS AND ADDITIONAL INSURANCE INPUTS
  const ADDITIONAL_INSURANCE_INPUTS = [
    {
      name: "additional_insurance.value",
      type: "number",
      label: "GENERAL.PRICE",
      price: true,
      symbol: defaultCurrency?.symbol,
      size: "6",
      feedbackLabel: true,
    },
    {
      name: "additional_insurance.currency",
      label: "PRODUCT.GENERAL.CURRENCY",
      component: SVReactSelect,
      options: currencyOptions,
      value: defaultCurrency,
      onChange: handleChangeCurrency,
      isClearable: false,
      required: true,
      size: "6",
    },
  ]

  const SERVICE_OPTIONS = [
    {
      name: "service_options.premium",
      type: 'service',
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.PREMIUM' }),
      info: intl({ id: service === 'dhl_new' ? 'DOCUMENT.SHIPPING.DHL_SERVICE.PREMIUM_INFO' : 'DOCUMENT.SHIPPING.DHL_SERVICE.WARENPOST_PREMIUM_INFO' }),
      hide: (!isInland && selectedAccount?.some(item => item?.premium)),
      disabled: billing_number?.slice(-4) === '6603'
    },
    {
      name: "service_options.goGreen",
      type: 'service',
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.GO_GREEN' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.GO_GREEN_INFO' }),
      hide: dhlAccountOptions.some(item => item?.goGreen),
    },
    {
      name: "service_options.endorsement",
      type: 'service',
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.ENDORSEMENT' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.ENDORSEMENT_INFO' }),
      hide: service === 'dhl_new' && selectedAccount?.some(item => item?.endorsement),
      disabled: false
    },
    {
      name: "service_options.parcel_outlet_routing",
      type: 'service',
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.PARCEL_OUTLET_ROUTING' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.PARCEL_OUTLET_ROUTING_INFO' }),
      hide: isInland && billing_number?.slice(-4) !== '0101',
      disabled: false
    },
    {
      name: "service_options.additional_insurance",
      type: 'service',
      label: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.TRANSPORT_INSURANCE' }),
      info: intl({ id: 'DOCUMENT.SHIPPING.DHL_SERVICE.TRANSPORT_INSURANCE_INFO' }),
      hide: service === 'dhl_new' && selectedAccount?.some(item => item?.additional_insurance && item?.type === 'dhl_new'),
      disabled: false
    },
  ]

  //Service Options
  const currentService = (isLabeled ? newOptions : getShipperOptions()).find(i => i.value === service);

  const SHIPPING_OPTIONS = [
    {
      name: 'labeled',
      component: DropDownControl,
      options: SHIPPER_LABEL_OPTIONS || [],
      onBlur: () => setFieldValue('profile_name', ''),
      size: "6",
      hide: true
    },
    {
      name: 'type',
      component: RadioControl,
      options: SHIPPER_TYPE_OPTIONS || [],
      required: (isLabeled && service === 'deutsche_post'),
      size: "6",
      hide: true
    },
    {
      name: 'service',
      component: DropDownControl,
      options: (isLabeled ? newOptions : getShipperOptions()?.filter(i => i.value !== 'dhl_new_warenpost' && i.value !== 'dhl_new')
        .map(option => ({
          ...option,
          icon: <Icon.SupplierFIll />
        }))
      ) || [],
      placeholder: 'DOCUMENT.SHIPPING_SERVICE_PLACEHOLDER',
      noOptionsMessage: <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_SHIPPER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />,
      onBlur: () => setFieldValue('profile_name', ''),
      withFeedbackLabel: true,
      feedbackLabel: true,
      required: true,
      size: "12",
      hide: true,
      labeled: isLabeled
    },
    {
      name: 'dp_product_id',
      component: SVReactSelect,
      type: 'text',
      label: 'DOCUMENT.SHIPPING.DEUTSCHE_POST.PRODUCT_LIST',
      options: deutschePost?.deutsche_post?.product_list?.map(item => ({ label: item.name, value: item.id })) || [],
      withFeedbackLabel: true,
      feedbackLabel: true,
      required: (isLabeled && service === 'deutsche_post'),
      size: "12",
      hide: (isLabeled && service === 'deutsche_post' && currentService)
    },
    {
      name: 'tracking_id',
      component: SVFormControl,
      type: 'text',
      label: 'DOCUMENT.SHIPPING_TRACKING_ID',
      withFeedbackLabel: true,
      feedbackLabel: true,
      size: "6",
      hide: !isLabeled
    },
    {
      name: 'delivery_date',
      component: SVFormControl,
      type: 'date',
      label: 'GENERAL.DELIVERY_DATE',
      withFeedbackLabel: true,
      feedbackLabel: true,
      size: (isLabeled && service === 'dpd') ? "12" : "6",
      hide: !isLabeled || service === 'dpd'
    },
    {
      name: 'weight',
      component: SVFormControl,
      type: 'number',
      label: 'GENERAL.WEIGHT',
      withFeedbackLabel: true,
      feedbackLabel: (['dhl_new', 'dhl_new_warenpost'].includes(service) && isAbroadEU && weight <= positionsTotalWeight)
        ? <span className='text-danger'>
          <FormattedMessage id="DOCUMENT.SHIPPING.DHL.CUSTOMS.REMAINING_CHARACTER" values={{ positionsTotalWeight: positionsTotalWeight?.toFixed(2) }} />
        </span>
        : true,
      required: true,
      size: "6",
      hide: true,
      min: 0,
      step: 0.01,
      info: (isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(service)) && intl({ id: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.WEIGHT_INFO' }),
    },
    {
      name: 'price',
      component: SVFormControl,
      type: 'number',
      label: 'DOCUMENT.SHIPPING_PRICE',
      withFeedbackLabel: true,
      feedbackLabel: true,
      disabled: (document?.marketplace !== ""),
      size: "6",
      hide: true,
      min: 0,
      step: 0.01,
      info: (isAbroadEU && ['dhl_new', 'dhl_new_warenpost'].includes(service))
        ? intl({ id: 'DOCUMENT.SHIPPING.DHL.CUSTOMS.PRICE_INFO' })
        : document?.marketplace
          ? intl({ id: 'DOCUMENT.SHIPPING.MARKETPLACE_PRICE_INFO' })
          : null,
    }
  ]

  // if default shipping settings are not found, set the total weight of the positions
  useEffect(() => {
    const foundLabeledWeight = default_shipping?.find(item => item?.profile_name === profile_name && !item?.unlabelled)?.weight;
    const foundUnlabeledWeight = default_shipping?.filter(item => item?.unlabeled)?.find(item => item?.service === service)?.weight;

    if (!foundLabeledWeight || !foundUnlabeledWeight) {
      setFieldValue('weight', positionsTotalWeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsTotalWeight, labeled, default_shipping])

  useEffect(() => {
    return () => {
      setFieldValue('billing_number', '');
      setFieldValue('delivery_date', UIDates.getCurrentDate());
      setFieldValue('weight', 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, service_options.goGreen]);


  return (
    <Row>

      {SHIPPING_OPTIONS.map((item) => (
        item.hide &&
        <Col lg={item.size || "12"} key={item.name}>
          <Field component={item.component || SVFormControl}
            {...{
              name: item.name,
              type: item.type,
              label: item.label && intl({ id: item.label }),
              placeholder: item.placeholder && intl({ id: item.placeholder }),
              ...(item.noOptionsMessage && { noOptionsMessage: item.noOptionsMessage }),
              options: item.options,
              onBlur: item.onBlur,
              withFeedbackLabel: true,
              feedbackLabel: item.feedbackLabel ?? true,
              required: item.required,
              disabled: item.disabled,
              info: item.info,
              labeled: item.labeled,
              min: item.min,
              step: item.step,
            }}
          />
        </Col>
      ))}

      {/* DPD */}
      {(service === 'dpd' && isLabeled && UIDates.isWeekend()) &&
        <Col md={12}>
          <Alert variant='light-info' className="alert-custom alert-notice mb-6">
            <div className="text-dark-75 line-height-xl">
              <FormattedMessage id='DOCUMENT.SHIPPING.DPD.SHIPMENT_DATE.NEXT_BUSINESS' />
            </div>
          </Alert>
        </Col>
      }

      {/*Suan iki DHL ayni anda kullanildigi icin bu kontrol konuldu */}
      {(['dhl_new', 'dhl_new_warenpost'].includes(service) && isLabeled) &&
        <>
          {(dhlAccountOptions?.some(item => item?.additional_insurance || item?.premium || item?.endorsement || item?.goGreen)) &&
            <Col lg="12">
              <div className="separator separator-dashed my-2" />
              <label className='font-weight-bold text-capitalize text-dark-75 '>
                <FormattedMessage id="DOCUMENT.SHIPPING.DHL_ADDITIONAL_SERVICE" />
              </label>
            </Col>
          }

          {/* DHL ve Warenpost servis ayarlari */}
          {/* TODO: hidden dan dolayi modal acilirken büyüme kücülme yasaniyor bir cözüm bulununca düzeltilmeli */}
          <Col lg="12" className='mb-2'>
            {SERVICE_OPTIONS.map((item, i) => (item.hide &&
              <Field component={Checkbox} key={i}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  info: intl({ id: item.info }),
                  disabled: item.disabled,
                }}
              />
            ))}
          </Col>

          {/* DHL de sigortali gönderi */}
          {(service === 'dhl_new' && selectedAccount?.some(item => item?.additional_insurance)) && ADDITIONAL_INSURANCE_INPUTS.map((item, i) =>
            <Col md="6" lg={item.size || "6"} key={i}>
              <Field component={item.component || SVFormControl}
                {...{
                  name: item.name,
                  type: item.type,
                  label: intl({ id: item.label }),
                  options: item.options,
                  withFeedbackLabel: true,
                  defaultValue: item.defaultValue,
                  feedbackLabel: item.feedbackLabel,
                  isClearable: item.isClearable,
                  onChange: item.onChange,
                  required: item.required,
                  price: item.price,
                  symbol: item.symbol,
                  onFocus: item.onFocus,
                  value: item.value,
                  min: 0,
                  step: item.price && 0.01,
                  disabled: !service_options?.additional_insurance,
                }}
              />
            </Col>
          )}

          {/* Customs */}
          {(countryCode && isAbroadEU && customerNumberOptions.length > 0) && <ShippingCustoms positions={positions} export_type={customs.export_type} export_description={customs.export_description} />}

          {/* Abrechnungnummer */}
          <Col lg="12" key="billing_number">
            {selectedAccount.length === 0
              ? <div>
                <Alert variant='light-warning' className="alert-custom alert-notice text-dark-75 flex-column line-height-xl">
                  <FormattedMessage id='DOCUMENT.SHIPPING.DHL.NO_BILLING_DATA' />
                </Alert>
              </div>
              : <Field
                component={SVReactSelect}
                name="billing_number"
                type="text"
                label={intl({ id: "DOCUMENT.SHIPPING.DHL.BILLING_DATA" })}
                withFeedbackLabel={true}
                feedbackLabel={true}
                required={true}
                options={customerNumberOptions}
                info={intl({ id: "DOCUMENT.SHIPPING.DHL.BILLING_DATA_INFO" })}
              />
            }
          </Col>
        </>
      }
    </Row>
  );
}
