import React, { useEffect, useMemo, useState } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { ProductKeysGrouping } from './ProductKeysGrouping';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { KEY_STATUS } from '../../_context/DigitalProductsUIHelpers';
import { useDigitalProductsUIContext } from '../../_context/DigitalProductsUIContext';
import { useSearchQueryContext } from '../../_context/SearchQueryProvider';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { SelectCounter } from '_metronic/_partials';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';
import moment from 'moment';



export function ProductKeysFilter({ connectionEntities, entities }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');
  const [filterParamsBase, setFilterParamsBase] = useState({
    date_from: null,
    date_to: null,
    marketplace: null,
    key_status: null,
  });

  useEffect(() => {

    setSearchBase(query.get('search') ?? '');
    const date_from = query.get('filter.date_from');
    const date_to = query.get('filter.date_to');
    const key_status = query.get('filter.key_status');
    const marketplace = query.get('filter.marketplace');

    setFilterParamsBase(prev => ({
      ...prev,
      date_from: date_from ? date_from : null,
      date_to: date_to ? date_to : null,
      key_status: KEY_STATUS.map(i => i.value).includes(key_status) ? key_status : null,
      marketplace: marketplace
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const UIContext = useDigitalProductsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openSendMailKeyDialog: UIContext.openSendMailKeyDialog,
    openReserveDialog: UIContext.openReserveDialog
  }),
    [UIContext]
  );

  const queryContext = useSearchQueryContext();
  const { setSearchQuery, setFilterQuery } = useMemo(() => ({
    queryParams: queryContext.queryParams,
    setOrderQuery: queryContext.setOrderQuery,
    setPaginationQuery: queryContext.setPaginationQuery,
    setSearchQuery: queryContext.setSearchQuery,
    setFilterQuery: queryContext.setFilterQuery
  }), [queryContext]);

  useEffect(() => {
    setSearchBase(query.get('search') ?? '');
  }, [query]);

  const handleSearch = value => {
    setSearchQuery(value);
    setSearchBase(value);
  };

  const onDateSwitcherHandler = (value) => {

    if (!value) {
      setFilterParamsBase(prev => ({
        ...prev,
        date_filter: false,
        date_from: null,
        date_to: null,
      }));
      setFilterQuery('DELETE_PARAMS', ['date_from', 'date_to']);
    }

    if (value) {
      setFilterParamsBase(prev => ({
        ...prev,
        date_filter: true,
        date_from: moment().add(-7, 'days').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
      }));
      // setFilterQuery('DATE_FILTER', ['date_from', 'date_to'], requestedDocument.type);
    }
  };

  const onFilterHandler = (key, value) => {
    setFilterQuery(key, value)
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const onDeleteFilterHandler = (key, value) => {
    if (key === 'date_from' || key === 'date_to') {
      return onDateSwitcherHandler(false);
    }
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: false,
    }));
    setFilterQuery('DELETE_PARAMS', key);
  };

  const sortedTokens = CONNECTION_SETTINGS
    .filter(token => token.active && !(token.type === "shipper"))
    .sort((a, b) => {
      const typeOrder = { marketplace: 0, shop: 1, shipper: 2 };
      const typeComparison = typeOrder[a.type] - typeOrder[b.type];
      if (typeComparison === 0) {
        return a.name.localeCompare(b.name);
      }
      return typeComparison;
    });

  const connections = connectionEntities.filter(connection => connection.token_type !== 'shipper')
  // Amazon Prime connection added
  connections.push({
    name: "amazon_prime",
    label: "Amazon Prime",
    icon: <Icon.AmazonPrimeLong />,
  });

  const filteredConnections = sortedTokens.filter(obj => connections.some(connection => connection.name === obj.name));

  // Marketplace none filter
  filteredConnections.push({
    name: 'none',
    label: <FormattedMessage id="GENERAL.FILTER.NONE_MARKET" />,
    icon: <Icon.ShopWindow className="text-dark-75" />
  })

  const selectMarketplace = filteredConnections.find(i => i.name === filterParamsBase.marketplace);

  const selectedKeyStatus = KEY_STATUS.find(item => item.value === filterParamsBase.key_status);


  return (
    <Row className="mb-3">

      <Col md="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col md="8" className='d-flex align-items-start px-md-0'>


        {/* --------------------------------------- Market filter started --------------------------------------- */}
        <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold svg-icon svg-icon-sm' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            {selectMarketplace?.icon ?? <Icon.Shop />}
            <span className="d-none d-sm-inline ml-2">
              {selectMarketplace?.label ?? <FormattedMessage id="DOCUMENT.FILTER.MARKET" />}
            </span>
          </Dropdown.Toggle>

          {filterParamsBase.marketplace && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" id="dropdown_market_options">
            <ul className="navi navi-hover">
              {filteredConnections.length
                ? <>
                  {filteredConnections?.map((opt, index) => (
                    <li className="navi-item" key={index}>
                      <Dropdown.Item className="navi-link" onClick={() => onFilterHandler('marketplace', opt.name)} id={`dropdown_item_${opt.name}`}>
                        <span className="svg-icon svg-icon-md mr-2">
                          {opt.icon}
                        </span>
                        <span className="navi-text text-nowrap font-weight-bold">
                          {opt.label}
                        </span>
                      </Dropdown.Item>
                    </li>
                  ))}

                  <Dropdown.Divider />

                  <li className="navi-item">
                    <Dropdown.Item
                      className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                      onClick={() => onDeleteFilterHandler('marketplace', CONNECTION_SETTINGS.find(i => i.name === filterParamsBase.marketplace)?.name)}
                      id='clear-marketplace'
                    >
                      <FormattedMessage id='GENERAL.CLEAR' />
                    </Dropdown.Item>
                  </li>
                </>
                : <li className="navi-item px-3">
                  <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                    <Icon.InfoCircle />
                  </span>
                  <span className="navi-text">
                    <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_MARKETPLACE_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />
                  </span>
                </li>
              }
            </ul>
          </Dropdown.Menu>
        </Dropdown>


        {/* ----------------------------------------Date filter----------------------------------------------- */}
        <DatePickerDropdown UIContext={queryContext} setFilterQuery={setFilterQuery} />


        {/* ----------------------------------------Key status filter----------------------------------------------- */}
        <Dropdown className="dropdown-inline symbol mr-2 mb-2" drop="down">

          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-tag">
            {selectedKeyStatus?.icon ?? <Icon.Sliders />}
            <span className="d-none d-sm-inline ml-2">
              {selectedKeyStatus?.label ?? <FormattedMessage id="PRODUCT.KEYS.KEY_STATUS" />}
            </span>
          </Dropdown.Toggle>

          {filterParamsBase?.key_status && <i className="symbol-badge bg-danger"></i>}

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_tag'>
            <ul className="navi navi-hover">
              {KEY_STATUS.map((status, index) => (
                <li className="navi-item" key={index}>
                  {/* {status.value === 'all_status' && <Dropdown.Divider />} */}
                  <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterHandler('key_status', status.value)} id={`key_status_${status.value}`}>
                    <span className="svg-icon svg-icon-md mr-2">
                      {status.icon}
                    </span>
                    <span className="navi-text">
                      {status.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}

              <Dropdown.Divider />
              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={() => onDeleteFilterHandler('key_status', 'all_status')} id='clear_key_status_filter' >
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>


        <SelectCounter count={UIProps.ids.length} />

        <ProductKeysGrouping UIProps={UIProps} />

      </Col>

    </Row>
  );
}