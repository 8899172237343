export const deutschePostProducts =[
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 1,
    "PROD_AUSR": "N",
    "PROD_NAME": "Standardbrief",
    "PROD_BRPREIS": "0,95",
    "BP_NAME": "Standardbrief",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 5,
    "MING": 0,
    "MAXG": 20,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe, Schriftst¸cke und kleinere Gegenst‰nde bis 20 g. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/brief_postkarte.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 11,
    "PROD_AUSR": "N",
    "PROD_NAME": "Kompaktbrief",
    "PROD_BRPREIS": "1,1",
    "BP_NAME": "Kompaktbrief",
    "BP_BRPREIS": "1,1",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 10,
    "MING": 0,
    "MAXG": 50,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe, Schriftst¸cke und kleinere Gegenst‰nde bis 50 g. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/brief_postkarte.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 21,
    "PROD_AUSR": "N",
    "PROD_NAME": "Groşbrief",
    "PROD_BRPREIS": "1,8",
    "BP_NAME": "Groşbrief",
    "BP_BRPREIS": "1,8",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 20,
    "MING": 0,
    "MAXG": 500,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe, Schriftst¸cke und kleinere Gegenst‰nde bis 500 g.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/brief_postkarte.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 31,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief",
    "PROD_BRPREIS": "2,9",
    "BP_NAME": "Maxibrief",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 0,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe, Schriftst¸cke und kleinere Gegenst‰nde bis 1000 g.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/brief_postkarte.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 41,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief bis 2000 g + Zusatzentgelt MBf",
    "PROD_BRPREIS": "5,1",
    "BP_NAME": "Maxibrief",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "Zusatzentgelt MBf",
    "ADD_BRPREIS": "2,2",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 600,
    "MAXB": 300,
    "MAXH": 150,
    "MING": 0,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe alternativ: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief bis 2000 g mit einem İberformat.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/brief_postkarte.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 51,
    "PROD_AUSR": "N",
    "PROD_NAME": "Postkarte",
    "PROD_BRPREIS": "0,95",
    "BP_NAME": "Postkarte",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 2,
    "MING": "",
    "MAXG": "",
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Fl‰chengewicht: 150 g/m2 bis 500 g/m2. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/brief_postkarte.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.10.24",
    "T&T": "",
    "PROD_ID": 290,
    "PROD_AUSR": "N",
    "PROD_NAME": "Warensendung",
    "PROD_BRPREIS": "2,55",
    "BP_NAME": "Warensendung",
    "BP_BRPREIS": "2,55",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 0,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Warenversand bis 1000 g, 35,3 x 25 x 5 cm. Zustellung bis 4 Werktage. Verschlossener Versand. Keine brieflichen Mitteilungen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/w/buecherundwarensendung.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.07.24",
    "T&T": "",
    "PROD_ID": 331,
    "PROD_AUSR": "N",
    "PROD_NAME": "Warensendung 2.000 + Gewichtszuschlag",
    "PROD_BRPREIS": "3,4",
    "BP_NAME": "Warensendung 1.000 zzgl. Gewichtszuschlag",
    "BP_BRPREIS": "2,55",
    "ADD_NAME": "Warensendung 2.000 Gewichtszuschlag",
    "ADD_BRPREIS": "0,85",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 1001,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Warensendung mit einem Gewicht ab 1.001g bis zum Hˆchstgewicht von 2.000 g.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Warenversand bis 2000 g, 35,3 x 25 x 5 cm. Zustellung bis 4 Werktage. Verschlossener Versand. Keine brieflichen Mitteilungen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/w/buecherundwarensendung.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.23",
    "T&T": "",
    "PROD_ID": 401,
    "PROD_AUSR": "N",
    "PROD_NAME": "Streifbandzeitung bis 50 g",
    "PROD_BRPREIS": "0,95",
    "BP_NAME": "Streifbandzeitung bis 50 g",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 0,
    "MAXG": 50,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Einlieferung in Filiale oder Groşannahmestelle, bei mehr als 500 St¸ck immer in Groşannahmestelle. Mit Produkt- oder Internetmarke ist die Einlieferung ¸ber Briefkasten mˆglich.",
    "INTMA_HINWTEXT": "Nutzung exklusiv fÅ¸r Vertragspartner der Deutsche Post Presse Distribution oder gewerbliche Einrichtungen des Pressehandels.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/p/presse-distribution/produkte/streifbandzeitung.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.23",
    "T&T": "",
    "PROD_ID": 407,
    "PROD_AUSR": "N",
    "PROD_NAME": "Streifbandzeitung 51 g bis 500 g",
    "PROD_BRPREIS": "1,55",
    "BP_NAME": "Streifbandzeitung 51 g bis 500 g",
    "BP_BRPREIS": "1,55",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 51,
    "MAXG": 500,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Einlieferung in Filiale oder Groşannahmestelle, bei mehr als 500 St¸ck immer in Groşannahmestelle. Mit Produkt- oder Internetmarke ist die Einlieferung ¸ber Briefkasten mˆglich.",
    "INTMA_HINWTEXT": "Nutzung exklusiv fÅ¸r Vertragspartner der Deutsche Post Presse Distribution oder gewerbliche Einrichtungen des Pressehandels.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/p/presse-distribution/produkte/streifbandzeitung.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.23",
    "T&T": "",
    "PROD_ID": 405,
    "PROD_AUSR": "N",
    "PROD_NAME": "Streifbandzeitung 501 g bis 1000 g",
    "PROD_BRPREIS": "2,68",
    "BP_NAME": "Streifbandzeitung 501 g bis 1000 g",
    "BP_BRPREIS": "2,68",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 501,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Einlieferung in Filiale oder Groşannahmestelle, bei mehr als 500 St¸ck immer in Groşannahmestelle. Mit Produkt- oder Internetmarke ist die Einlieferung ¸ber Briefkasten mˆglich.",
    "INTMA_HINWTEXT": "Nutzung exklusiv fÅ¸r Vertragspartner der Deutsche Post Presse Distribution oder gewerbliche Einrichtungen des Pressehandels.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/p/presse-distribution/produkte/streifbandzeitung.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1002,
    "PROD_AUSR": "N",
    "PROD_NAME": "Standardbrief Integral + EINSCHREIBEN EINWURF",
    "PROD_BRPREIS": "3,3",
    "BP_NAME": "Standardbrief BZL GK",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "EINSCHREIBEN EINWURF",
    "ADD_BRPREIS": "2,35",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 5,
    "MING": 0,
    "MAXG": 20,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Standardbrief bis 20 g, Zustellnachweis durch Postmitarbeiter, Sendungsverfolgung per T&T, Haftung bis 20 EUR, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1007,
    "PROD_AUSR": "N",
    "PROD_NAME": "Standardbrief Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "3,6",
    "BP_NAME": "Standardbrief BZL GK",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "2,65",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 5,
    "MING": 0,
    "MAXG": 20,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Standardbrief bis 20 g, Zustellung gegen Empf‰ngerunterschrift, Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1008,
    "PROD_AUSR": "N",
    "PROD_NAME": "Standardbrief Integral + EINSCHREIBEN + RİCKSCHEIN",
    "PROD_BRPREIS": "5,8",
    "BP_NAME": "Standardbrief BZL GK",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "EINSCHREIBEN + RİCKSCHEIN",
    "ADD_BRPREIS": "4,85",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 5,
    "MING": 0,
    "MAXG": 20,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Standardbrief bis 20 g, Zustellung gegen Empf‰ngerunterschrift, R¸ckschein per Post an Absender (Angabe auf der Vorderseite!), Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1012,
    "PROD_AUSR": "N",
    "PROD_NAME": "Kompaktbrief Integral + EINSCHREIBEN EINWURF",
    "PROD_BRPREIS": "3,45",
    "BP_NAME": "Kompaktbrief BZL GK",
    "BP_BRPREIS": "1,1",
    "ADD_NAME": "EINSCHREIBEN EINWURF",
    "ADD_BRPREIS": "2,35",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 10,
    "MING": 0,
    "MAXG": 50,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Kompaktbrief bis 50 g, Zustellnachweis durch Postmitarbeiter, Sendungsverfolgung per T&T, Haftung bis 20 EUR, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1017,
    "PROD_AUSR": "N",
    "PROD_NAME": "Kompaktbrief Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "3,75",
    "BP_NAME": "Kompaktbrief BZL GK",
    "BP_BRPREIS": "1,1",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "2,65",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 10,
    "MING": 0,
    "MAXG": 50,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Kompaktbrief bis 50 g, Zustellung gegen Empf‰ngerunterschrift, Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1018,
    "PROD_AUSR": "N",
    "PROD_NAME": "Kompaktbrief Integral + EINSCHREIBEN + RİCKSCHEIN",
    "PROD_BRPREIS": "5,95",
    "BP_NAME": "Kompaktbrief BZL GK",
    "BP_BRPREIS": "1,1",
    "ADD_NAME": "EINSCHREIBEN + RİCKSCHEIN",
    "ADD_BRPREIS": "4,85",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 10,
    "MING": 0,
    "MAXG": 50,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Standardbrief bis 50 g, Zustellung gegen Empf‰ngerunterschrift, R¸ckschein per Post an Absender (Angabe auf der Vorderseite!), Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1022,
    "PROD_AUSR": "N",
    "PROD_NAME": "Groşbrief Integral + EINSCHREIBEN EINWURF",
    "PROD_BRPREIS": "4,15",
    "BP_NAME": "Groşbrief BZL GK",
    "BP_BRPREIS": "1,8",
    "ADD_NAME": "EINSCHREIBEN EINWURF",
    "ADD_BRPREIS": "2,35",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 20,
    "MING": 0,
    "MAXG": 500,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Groşbrief bis 500 g, Zustellnachweis durch Postmitarbeiter, Sendungsverfolgung per T&T, Haftung bis 20 EUR, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1027,
    "PROD_AUSR": "N",
    "PROD_NAME": "Groşbrief Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "4,45",
    "BP_NAME": "Groşbrief BZL GK",
    "BP_BRPREIS": "1,8",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "2,65",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 20,
    "MING": 0,
    "MAXG": 500,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Groşbrief bis 500 g, Zustellung gegen Empf‰ngerunterschrift, Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1028,
    "PROD_AUSR": "N",
    "PROD_NAME": "Groşbrief Integral + EINSCHREIBEN + RİCKSCHEIN",
    "PROD_BRPREIS": "6,65",
    "BP_NAME": "Groşbrief BZL GK",
    "BP_BRPREIS": "1,8",
    "ADD_NAME": "EINSCHREIBEN + RİCKSCHEIN",
    "ADD_BRPREIS": "4,85",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 20,
    "MING": 0,
    "MAXG": 500,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Standardbrief bis 500 g, Zustellung gegen Empf‰ngerunterschrift, R¸ckschein per Post an Absender (Angabe auf der Vorderseite!), Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1032,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief Integral + EINSCHREIBEN EINWURF",
    "PROD_BRPREIS": "5,25",
    "BP_NAME": "Maxibrief BZL GK",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "EINSCHREIBEN EINWURF",
    "ADD_BRPREIS": "2,35",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 0,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief bis 1000 g, Zustellnachweis durch Postmitarbeiter, Sendungsverfolgung per T&T, Haftung bis 20 EUR, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1037,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "5,55",
    "BP_NAME": "Maxibrief BZL GK",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "2,65",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 0,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief bis 1000 g, Zustellung gegen Empf‰ngerunterschrift, Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1038,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief Integral + EINSCHREIBEN + RİCKSCHEIN",
    "PROD_BRPREIS": "7,75",
    "BP_NAME": "Maxibrief BZL GK",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "EINSCHREIBEN + RİCKSCHEIN",
    "ADD_BRPREIS": "4,85",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 0,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief bis 1000 g, Zustellung gegen Empf‰ngerunterschrift, R¸ckschein per Post an Absender (Angabe auf der Vorderseite!), Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1042,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief Integral + Zusatzentgelt MBf + EINSCHREIBEN EINWURF",
    "PROD_BRPREIS": "7,45",
    "BP_NAME": "Maxibrief plus ¸ber 1.000g BZL GK",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "Zusatzentgelt MBf + EINSCHREIBEN EINWURF",
    "ADD_BRPREIS": "4,55",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 600,
    "MAXB": 300,
    "MAXH": 150,
    "MING": 0,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe alternativ: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief bis 2000 g mit einem İberformat, Zustellnachweis durch Postmitarbeiter, Sendungsverfolgung per T&T, Haftung bis 20 EUR, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1047,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief Integral + Zusatzentgelt MBf + EINSCHREIBEN",
    "PROD_BRPREIS": "7,75",
    "BP_NAME": "Maxibrief plus ¸ber 1.000g BZL GK",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "Zusatzentgelt MBf + EINSCHREIBEN",
    "ADD_BRPREIS": "4,85",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 600,
    "MAXB": 300,
    "MAXH": 150,
    "MING": 0,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe alternativ: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief bis 2000 g mit einem İberformat, Zustellung gegen Empf‰ngerunterschrift, Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1048,
    "PROD_AUSR": "N",
    "PROD_NAME": "Maxibrief Integral + Zusatzentgelt MBf + EINSCHREIBEN + RİCKSCHEIN",
    "PROD_BRPREIS": "9,95",
    "BP_NAME": "Maxibrief plus ¸ber 1.000g BZL GK",
    "BP_BRPREIS": "2,9",
    "ADD_NAME": "Zusatzentgelt MBf + EINSCHREIBEN + RİCKSCHEIN",
    "ADD_BRPREIS": "7,05",
    "MINL": 100,
    "MINB": 70,
    "MINH": 0,
    "MAXL": 600,
    "MAXB": 300,
    "MAXH": 150,
    "MING": 0,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe alternativ: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief bis 2000 g mit einem İberformat, Zustellung gegen Empf‰ngerunterschrift, R¸ckschein per Post an Absender (Angabe auf der Vorderseite!), Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1052,
    "PROD_AUSR": "N",
    "PROD_NAME": "Postkarte Integral + EINSCHREIBEN EINWURF",
    "PROD_BRPREIS": "3,3",
    "BP_NAME": "Postkarte BZL GK",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "EINSCHREIBEN EINWURF",
    "ADD_BRPREIS": "2,35",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 2,
    "MING": "",
    "MAXG": "",
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Fl‰chengewicht: 150 g/m2 bis 500 g/m2. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Eine Postkarte, Zustellnachweis durch Postmitarbeiter, Sendungsverfolgung per T&T, Haftung bis 20 EUR, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1057,
    "PROD_AUSR": "N",
    "PROD_NAME": "Postkarte Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "3,6",
    "BP_NAME": "Postkarte BZL GK",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "2,65",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 2,
    "MING": "",
    "MAXG": "",
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Fl‰chengewicht: 150 g/m2 bis 500 g/m2. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Eine Postkarte, Zustellung gegen Empf‰ngerunterschrift, Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 1058,
    "PROD_AUSR": "N",
    "PROD_NAME": "Postkarte Integral + EINSCHREIBEN + RİCKSCHEIN",
    "PROD_BRPREIS": "5,8",
    "BP_NAME": "Postkarte BZL GK",
    "BP_BRPREIS": "0,95",
    "ADD_NAME": "EINSCHREIBEN + RİCKSCHEIN",
    "ADD_BRPREIS": "4,85",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 2,
    "MING": "",
    "MAXG": "",
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Fl‰chengewicht: 150 g/m2 bis 500 g/m2. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Eine Postkarte, Zustellung gegen Empf‰ngerunterschrift, R¸ckschein per Post an Absender (Angabe auf der Vorderseite!), Sendungsverfolgung, Haftung bis 25 EUR, Einlieferung in den Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/e/einschreiben.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 10001,
    "PROD_AUSR": "I",
    "PROD_NAME": "Standardbrief Intern. GK",
    "PROD_BRPREIS": "1,25",
    "BP_NAME": "Standardbrief Intern. GK",
    "BP_BRPREIS": "1,25",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 5,
    "MING": 0,
    "MAXG": 20,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe und Schriftst¸cke bis 20 g. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-postkarte-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 10011,
    "PROD_AUSR": "I",
    "PROD_NAME": "Kompaktbrief Intern. GK",
    "PROD_BRPREIS": "1,8",
    "BP_NAME": "Kompaktbrief Intern. GK",
    "BP_BRPREIS": "1,8",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 10,
    "MING": 0,
    "MAXG": 50,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe und Schriftst¸cke bis 50 g. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-postkarte-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 10051,
    "PROD_AUSR": "I",
    "PROD_NAME": "Groşbrief Intern. GK",
    "PROD_BRPREIS": "3,3",
    "BP_NAME": "Groşbrief Intern. GK",
    "BP_BRPREIS": "3,3",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 20,
    "MING": 0,
    "MAXG": 500,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe und Schriftst¸cke bis 500 g. Keine Seite l‰nger als 600 mm.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-postkarte-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 10071,
    "PROD_AUSR": "I",
    "PROD_NAME": "Maxibrief Intern. bis 1.000g GK",
    "PROD_BRPREIS": "6,5",
    "BP_NAME": "Maxibrief Intern. bis 1.000g GK",
    "BP_BRPREIS": "6,5",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 501,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe und Schriftst¸cke bis 1000 g. Keine Seite l‰nger als 600 mm.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-postkarte-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 10091,
    "PROD_AUSR": "I",
    "PROD_NAME": "Maxibrief Intern. bis 2.000g GK",
    "PROD_BRPREIS": 17,
    "BP_NAME": "Maxibrief Intern. bis 2.000g GK",
    "BP_BRPREIS": 17,
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 1001,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe und Schriftst¸cke bis 2000 g. Keine Seite l‰nger als 600 mm.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-postkarte-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 10162,
    "PROD_AUSR": "I",
    "PROD_NAME": "Brief Kilotarif international ohne USt + EINSCHREIBEN",
    "PROD_BRPREIS": "4,58",
    "BP_NAME": "Frankierung Brief Kilotarif St¸ckentgelt",
    "BP_BRPREIS": "0,88",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "3,7",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 600,
    "MAXB": 600,
    "MAXH": 600,
    "MING": 0,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe, Schriftst¸cke und kleinere Gegenst‰nde. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-international-kilotarif.html",
    "INTMA_VERTRAG": "ja",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 10166,
    "PROD_AUSR": "I",
    "PROD_NAME": "Brief Kilotarif international ohne USt.",
    "PROD_BRPREIS": "0,88",
    "BP_NAME": "Frankierung Brief Kilotarif St¸ckentgelt",
    "BP_BRPREIS": "0,88",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 600,
    "MAXB": 600,
    "MAXH": 600,
    "MING": 0,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. F¸r Briefe und Schriftst¸cke. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-international-kilotarif.html",
    "INTMA_VERTRAG": "ja",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": "",
    "PROD_ID": 10201,
    "PROD_AUSR": "I",
    "PROD_NAME": "Postkarte Intern. GK",
    "PROD_BRPREIS": "1,25",
    "BP_NAME": "Postkarte Intern. GK",
    "BP_BRPREIS": "1,25",
    "ADD_NAME": "",
    "ADD_BRPREIS": "",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 2,
    "MING": "",
    "MAXG": "",
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Fl‰chengewicht: 150 g/m2 bis 500 g/m2. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/brief-postkarte-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 11006,
    "PROD_AUSR": "I",
    "PROD_NAME": "Standardbrief Intern. GK Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "4,95",
    "BP_NAME": "Standardbrief Intern. GK",
    "BP_BRPREIS": "1,25",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "3,7",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 5,
    "MING": 0,
    "MAXG": 20,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Standardbrief INTERNATIONAL bis 20 g, nachgewiesene İbergabe an Empf‰nger, Sendungsverfolgung, Haftung, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/einschreiben-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 11016,
    "PROD_AUSR": "I",
    "PROD_NAME": "Kompaktbrief Intern. GK Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "5,5",
    "BP_NAME": "Kompaktbrief Intern. GK",
    "BP_BRPREIS": "1,8",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "3,7",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 10,
    "MING": 0,
    "MAXG": 50,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Kompaktbrief INTERNATIONAL bis 50 g, nachgewiesene İbergabe an Empf‰nger, Sendungsverfolgung, Haftung, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/einschreiben-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 11056,
    "PROD_AUSR": "I",
    "PROD_NAME": "Groşbrief Intern. GK Integral + EINSCHREIBEN",
    "PROD_BRPREIS": 7,
    "BP_NAME": "Groşbrief Intern. GK",
    "BP_BRPREIS": "3,3",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "3,7",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 20,
    "MING": 0,
    "MAXG": 500,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Groşbrief INTERNATIONAL bis 500 g, nachgewiesene İbergabe an Empf‰nger, Sendungsverfolgung, Haftung, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/einschreiben-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 11076,
    "PROD_AUSR": "I",
    "PROD_NAME": "Maxibrief Intern. bis 1.000g GK Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "10,2",
    "BP_NAME": "Maxibrief Intern. bis 1.000g GK",
    "BP_BRPREIS": "6,5",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "3,7",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 501,
    "MAXG": 1000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief INTERNATIONAL bis 1000 g, nachgewiesene İbergabe an  Empf‰nger, Sendungsverfolgung, Haftung, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/einschreiben-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 11096,
    "PROD_AUSR": "I",
    "PROD_NAME": "Maxibrief Intern. bis 2.000g GK Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "20,7",
    "BP_NAME": "Maxibrief Intern. bis 2.000g GK",
    "BP_BRPREIS": 17,
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "3,7",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 353,
    "MAXB": 250,
    "MAXH": 50,
    "MING": 1001,
    "MAXG": 2000,
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Hˆchstmaşe: L + B + H = 900 mm, dabei keine Seite l‰nger als 600 mm.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Ein Maxibrief INTERNATIONAL bis 2000 g, nachgewiesene İbergabe an  Empf‰nger, Sendungsverfolgung, Haftung, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/einschreiben-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  },
  {
    "PROD_GUEAB": "01.01.25",
    "T&T": 1,
    "PROD_ID": 11202,
    "PROD_AUSR": "I",
    "PROD_NAME": "Postkarte Intern. GK Integral + EINSCHREIBEN",
    "PROD_BRPREIS": "4,95",
    "BP_NAME": "Postkarte Intern. GK",
    "BP_BRPREIS": "1,25",
    "ADD_NAME": "EINSCHREIBEN",
    "ADD_BRPREIS": "3,7",
    "MINL": 140,
    "MINB": 90,
    "MINH": 0,
    "MAXL": 235,
    "MAXB": 125,
    "MAXH": 2,
    "MING": "",
    "MAXG": "",
    "MIND": "",
    "MAXD": "",
    "PROD_ANM": "Fl‰chengewicht: 150 g/m2 bis 500 g/m2. Die L‰nge muss mindestens das 1,4-fache der Breite betragen.",
    "INTMA_HINWTEXT": "Preis nach UStG umsatzsteuerfrei. Eine Postkarte INTERNATIONAL, nachgewiesene İbergabe an Empf‰nger, Sendungsverfolgung, Haftung, Einlieferung ¸ber die Filialen der Deutschen Post.",
    "INTMA_PROD_URL": "https://www.deutschepost.de/de/b/briefe-ins-ausland/einschreiben-international.html",
    "INTMA_VERTRAG": "nein",
    "INTMA_ZOLLERKL": "nein"
  }
]