import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, Table } from 'react-bootstrap';
import { convertPriceCurrency, QuantityStyle } from '_metronic/_helpers';
import { useProductsUIContext } from '../_context/ProductsUIContext';
import { CardItems } from '_metronic/_partials/components';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ProductActions } from '../_redux/actions';
import { CloseButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { BASE_URL } from 'constants/apiUrl';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function ProductDetailsDialog() {

  const history = useHistory();
  const dispatch = useDispatch();

  const UIContext = useProductsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showProductDetailsDialog,
    onHide: UIContext.closeProductDetailsDialog,
    productId: UIContext.productId,
  }), [
    UIContext.closeProductDetailsDialog,
    UIContext.showProductDetailsDialog,
    UIContext.productId,
  ]);


  const { product: { sku, name, brand, quantity, pricing, variants, features, identities, images, category } } = useSelector(state => ({
    product: state.products.productForEdit,
  }));

  const prices = variants.map(variant => variant.pricing?.gross_price);
  const startingPrice = Math.min(...prices);
  const netPrice = (+startingPrice / (1 + pricing?.tax / 100));

  useEffect(() => {
    UIProps.productId && dispatch(ProductActions.getProductById(UIProps.productId));
  }, [dispatch, UIProps.productId]);

  useEffect(() => {
    return () => {
      dispatch(ProductActions.resetFormHandler());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditAction = () => {
    history.push(ModuleRoutes.EDIT_PRODUCT_FN(UIProps.productId));
    UIProps.onHide()
  }


  const INFO = [
    {
      label: "GENERAL.SKU",
      content: sku
    },
    {
      label: "GENERAL.QUANTITY",
      content: QuantityStyle(quantity, true)
    },
    {
      label: "PRODUCT.MORE_SETTINGS.BRAND",
      content: brand
    },
    {
      label: "PRODUCT.GENERAL.TAX",
      content: pricing?.tax + '%'
    },
    {
      label: "PRODUCT.TABLE.PRICE_NET",
      content: variants.length > 0
        ? <div className='d-flex align-items-center'>
          <span className="label label-lg label-inline label-light-info font-weight-bold mx-1">* ab</span>
          <span>{convertPriceCurrency(netPrice)}</span>
        </div>
        : <span>{convertPriceCurrency(pricing?.gross_price / (1 + +pricing?.tax / 100))}</span>
    },
    {
      label: "CATEGORY.CATEGORY_NAME",
      content: category.path
    },
  ]


  return (
    <Portal node={document && document.getElementById('modal-portal')}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" size='lg'>

        {/* <PerfectScrollbar options={{ wheelSpeed: 1, wheelPropagation: false }} className="scroll" style={{ maxHeight: "calc(100vh - 150px)", minHeight: "200px" }}> */}

        <Modal.Body className='font-weight-bold overflow-auto p-10'>

          <CloseButton onClick={UIProps.onHide} className="position-absolute top-0 right-0 z-index-10 mr-10 mt-10" />

          {name && (
            <>
              <Row>

                <Col lg="5">
                  <div className='overflow-hidden border p-2 rounded h-300px h-md-auto h-lg-300px h-xxl-auto w-300px text-center'>
                    {images?.length > 0
                      ? images[0]?.thumbnail
                        ? <img src={`${BASE_URL}/${images[0]?.url}`} className='w-100 h-100' style={{ objectFit: 'contain' }} alt={`${images[0]?.name}`} />
                        : <img src={`${images[0]?.url}`} className="w-100 h-100" style={{ objectFit: 'contain' }} alt={`${images[0]?.name}`} />
                      : <Icon.Image className="h-100 w-50" style={{ opacity: 0.15 }} />
                    }
                  </div>
                </Col>

                <Col lg="7" className='pl-lg-10'>

                  <div className="font-weight-bolder text-md-left display-5 text-break mt-3 mt-lg-0 mb-3">
                    {name}
                  </div>

                  <h2 className="font-weight-bolder text-primary mb-6">
                    {variants.length > 0
                      ? <div className='d-flex align-items-center'>
                        <span className="label label-lg label-inline label-light-info font-weight-bold mx-1">* ab</span>
                        <span>{convertPriceCurrency(startingPrice)}</span>
                      </div>
                      : <span>{convertPriceCurrency(pricing?.gross_price)}</span>
                    }
                  </h2>

                  <hr />

                  <CardItems Content={INFO} />

                </Col>
              </Row>

              {/* --------------------------------- Identities --------------------------------- */}

              {identities.barcode || identities.ean || identities.asin || identities.gtin || identities.isbn || identities.mpn || identities.upc
                ? <Row className="d-flex justify-content-between bg-light-info px-5 py-5 mx-0 my-5">

                  <Col xs="6" md="3" className="text-md-center py-2">
                    <div className="font-weight-bolder text-uppercase">
                      <FormattedMessage id="PRODUCT.MORE_SETTINGS.BARCODE" />
                    </div>
                    <div className="text-dark-50">
                      {identities.barcode.length > 0 && identities.barcode}
                    </div>
                  </Col>

                  {identities.gtin.length > 0 &&
                    <Col xs="6" md="3" className="text-md-center py-2">
                      <div className="font-weight-bolder text-uppercase">
                        <FormattedMessage id='PRODUCT.MORE_SETTINGS.GTIN' />
                      </div>
                      <div className="text-dark-50">
                        {identities.gtin}
                      </div>
                    </Col>
                  }

                  {identities.mpn.length > 0 &&
                    <Col xs="6" md="3" className="text-md-center py-2">
                      <div className="font-weight-bolder text-uppercase">
                        <FormattedMessage id='PRODUCT.MORE_SETTINGS.MPN' />
                      </div>
                      <div className="text-dark-50">
                        {identities.mpn}
                      </div>
                    </Col>
                  }

                  <Col xs="6" md="3" className="text-md-center py-2">
                    <div className="font-weight-bolder text-uppercase">
                      <FormattedMessage id='PRODUCT.MORE_SETTINGS.EAN' />
                    </div>
                    <div className="text-dark-50">
                      {identities.ean.length > 0 && identities.ean}
                    </div>
                  </Col>

                  <Col xs="6" md="3" className="text-md-center py-2">
                    <div className="font-weight-bolder text-uppercase">
                      <FormattedMessage id='PRODUCT.MORE_SETTINGS.ASIN' />
                    </div>
                    <div className="text-dark-50">
                      {identities.asin?.length > 0 && identities.asin}
                    </div>
                  </Col>

                  <Col xs="6" md="3" className="text-md-center py-2">
                    <div className="font-weight-bolder text-uppercase">
                      <FormattedMessage id='PRODUCT.MORE_SETTINGS.UPC' />
                    </div>
                    <div className="text-dark-50">
                      {identities.upc.length > 0 && identities.upc}
                    </div>
                  </Col>

                </Row>
                : null
              }

              {/* --------------------------------- Variants --------------------------------- */}

              {variants.length > 0 &&
                <div className="table-responsive mt-5">
                  <Table className='mb-0'>
                    <thead>
                      <tr>

                        <th className="font-weight-bolder text-uppercase pl-5">
                          <FormattedMessage id="PRODUCT.VARIANTS" />
                        </th>

                        <th className="font-weight-bolder text-uppercase">
                          <FormattedMessage id="GENERAL.SKU" />
                        </th>

                        <th className="font-weight-bolder text-center text-uppercase">
                          <FormattedMessage id="GENERAL.QUANTITY" />
                        </th>

                        <th className="font-weight-bolder text-right text-uppercase">
                          <FormattedMessage id="PRODUCT.TABLE.PRICE_NET" />
                        </th>

                        <th className="font-weight-bolder text-right text-uppercase">
                          <FormattedMessage id="PRODUCT.TABLE.PRICE_GROSS" />
                        </th>

                      </tr>
                    </thead>

                    <tbody>
                      {variants && variants.map((variant, index) =>

                        <tr key={index}>

                          <td className="align-middle pt-3 pl-5">
                            {variant?.specs.map((spec, i) => (
                              <label className="label label-inline label-light-primary font-weight-bold text-nowrap m-1" key={i}>
                                {spec.value}
                              </label>
                            ))}
                          </td>

                          <td className="d-flex flex-column pt-3">
                            <span>{variant?.sku}</span>
                          </td>

                          <td className="text-center align-middle pt-3">
                            {QuantityStyle(variant?.quantity)}
                          </td>

                          <td className="font-weight-bolder text-right align-middle pt-3 ">
                            {convertPriceCurrency((+variant?.pricing?.gross_price / (1 + +pricing?.tax / 100)))}
                          </td>

                          <td className="font-weight-bolder text-right align-middle pt-3 pr-5">
                            {convertPriceCurrency(+variant?.pricing?.gross_price)}
                          </td>

                        </tr>

                      )}
                    </tbody>

                  </Table>
                </div>
              }

              {/* --------------------------------- Features --------------------------------- */}

              {features.length > 0 &&
                <>
                  <div className="separator separator-dashed my-5 my-md-6" />
                  <div className="font-weight-bolder text-uppercase mb-2">
                    <FormattedMessage id="PRODUCT.FEATURES" />
                  </div>
                  <div className='d-flex flex-wrap'>
                    {features.map((feature, index) =>
                      <label type="button" key={index} className="label label-inline label-light-primary label-xl text-nowrap mb-2 mr-2">
                        {feature.name} : {feature.value}
                      </label>
                    )}
                  </div>
                </>
              }

            </>
          )}

        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='primary' className="ml-2" onClick={handleEditAction} id='btn_edit_modal'>
            <FormattedMessage id='GENERAL.EDIT' />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
};