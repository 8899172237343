import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { ShippingSettingsTable } from './shipping-settings-table/ShippingSettingsTable';
import { ShippingSettingsFilter } from './settings-filter/ShippingSettingsFilter';
import { BlankMessage } from '_metronic/_partials/components';
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ShippingSettingsCard({ UIProps, queryParams, setOrderQuery, setPaginationQuery, setSearchQuery }) {

  const { id, settings_type, default_shipping, loading } = useSelector(state => ({
    id: state.settings.shippingSettings.id,
    settings_type: state.settings.shippingSettings.settings_type,
    default_shipping: state.settings.shippingSettings.default_shipping,
    loading: state.settings.loading,
  }), shallowEqual);

  const searchedShipping = () => {
    if (queryParams.search) {
      return default_shipping.filter(item => item.service.toLowerCase().includes(queryParams.search.toLowerCase()))
    }
    return default_shipping
  }


  return (
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Supplier />}
        title={<FormattedMessage id='SETTINGS.DOCUMENT.SHIPPING.DEFAULT_SETTINGS' />}
        info={<UserInfoDropdown description={<FormattedMessage id='SETTINGS.DOCUMENT.SHIPPING.INFO_DESCRIPTION' />} />}
      >

        <OverlayTrigger overlay={<Tooltip id="market-tooltip"><FormattedMessage id={default_shipping?.length >= 10 ? "SETTINGS.DOCUMENT.SHIPPING.TOO_MANY_PROFILES" : "SETTINGS.DOCUMENT.SHIPPING.METHOD_ADD"} /></Tooltip>}>
          <Button variant='outline-primary' size='sm' className="svg-icon" onClick={() => UIProps.openShippingSettingDialog()} id="btn_shipping" disabled={default_shipping?.length >= 10}>
            <Icon.Plus />
            <span className="d-none d-md-inline font-weight-bold ml-2">
              <FormattedMessage id="SETTINGS.DOCUMENT.SHIPPING.METHOD_ADD" />
            </span>
          </Button>
        </OverlayTrigger>

      </CardSubHeader>

      <CardBody>
        {searchedShipping()?.length === 0 && !queryParams.search
          ?
          <BlankMessage
            icon={<Icon.Supplier />}
            title={<FormattedMessage id='SETTINGS.DOCUMENT.SHIPPING.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='SETTINGS.DOCUMENT.SHIPPING.BLANK_MESSAGE' />}
            className="my-10"
            arrow
          />
          : <>
            <ShippingSettingsFilter setSearchQuery={setSearchQuery} />
            <ShippingSettingsTable
              id={id}
              queryParams={queryParams}
              settings_type={settings_type}
              searchedShipping={searchedShipping()}
              loading={loading}
              default_shipping={default_shipping}
              setPaginationQuery={setPaginationQuery}
              setOrderQuery={setOrderQuery}
              setSearchQuery={setSearchQuery}
            />
          </>
        }

      </CardBody>

    </Card>
  );
}