import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
//import { DatePickerDropdown } from '_metronic/_partials/components';
//import { useUserUIContext } from '../../_context/UserUIContext';
import { BlankMessage } from '_metronic/_partials/components'
import { getConnection } from 'constants/connectionSettings'
import { convertPriceCurrency } from '_metronic/_helpers'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FormattedMessage } from 'react-intl'
import { Icon } from '_metronic/_icons'
import { Table } from 'react-bootstrap'



export function OrderReportCard({ orderReport, setFilterQuery }) {

  //const UIContext = useUserUIContext();

  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.Order />} title={<FormattedMessage id="GENERAL.ORDER_REPORTS" />}>

      </CardSubHeader>

      <CardBody className="px-0 pt-0">
        <PerfectScrollbar className="scroll" style={{ maxHeight: '560px' }}>

          {orderReport?.length > 0
            ? <Table responsive className="table-head-custom table-head-bg table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">

                  <th className="rounded-0 px-5 pl-md-10" style={{ width: "70%" }}>
                    <FormattedMessage id="DOCUMENT.TABLE.COLUMN.MARKETPLACE" />
                  </th>

                  <th className="text-center px-0" style={{ width: "10%" }}>
                    <FormattedMessage id="GENERAL.COUNT" />
                  </th>

                  <th className="rounded-0 text-right pr-5 pr-md-10" style={{ width: "20%" }}>
                    <FormattedMessage id="DOCUMENT.POSITION_TOTAL_AMOUNT" />
                  </th>

                </tr>
              </thead>

              <tbody>
                {orderReport?.map((data, index) => (
                  <tr key={index}>

                    <td className='text-left pl-5 pl-md-10 py-2'>
                      <div className="d-flex align-items-center">
                        <div className="text-center svg-icon svg-icon-xl mr-4">
                          {getConnection(data.marketplace)?.icon}
                        </div>
                        <div>
                          <div className="font-weight-bolder text-dark-75 font-size-lg">
                            {getConnection(data.marketplace)?.label}
                          </div>
                          {(data?.marketplace_url && data?.marketplace_url.length > 0) ?
                            <a href={`${data?.marketplace_url}`} target="_blank" rel="noopener noreferrer" className="font-weight-bold text-dark-50 text-hover-primary preview">
                              {data?.marketplace_url}
                              <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                                <Icon.Link />
                              </span>
                            </a>
                            : null
                          }
                        </div>
                      </div>
                    </td>

                    <td className="text-center">
                      <div className="text-dark-75 font-weight-bolder font-size-lg">
                        {data?.total_count}
                      </div>
                    </td>

                    <td className="text-right pr-5 pr-md-10">
                      <div className="text-dark-75 font-weight-bolder font-size-lg">
                        {convertPriceCurrency(+data?.total_price?.toFixed(2), 'EUR')}
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </Table>
            : <BlankMessage
              icon={<Icon.Order />}
              message={<FormattedMessage id='ADMIN.USER.ORDER_REPORT.BLANK_MESSAGE' />}
            />
          }
        </PerfectScrollbar>
      </CardBody>

    </Card>
  )
}
