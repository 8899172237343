import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import { RemotePagination } from '_metronic/_partials/controls';
import { sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates, convertPriceCurrency, getSelectRow } from '_metronic/_helpers';
import * as UIHelpersDocuments from 'app/modules/documents/_context/DocumentsUIHelpers';
import { documentContents } from 'app/modules/documents/GenerateDocument';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import { useShippingsUIContext } from '../_context/ShippingsUIContext';
import * as uiHelpers from '../_context/ShippingsUIHelpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShippingsActions } from '../_redux/actions';
import { Icon } from '_metronic/_icons';
import { find } from 'lodash';



export function ShippingsTable({ entities, countShipping, loading, queryParams, setPaginationQuery, setOrderQuery, setSearchQuery }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  // Customers UI Context
  const UIContext = useShippingsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    openDeleteLabelDialog: UIContext.openDeleteLabelDialog,
    openCustomerDetailsDialog: UIContext.openCustomerDetailsDialog,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    sendEmailLabelDialog: UIContext.sendEmailLabelDialog
  }), [UIContext]);

  const openPrintLabelsDialog = (row) => {
    const findMethod = find(UIHelpersDocuments.LABEL_OPTIONS, { value: row.service });
    if (findMethod) {
      dispatch(ShippingsActions.getShipping([row.id]))
      UIProps.openPrintLabelsDialog(findMethod.value);
    }
  };


  const COLUMNS = [
    {
      dataField: 'label',
      text: intl({ id: 'GENERAL.LABEL' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '200px', width: "35%" },
      headerClasses: 'text-left pl-6',
      classes: 'text-left font-weight-bold text-dark-75 p-2',
      formatter: columnFormatters.LabelFormatter,
      formatExtraData: {
        openPrintLabelsDialog
      }
    },
    {
      dataField: 'type',
      text: intl({ id: 'CONNECTIONS.TYPE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { width: "5%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center font-weight-bold text-dark-75 pr-7',
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="type-tooltip" className='font-weight-bold'><FormattedMessage id={cell === "return" ? 'DOCUMENT.SHIPPING.TYPE_RETURN' : 'DOCUMENT.SHIPPING.TYPE_SHIPMENT'} /></Tooltip>}>
          <div className="svg-icon svg-icon-lg">
            {cell === "return" ? <Icon.Shipping className="text-danger" style={{ transform: "scaleX(-1)" }} /> : <Icon.Shipping />}
          </div>
        </OverlayTrigger>
      )
    },
    {
      dataField: 'delivery_address.name',
      text: intl({ id: 'CUSTOMER.TITLE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px', width: "20%" },
      headerClasses: 'text-left text-nowrap',
      classes: 'text-left',
      formatter: (cell, row) => {
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{row.delivery_address.name}</span>
            <span className='text-dark-50'>{row.delivery_address.email}</span>
          </div>
        );
      }
    },
    {
      dataField: 'delivery_address.country_code',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px', width: "10%" },
      headerClasses: 'text-left text-nowrap',
      classes: 'text-left',
      formatter: (cell, row) => {
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{row.delivery_address?.city}</span>
            <span className='text-dark-50'>{`${row.delivery_address?.country_code}-${row.delivery_address?.post_code}`}</span>
          </div>
        );
      },
    },
    {
      dataField: 'relation_documents',
      text: intl({ id: 'GENERAL.DOCUMENT_ID' }),
      headerStyle: { width: "10%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center',
      formatter: (cell, row) => (!cell ? null : cell.map((item, index) => {
        const document = documentContents.find(doc => doc.type === item.type);
        return (
          <OverlayTrigger overlay={<Tooltip id="type-tooltip" className='font-weight-bold'><FormattedMessage id={document?.title} /></Tooltip>} key={index}>
            <Link to={`${document?.route}/${item.id}/edit`} className='d-flex flex-column font-weight-bold text-dark-75 text-hover-primary mb-1' key={index}>
              {item.id}
            </Link>
          </OverlayTrigger>
        )
      }))
    },
    {
      dataField: 'dp_product_name',
      text: intl({ id: 'GENERAL.NAME' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '60px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-75 pr-7',
      hidden: (cell, row) => !(row.service === 'deutsche_post'),
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'delivery_date',
      text: intl({ id: 'GENERAL.DELIVERY_DATE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      type: 'date',
      headerStyle: { width: "10%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center pr-7',
      editor: { type: Type.DATE },
      formatter: (cell, row) => <div className='font-size-sm text-dark-50'>{UIDates.formatDate(cell)}</div>
    },
    {
      dataField: 'weight',
      text: intl({ id: 'GENERAL.WEIGHT' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { width: "5%" },
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center font-weight-bold text-dark-75 pr-7',
      formatter: (cell, row) => cell,
    },
    {
      dataField: 'price',
      text: intl({ id: 'DOCUMENT.SHIPPING_PRICE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px', width: "10%" },
      headerClasses: 'text-right',
      classes: 'text-right font-weight-bolder text-dark-75 pr-3',
      formatter: (cell, row) => {
        return (
          <div className="pr-4">
            {convertPriceCurrency(+cell, "EUR")}
          </div>
        );
      }
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      headerStyle: { minWidth: '80px', width: "5%" },
      headerClasses: 'text-right pr-3',
      classes: 'text-right pr-3',
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        openPrintLabelsDialog,
        openDeleteLabelDialog: UIProps.openDeleteLabelDialog
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={entities || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        striped
        condensed
        defaultSorted={uiHelpers.defaultSorted}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => setSearchQuery('')} />}
        selectRow={getSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: entities,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countShipping}
        entitiesCount={entities?.length}
        loading={loading}
      />
    </>
  );
}
