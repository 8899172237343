import React, { useEffect, useState, useMemo } from 'react';
import { MultipleFilter, DatePickerDropdown } from '_metronic/_partials/components';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { ORDER_STATUS } from '../_context/DocumentsUIHelpers';
import { SearchControl } from '_metronic/_partials/controls';
import * as UIHelpers from '../_context/DocumentsUIHelpers';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { theme } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';
import clsx from 'clsx';



export function DocumentsFilter({ queryParams, setSearchQuery, setFilterQuery, requestedDocument, marketFilterContent, UIContext }) {

  const query = useQuery();

  const [search, setSearchBase] = useState(query.get('search') ?? '');
  const [filterParamsBase, setFilterParamsBase] = useState({
    no_invoice: null,
    open_invoice: null,
    credit_created: false,
    non_labeled: false,
    status: "open",
    marketplace: null,
    invoice_ebay: null,
  });


  useEffect(() => {

    setSearchBase(query.get('search') ?? '');
    const no_invoice = query.get('filter.no_invoice');
    const open_invoice = query.get('filter.open_invoice');
    const credit_created = query.get('filter.credit_created');
    const non_labeled = query.get('filter.non_labeled');
    const status = query.get('filter.status');
    const marketplace = query.get('filter.marketplaces');
    const invoice_ebay = query.get('filter.invoice_ebay');

    setFilterParamsBase(prev => ({
      ...prev,
      no_invoice: no_invoice ? true : false,
      open_invoice: open_invoice ? true : false,
      credit_created: credit_created ? true : false,
      non_labeled: non_labeled ? true : false,
      status: ORDER_STATUS.map(item => item.value).includes(status) ? status : "open",
      marketplace: marketplace,
      invoice_ebay: invoice_ebay,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])


  const handleSearch = (value) => {
    setSearchQuery(value);
    setSearchBase(value);
  };

  const onFilterHandler = e => {
    const { name, checked } = e.target;
    setFilterParamsBase(prev => ({
      ...prev,
      [name]: checked,
    }));
    setFilterQuery(name, checked);
  };

  const onFilterStatusHandler = (value) => {
    setFilterQuery('status', value);
    setFilterParamsBase(prev => ({
      ...prev,
      status: value,
    }));
  };


  const onFilterHandlerEbay = e => {
    const { name, checked } = e.target;
    setFilterParamsBase(prev => ({
      ...prev,
      [name]: checked ? "ebay" : null,
    }));
    setFilterQuery(name, checked ? "ebay" : null);
  };

  // Market Filter Fonksiyonları - MultipleFilter
  const existingMarkets = CONNECTION_SETTINGS.filter(setting => marketFilterContent?.includes(setting.name));

  existingMarkets.push({
    name: "non_market",
    label: <FormattedMessage id="GENERAL.FILTER.NONE_MARKET" />,
    icon: <Icon.ShopWindow className="text-dark-75" />
  })

  const defaultMarketFilterValues = useMemo(() => {
    const values = {};
    existingMarkets.forEach(entity => {
      values[entity.name] = queryParams?.filter?.marketplaces?.[entity.name] ? true : false;
    });
    return values;
  }, [existingMarkets, queryParams]);

  const [marketFilterValue, setMarketFilterValue] = useState(defaultMarketFilterValues);

  const onFilterConnectionHandler = (value) => {
    setMarketFilterValue(value);
    setFilterQuery(value, "marketplaces");
  }


  return (
    <Row className='mb-3'>

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>


        {/* -------------------------------------- Order status filter ------------------------------------ */}
        {requestedDocument.type === 'orders' &&
          <Dropdown className="dropdown-inline symbol mr-2" drop="down">

            <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
              <span className={clsx("label label-xl label-dot mr-3", `label-${ORDER_STATUS.find(item => item.value === filterParamsBase.status)?.className}`)}></span>
              {ORDER_STATUS.find(i => i.value === filterParamsBase.status)?.label}
            </Dropdown.Toggle>

            {filterParamsBase.status !== "open" && <i className="symbol-badge bg-danger"></i>}
            <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_grouping'>
              <ul className="navi navi-hover">
                {ORDER_STATUS.map((filter, index) => (
                  <li className="navi-item" key={index}>
                    {filter.value === 'all-orders' && <Dropdown.Divider />}
                    <Dropdown.Item className='navi-link font-weight-bold' onClick={() => onFilterStatusHandler(filter.value)} id={`dropdown_item_${filter.value}`}>
                      <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)}></span>
                      <span className="navi-text text-nowrap">{filter.long_label}</span>
                    </Dropdown.Item>
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>

          </Dropdown>}


        {/* -------------------------------------- Order general filter ------------------------------------ */}
        <Dropdown className="dropdown-inline symbol mr-2" drop="down">

          <Dropdown.Toggle variant='outline-secondary' className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <Icon.Sliders />
            <span className="d-none d-sm-inline ml-2">
              <FormattedMessage id='DOCUMENT.FILTER.CONTENT' />
            </span>
          </Dropdown.Toggle>

          {Object.keys(filterParamsBase)?.some(key => filterParamsBase[key] === true) && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim-down" style={{ minWidth: '180px' }} id="dropdown_document_filter">
            <ul className="navi navi-hover font-weight-bold">

              {(['invoices', 'credits', 'orders'].includes(requestedDocument.type)) &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-primary">
                    <input type="checkbox" name="open_invoice" checked={filterParamsBase['open_invoice']} onChange={onFilterHandler} id="checkbox_open_invoice" />
                    <span />
                    <div className="text-nowrap ml-1">
                      <FormattedMessage id="DOCUMENT.FILTER.OPEN_PAYMENTS" />
                    </div>
                  </label>
                </li>
              }

              {requestedDocument.type === 'orders' &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-primary">
                    <input type="checkbox" name="no_invoice" checked={filterParamsBase['no_invoice']} onChange={onFilterHandler} id="checkbox_no_invoice" />
                    <span />
                    <div className="text-nowrap ml-1">
                      <FormattedMessage id="DOCUMENT.FILTER.NO_INVOICE" />
                    </div>
                  </label>
                </li>
              }

              {requestedDocument.type === 'invoices' &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-primary">
                    <input type="checkbox" name="credit_created" checked={filterParamsBase['credit_created']} onChange={onFilterHandler} />
                    <span />
                    <div className="text-nowrap ml-1">
                      <FormattedMessage id="DOCUMENT.FILTER.CANCELLED" />
                    </div>
                  </label>
                </li>
              }

              {(['invoices', 'offers', 'orders'].includes(requestedDocument.type)) &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-primary">
                    <input type="checkbox" name="non_labeled" checked={filterParamsBase['non_labeled']} onChange={onFilterHandler} id="checkbox_none_labeled" />
                    <span />
                    <div className="text-nowrap ml-1">
                      <FormattedMessage id="DOCUMENT.FILTER.WITHOUT_SHIPPING_LABEL" />
                    </div>
                  </label>
                </li>
              }

              {['orders'].includes(requestedDocument.type) &&
                <li className="navi-item">
                  <label className="navi-link checkbox checkbox-primary">
                    <input type="checkbox" name="invoice_ebay" checked={!!filterParamsBase['invoice_ebay']} onChange={onFilterHandlerEbay} id="checkbox_invoice_ebay" />
                    <span />
                    <div className="text-nowrap ml-1">
                      <FormattedMessage id="DOCUMENT.FILTER.INVOICE_TO_EBAY" />
                    </div>
                  </label>
                </li>
              }

              <Dropdown.Divider />
              <li className="navi-item">
                <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear' onClick={() => setFilterQuery('DELETE_PARAMS', UIHelpers.allFilterKeys)}>
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li>

            </ul>
          </Dropdown.Menu>
        </Dropdown>


        {/* --------------------------------------- Market filter --------------------------------------- */}
        {(['orders', 'invoices', 'credits', 'waybills',].includes(requestedDocument.type)) &&
          <MultipleFilter
            icon={<Icon.Shop />}
            title={<FormattedMessage id='DOCUMENT.FILTER.MARKET' />}
            items={existingMarkets}
            itemNamePlural={<FormattedMessage id="GENERAL.FILTER.MARKETS" />}
            noItemMessage={<FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_MARKETPLACE_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />}
            selectedFilters={marketFilterValue}
            onFilterChange={onFilterConnectionHandler}
          />
        }


        {/* --------------------------------------- Date filter --------------------------------------- */}
        <DatePickerDropdown UIContext={UIContext} setFilterQuery={setFilterQuery} />

      </Col>
    </Row>
  );
}
