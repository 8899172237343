/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export const ActionFormatter = (cellContent, row, rowIndex, { deletePositionAction }) => (
  <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="PRODUCT.PRODUCT_DELETE" /></Tooltip>}>
    <a className="btn btn-icon btn-sm btn-hover-danger font-weight-bold " onClick={() => /* row.sku != null && */ deletePositionAction(row.id)} id={`btn_delete_${row?.id}`}>
      <span className="svg-icon svg-icon-md">
        <Icon.Trash />
      </span>
    </a>
  </OverlayTrigger>
);
