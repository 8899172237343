import React, { useRef, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'react-bootstrap';
import { DocumentActions } from '../../_redux/actions';
import { useDocumentsUIContext } from '../../_context/DocumentsUIContext';
import { EnterPaymentAddForm } from './EnterPaymentAddForm';
import { v4 as uuidv4 } from 'uuid';



const isPaymentHasDifferences = (paymentValues, restPayment) => {
  return +restPayment.toFixed(2) === +paymentValues.toFixed(2) ? 'paid' : 'open';
};


export function EnterPaymentAddDialog({ documentId, paymentAmountDifference }) {

  const dispatch = useDispatch();
  const history = useHistory();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showEnterPaymentEditDialog,
    onHide: UIContext.handleCloseEnterPaymentEditDialog,
    enteredPaymentForEdit: UIContext.enteredPaymentForEdit,
    selectedPaymentId: UIContext.selectedPaymentId,
    requestedDocument: UIContext.requestedDocument,
  }),
    [
      UIContext.showEnterPaymentEditDialog,
      UIContext.handleCloseEnterPaymentEditDialog,
      UIContext.enteredPaymentForEdit,
      UIContext.selectedPaymentId,
      UIContext.requestedDocument,
    ]
  );

  useEffect(() => {
    !documentId && UIProps.onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  const savePayment = (values) => {

    const urlParts = history.location.pathname.split('/')
    const documentIsEdit = ['copy', 'new', 'convert'].includes(urlParts[urlParts.length - 1])

    const paymentStatus = isPaymentHasDifferences(values.amount, paymentAmountDifference);
    const paymentValues = { id: uuidv4(), ...values }

    if (documentIsEdit) {
      dispatch(DocumentActions.enterPaymentSuccess(paymentValues, paymentStatus, null));
    } else {
      dispatch(DocumentActions.enterPayment(paymentValues, paymentStatus, documentId));
    }

    UIProps.onHide();
  };

  const submitByRef = useRef();
  const submitByRefClick = () => {
    submitByRef.current && submitByRef.current.click();
  };


  return (
    <Modal show={UIProps.show} onHide={UIProps.onHide} size="md" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>

      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id='DOCUMENT.PAYMENT.ENTER_TITLE' />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <EnterPaymentAddForm savePayment={savePayment} initialValues={UIProps.enteredPaymentForEdit} btnRef={submitByRef} paymentAmountDifference={paymentAmountDifference.toFixed(2)} documentType={UIProps.requestedDocument.type} />

      </Modal.Body>

      <Modal.Footer>

        <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>

        <Button variant='primary' className="ml-2" onClick={submitByRefClick} id="btn_save_modal">
          <FormattedMessage id="GENERAL.SAVE" />
        </Button>

      </Modal.Footer>

    </Modal>
  );
}
