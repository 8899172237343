import moment from 'moment';



export const UIDates = {

  getCurrentDate: (format = 'YYYY-MM-DD') => moment().format(format),
  addDate: (date, add, unit) => moment(date).add(add, unit),
  formatDate: (date, format = 'DD.MM.YYYY') => moment(date).format(format),
  formatDateTime: (date, format = 'DD.MM.YYYY, HH:mm') => moment(date).format(format),

  getCurrentDateTime: (format = 'DD.MM.YYYY HH:mm') => moment().format(format),
  hourComparison: (dateString, diffHour) => { return moment().diff(moment(dateString), 'hours') <= diffHour },
  getYear: date => moment(date).year(),

  addDateTime: (date, add, unit) => moment(date).add(add, unit),
  getDateDiff: (date1, date2, unit) => moment(date1).diff(date2, unit),

  isWeekend: date => moment(date).day() === 0 || moment(date).day() === 6,
  isToday: date => moment(date).isSame(moment(), 'day'),
  isBetween: (date, start, end) => moment(date).isBetween(start, end, 'day', '[]'),

  exportName: (date) => moment(date).format("YYYY.MM.DD_HH.mm.ss"),

  dayCalculation: (day) => moment().add(day, "d").format("YYYY-MM-DD"),

  dateFormatter: (date) => moment(date).format(date?.length > 10 ? "DD/MM/YYYY, hh:mm" : "DD/MM/YYYY"),

  formatFilterDate: (date) => moment.utc(date).format('YYYY-MM-DD'),

  getCurrentDateUtc: (date, format = 'DD.MM.YYYY') => moment.utc(date).format(format),

  getLastMonthsStartDate: (date) => {
    const startOfCurrentMonth = moment().clone().startOf('month');
    return startOfCurrentMonth.clone().subtract(date - 1, 'months').startOf('month').format('YYYY-MM-DD');
  },

  formNow: (date) => moment(date).fromNow(),

  isOrderOlderThan: (orderDate, days) => moment().diff(orderDate, 'days', true) > days,

  calculateDayDifference: (startDate, endDate) => Math.abs(moment(endDate).diff(moment(startDate), 'days')),

  getDaysDifference: (date1, date2 = moment()) => moment(date1).diff(moment(date2), 'days')

};